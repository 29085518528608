import React, { FC } from 'react'
import { useTextTranslation } from '../../../contexts/TextTranslation'

import style from './input-name.module.css'
import { IBorder } from '../../cart-detali/cart-detali'
import { TRest } from '../../../utils/typesFromBackend'

interface IInputName {
  value: string
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  border: IBorder
  rest: TRest
}

const InputName: FC<IInputName> = ({ value, handleChange, border, rest }) => {
  const [validInput, setValidInput] = React.useState(true)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const translation = useTextTranslation()
  function handleChangeInput (e: React.ChangeEvent<HTMLInputElement>): void {
    handleChange(e)
    setValidInput(e.target.checkValidity())
  }

  const textColor = {
    color: rest.colorsSchema_id.mainTextColor,
    backgroundColor: rest.colorsSchema_id.backgroundColor
  }

  const inputStyle = {
    color: rest.colorsSchema_id.popupInputColor,
    backgroundColor: rest.colorsSchema_id.backgroundColor
  }

  return (
    <div className={style['input-wrapper']}>
      <label style={textColor} className={style['cart-detail__subtitle']}>{translation('enter-your-details')}</label>
      <input
        ref={inputRef}
        className={style['cart-input']}
        style={{ ...border, ...inputStyle }}
        type="text"
        name="name"
        minLength={2}
        required
        placeholder={translation('enter-name')}
        value={value}
        onChange={handleChangeInput}
        autoComplete="off"
      />
      <span className={style['cart-input__error']}>
        {validInput ? '' : translation('you-need-enter-name')}
      </span>
    </div>
  )
}
export default InputName
