import { FC } from 'react'

interface IButtonMinus {
  fill: string
  stroke: string
}
const ButtonMinus: FC<IButtonMinus> = ({ fill, stroke }) => {
  return (
        <svg
        width="12"
        height="12"
        viewBox="0 0 12 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.923077 0.0769043H5.07692H6.92308H11.0769C11.5867 0.0769043 12 0.49018 12 0.999981C12 1.50978 11.5867 1.92306 11.0769 1.92306H6.92308H5.07692H0.923077C0.413276 1.92306 0 1.50978 0 0.999981C0 0.49018 0.413276 0.0769043 0.923077 0.0769043Z"
          fill={fill}
          stroke={stroke}
        />
      </svg>
  )
}

export default ButtonMinus
