import { FC } from 'react'
import { useHistory } from 'react-router'
import { useTextTranslation } from '../../contexts/TextTranslation'
import { TRest } from '../../utils/typesFromBackend'

import style from './not-found.module.css'

interface INotFound {
  rest: TRest
}

const NotFound: FC<INotFound> = ({ rest }) => {
  const history = useHistory()
  const translation = useTextTranslation()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClick = () => {
    history.push('/')
  }
  const styleMainColorButton = {
    backgroundColor: rest.colorsSchema_id.buttonColor,
    color: rest.colorsSchema_id.buttonTextColor
  }
  return (
    <section className={style.container}>
      <h3 className={style.h3}>{translation('something-went-wrong')}</h3>
      <div className={style['not-found']}>
        <p>4</p>
        <p>0</p>
        <p>4</p>
      </div>
      <button
        className={style.btn}
        style={styleMainColorButton}
        onClick={handleClick}
      >
        {translation('go-back-main-page')}
      </button>
    </section>
  )
}

export default NotFound
