import { FC } from 'react'
import { TOrderType, TPayment, TRest } from '../../utils/typesFromBackend'
import style from './cart-delivery-block.module.css'

interface ICartDeliveryBlock {
  title: string
  active: boolean
  rest: TRest
  toggleActiveMethod: (name: string) => void
  nameActiveMethod: string
  orderType?: TOrderType
  setYandexDelivery?: (arg: boolean) => void
  yandexDelivery: boolean
  paymentType?: TPayment
}
const CartDeliveryBlock: FC<ICartDeliveryBlock> = ({
  title,
  active,
  rest,
  toggleActiveMethod,
  nameActiveMethod,
  orderType,
  setYandexDelivery,
  yandexDelivery,
  paymentType
}) => {
  const styleMainColorButton = {
    backgroundColor: rest.colorsSchema_id.buttonColor,
    color: rest.colorsSchema_id.activeButtonColorCart,
    borderRadius: '4px'
  }
  function handleClick(): void {
    toggleActiveMethod(nameActiveMethod)
    // eslint-disable-next-line
    if (setYandexDelivery) {
      setYandexDelivery(orderType?.isYandexDelivery ?? false)
    }
  }
  // eslint-disable-next-line
  return paymentType?.onlineNamePayment === '' && yandexDelivery ? null : (
    <div
      className={style['cart-delivery-block__item']}
      style={
        active
          ? styleMainColorButton
          : { color: rest.colorsSchema_id.buttonColorCart }
      }
      onClick={handleClick}
    >
      {title}
    </div>
  )
}
export default CartDeliveryBlock
