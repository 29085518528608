import React, { FC } from 'react'
import InputMask from 'react-input-mask'
import { useTextTranslation } from '../../../contexts/TextTranslation'

import { TRest } from '../../../utils/typesFromBackend'
import style from './input-phone.module.css'
import { IBorder } from '../../cart-detali/cart-detali'

interface IInputPhone {
  rest: TRest
  value: string
  handleChange: (e: any) => void
  setValidPhone: (arg0: boolean) => void
  border: IBorder
}
const InputPhone: FC<IInputPhone> = ({
  rest,
  value,
  handleChange,
  setValidPhone,
  border
}) => {
  const [validInput, setValidInput] = React.useState(true)
  const translation = useTextTranslation()
  const regex =
    // eslint-disable-next-line no-useless-escape
    /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/
  const regexInput = /[^0-9+()-]/g
  function handleChangeInput (e: React.ChangeEvent<HTMLInputElement>): void {
    e.target.value = e.target.value.replace(regexInput, '')
    handleChange(e)
    if (rest.country === 'RU' || rest.country === 'KZ') {
      if (!regex.test(e.target.value)) {
        setValidInput(false)
        setValidPhone(false)
      } else {
        setValidInput(true)
        setValidPhone(true)
      }
    }
  }

  const textColor = {
    color: rest.colorsSchema_id.mainTextColor,
    backgroundColor: rest.colorsSchema_id.backgroundColor
  }

  const inputStyle = {
    color: rest.colorsSchema_id.popupInputColor,
    backgroundColor: rest.colorsSchema_id.backgroundColor
  }

  return (
    <div className={style['input-wrapper']}>
      <label style={textColor} className={style['cart-detail__subtitle']}>
        {translation('enter-your-phone-number')}
      </label>
      <InputMask autoComplete='off' className={style['cart-input']} type="tel" style={{ ...border, ...inputStyle }}
        name="userPhone" placeholder='+7 (999) 999-99-99' mask="+7 (999) 999-99-99" required onChange={handleChangeInput} value={value} />
      <span className={style['cart-input__error']}>
        {validInput ? '' : translation('you-need-enter-correct-phone-number')}
      </span>
    </div>
  )
}
export default InputPhone
// input mask "/^(\+7|7|8)?[\s\-]?\(?[0-9]{3}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/"
