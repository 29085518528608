/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { handleResponse } from '../helpers'
import { BASE_URL } from '../const'

export async function getCity (yandexCity: string) {
  return await fetch(
    `${BASE_URL}/yandex-delivery/getCity`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        yandexCity
      })
    }
  )
    .then(async (res) => await handleResponse(res))
}

export async function getStreet (yandexCity: string, yandexStreet: string) {
  return await fetch(
    `${BASE_URL}/yandex-delivery/getStreet`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        yandexCity,
        yandexStreet
      })
    }
  ).then(async (res) => await handleResponse(res))
}

export async function getHouse (yandexCity: string, yandexStreet: string, yandexHouse: string) {
  return await fetch(
    `${BASE_URL}/yandex-delivery/getHouse`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        yandexCity,
        yandexStreet,
        yandexHouse
      })
    }
  ).then(async (res) => await handleResponse(res))
}

export async function getCoordinates (yandexCity: string, yandexStreet: string, yandexHouse: string) {
  return await fetch(
    `${BASE_URL}/yandex-delivery/getCoordinates`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        yandexCity,
        yandexStreet,
        yandexHouse
      })
    }
  ).then(async (res) => await handleResponse(res))
}

export async function getDeliveryPrice (data: any) {
  return await fetch(`${BASE_URL}/yandex-delivery/DeliveryOrderCoordinates`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ...data
    })
  })
    .then(async (res) => await handleResponse(res))
}

export const createYandexOrder = async (order: any) => {
  return await fetch(`${BASE_URL}/yandex-delivery/FullDeliveryCreate`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ...order
    })
  }).then(async (response) => {
    const resp = await handleResponse(response)
    return resp
  })
}
