import { BASE_URL } from '../const'
import { handleResponse } from '../helpers'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
export const getAllSales = async (rest_id: string) => {
  return await fetch(`${BASE_URL}/sale/list?rest_id=${rest_id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(async (res) => await handleResponse(res))
}
