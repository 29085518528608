import { CSSProperties, FC, useRef } from 'react'

import { IMultiLang } from '../../utils/typesFromBackend'
import { useCurrentLanguage } from '../../contexts/CurrentLanguageContext'

import style from './dishInfo.module.css'

interface IDishInfoProps {
  showDishInfo: boolean
  info: IMultiLang
  handlerShowDishInfo: (value: boolean) => void
  infoBackdropBackgroundColor?: string
  infoBackdropTextColor?: string
}
interface ICustomCSS extends CSSProperties {
  '--bgColor': string
}

const DishInfo: FC<IDishInfoProps> = ({
  showDishInfo,
  info,
  handlerShowDishInfo,
  infoBackdropTextColor,
  infoBackdropBackgroundColor
}) => {
  const currentLanguage = useCurrentLanguage()
  const timerId = useRef<ReturnType<typeof setTimeout> | null>(null)

  const handleMouseEnter = (): void => {
    timerId.current != null && clearTimeout(timerId.current)
  }

  const handleMouseLeave = (): void => {
    timerId.current = setTimeout(() => handlerShowDishInfo(false), 900)
  }

  const dishInfoStyle: CSSProperties = {
    color: infoBackdropTextColor ?? '#FFF',
    backgroundColor: infoBackdropBackgroundColor ?? '#F0F'
  }

  const pStyle: ICustomCSS = {
    '--bgColor': infoBackdropBackgroundColor ?? '#F0F'
  }

  return showDishInfo ? (
    <aside
      className={style.asideBlock}
      style={dishInfoStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      title={info[currentLanguage]}
      aria-label={info[currentLanguage]}
    >
      <p style={pStyle} className={style.asideBlockText}>
        {info[currentLanguage]}
      </p>
    </aside>
  ) : null
}

export default DishInfo
