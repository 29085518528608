/* eslint-disable n/handle-callback-err */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import * as UserApi from '../../utils/user-api'
import { AppThunk } from '../../utils/types'
import { setTokens, signOut } from '../../utils/utils'
import { TCity } from '../../utils/typesFromBackend'
export const USER_REQUEST: 'USER_REQUEST' = 'USER_REQUEST'
export const USER_REGISTRATION_SUCCESS: 'USER_REGISTRATION_SUCCESS' =
  'USER_REGISTRATION_SUCCESS'
export const USER_REGISTRATION_FAILED: 'USER_REGISTRATION_FAILED' =
  'USER_REGISTRATION_FAILED'
export const USER_REQUEST_SUCCESS: 'USER_REQUEST_SUCCESS' =
  'USER_REQUEST_SUCCESS'
export const USER_REQUEST_FAILED: 'USER_REQUEST_FAILED' = 'USER_REQUEST_FAILED'
export const LOGOUT_REQUEST: 'LOGOUT_REQUEST' = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS: 'LOGOUT_SUCCESS' = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILED: 'LOGOUT_FAILED' = 'LOGOUT_FAILED'
export const UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST' = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS' = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILED: 'UPDATE_USER_FAILED' = 'UPDATE_USER_FAILED'
export const TOKEN_REQUEST: 'TOKEN_REQUEST' = 'TOKEN_REQUEST'
export const TOKEN_SUCCESS: 'TOKEN_SUCCESS' = 'TOKEN_SUCCESS'
export const TOKEN_FAILED: 'TOKEN_FAILED' = 'TOKEN_FAILED'
interface IUserRequestAction {
  readonly type: typeof USER_REQUEST
}
interface IUserRegistrationSuccessAction {
  readonly type: typeof USER_REGISTRATION_SUCCESS
  readonly user: {
    name: string
    email: string
  }
}
interface IUserRegistrationFailedAction {
  readonly type: typeof USER_REGISTRATION_FAILED
}
interface IUserRequestSuccessAction {
  readonly type: typeof USER_REQUEST_SUCCESS
  readonly user: {
    _id: string
    nickname?: string
    birthdate?: string
    name?: string
    phone: string
    street?: string
    house?: string
    flat?: string
    city?: TCity
    smsCode?: string
    timeSendCode?: string
    email?: string
    porch?: string
    floor?: string
  }
}
interface IUserRequestFailedAction {
  readonly type: typeof USER_REQUEST_FAILED
}
interface ILogoutRequestAction {
  readonly type: typeof LOGOUT_REQUEST
}
interface ILogoutSuccessAction {
  readonly type: typeof LOGOUT_SUCCESS
}
interface ILogoutFailedAction {
  readonly type: typeof LOGOUT_FAILED
}
interface IUpdateUserRequestAction {
  readonly type: typeof UPDATE_USER_REQUEST
}
interface IUpdateUserSuccessAction {
  readonly type: typeof UPDATE_USER_SUCCESS
  readonly user: {
    name: string
    email: string
  }
}
interface IUpdateUserFailedAction {
  readonly type: typeof UPDATE_USER_FAILED
}
interface ITokenRequestAction {
  readonly type: typeof TOKEN_REQUEST
}
interface ITokenSuccessAction {
  readonly type: typeof TOKEN_SUCCESS
}
interface ITokenFailedAction {
  readonly type: typeof TOKEN_FAILED
}
export type TUserActions =
  | IUserRequestAction
  | IUserRegistrationSuccessAction
  | IUserRegistrationFailedAction
  | IUserRequestSuccessAction
  | IUserRequestFailedAction
  | ILogoutRequestAction
  | ILogoutSuccessAction
  | ILogoutFailedAction
  | IUpdateUserRequestAction
  | IUpdateUserSuccessAction
  | IUpdateUserFailedAction
  | ITokenRequestAction
  | ITokenSuccessAction
  | ITokenFailedAction
interface IRegistration {
  name: string
  email: string
  password: string
}
export const registration: AppThunk = (data: IRegistration) => {
  return function (dispatch) {
    dispatch({
      type: USER_REQUEST
    })
    UserApi.registrationUser(data)
      .then((res) => {
        if (res && res.success) {
          setTokens(res)
          dispatch({
            type: USER_REGISTRATION_SUCCESS,
            user: res.user
          })
        } else {
          dispatch({
            type: USER_REGISTRATION_FAILED
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: USER_REGISTRATION_FAILED
        })
      })
  }
}
interface IAuthorization {
  email: string
  password: string
}
export const authorization: AppThunk = (data: IAuthorization) => {
  return function (dispatch) {
    dispatch({
      type: USER_REQUEST
    })
    UserApi.autorizationUser(data)
      .then((res) => {
        if (res && res.success) {
          setTokens(res)
          dispatch({
            type: USER_REQUEST_SUCCESS,
            user: res.user
          })
        } else {
          dispatch({
            type: USER_REQUEST_FAILED
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: USER_REQUEST_FAILED
        })
      })
  }
}
export const logout: AppThunk = (callback: () => void) => {
  return function (dispatch) {
    dispatch({
      type: LOGOUT_REQUEST
    })
    UserApi.logoutUser()
      .then((res) => {
        if (res && res.success) {
          dispatch({
            type: LOGOUT_SUCCESS
          })
          signOut()
          callback()
        } else {
          dispatch({
            type: LOGOUT_FAILED
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: LOGOUT_FAILED
        })
      })
  }
}
export const getNewToken: AppThunk = () => {
  return function (dispatch) {
    dispatch({
      type: TOKEN_REQUEST
    })
    UserApi.refreshToken()
      .then((res) => {
        if (res && res.success) {
          setTokens(res)
          dispatch({
            type: TOKEN_SUCCESS
          })
        } else {
          dispatch({
            type: TOKEN_FAILED
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: TOKEN_FAILED
        })
      })
  }
}
interface IChangeUserProfile {
  email: string
  password: string
  name: string
}
export const changeUserProfile: AppThunk = (data: IChangeUserProfile) => {
  return function (dispatch) {
    dispatch({
      type: UPDATE_USER_REQUEST
    })
    UserApi.updateUser(data)
      .then((res) => {
        if (res && res.success) {
          dispatch({
            type: UPDATE_USER_SUCCESS,
            user: res.user
          })
        } else {
          dispatch({
            type: UPDATE_USER_FAILED
          })
        }
      })
      .catch((err) => {
        if (
          err.message === 'jwt expired' ||
          err.message === 'Token is invalid'
        ) {
          dispatch(getNewToken())
          dispatch(changeUserProfile(data))
        } else {
          dispatch({
            type: UPDATE_USER_FAILED
          })
        }
      })
  }
}
export const getUser: AppThunk = () => {
  return function (dispatch) {
    dispatch({
      type: USER_REQUEST
    })
    UserApi.getUser()
      .then((res) => {
        if (res && res.success) {
          dispatch({
            type: USER_REQUEST_SUCCESS,
            user: res.user
          })
        } else {
          dispatch({
            type: USER_REQUEST_FAILED
          })
        }
      })
      .catch((err) => {
        if (
          err.message === 'jwt expired' ||
          err.message === 'Token is invalid'
        ) {
          dispatch(getNewToken())
          dispatch(getUser())
        } else {
          dispatch({
            type: USER_REQUEST_FAILED
          })
        }
      })
  }
}
