import { iNotification, NotificationAction, SET_NOTIFICATION } from '../actions/notification'

const initialState: iNotification = {
  message: '',
  type: 'success'
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const notificationReducer = (state = initialState, action: NotificationAction) => {
  switch (action.type) {
    case SET_NOTIFICATION:
      return {
        message: action.payload.message,
        type: action.payload.type
      }
    default:
      return state
  }
}
