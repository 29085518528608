import React, { FC } from 'react'
import { useCurrentLanguage } from '../../contexts/CurrentLanguageContext'

import { IModifierInModal } from '../../utils/types'
import { TCategoryModifier, TRest } from '../../utils/typesFromBackend'
import ModifierInModal from '../modifier-in-modal/modifier-in-modal'
import style from './group-modifier-in-modal.module.css'

interface IGroupModifierInModal {
  groupModifier: TCategoryModifier
  rest: TRest
  modifiers: IModifierInModal[]
  toggleCheckGroupModifier: (
    groupModifierId: string,
    modifierId: string
  ) => void
  noImage: boolean
}

const GroupModifierInModal: FC<IGroupModifierInModal> = ({
  groupModifier,
  rest,
  modifiers,
  toggleCheckGroupModifier,
  noImage
}) => {
  const currentLanguage = useCurrentLanguage()

  return (
    <div className={style.groupModifier}>
      <h3 className={style['groupModifier-title']}>{groupModifier.title[currentLanguage]}</h3>
      <div
        className={
          noImage
            ? style['container-groupModifier_no_image']
            : style['container-groupModifier']
        }
      >
        {modifiers.map((element) => (
          <ModifierInModal
            modifier={element.modifier}
            key={element.modifier._id}
            groupModifierId={groupModifier._id}
            check={element.check}
            rest={rest}
            toggleCheckGroupModifier={toggleCheckGroupModifier}
            noImage={noImage}
          />
        ))}
      </div>
    </div>
  )
}
export default GroupModifierInModal
