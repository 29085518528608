/* eslint-disable multiline-ternary */
import { FC, ReactElement } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from '../../services/hooks/redux-hooks'

interface IProtectedRoute {
  path: string
  exact: boolean
  children: ReactElement
}

const ProtectedRoute: FC<IProtectedRoute> = ({ children }) => {
  const isLoggedIn = useSelector((store) => store.user.isLoggedIn)

  return (
    <Route
      render={({ location }) => {
        // Создаем URLSearchParams из текущего location.search
        const urlParams = new URLSearchParams(location.search)

        // Формируем строку параметров
        const searchParams = urlParams.toString()

        return isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/person-sign-in/',
              search: searchParams.length > 0 ? `?${searchParams}` : '', // Добавляем ? перед параметрами, если они существуют
              state: { from: location }
            }}
          />
        )
      }}
    />
  )
}

export default ProtectedRoute
