import React, { FC } from 'react'
import { TRest } from '../../utils/typesFromBackend'
import Header from '../../components/header/header'
import { useTextTranslation } from '../../contexts/TextTranslation'
import style from './payment-failure.module.css'
import { Link } from 'react-router-dom'
import useLinkWithParams from '../../services/hooks/useLinkWithParams'

interface IPaymentFailure {
  rest: TRest
  setIsScrollButtonVisible: (arg: boolean) => void
}
const PaymentFailure: FC<IPaymentFailure> = ({
  rest,
  setIsScrollButtonVisible
}) => {
  const translation = useTextTranslation()
  const createLink = useLinkWithParams()
  return (
    <section className={style.section}>
      <Header
        title={translation('payment-failure')}
        rest={rest}
        showBottomCart={true}
        setIsScrollButtonVisible={setIsScrollButtonVisible}
      />
      <div className={style.body}>
        <h1 style={{ color: `${rest.colorsSchema_id.mainTextColor}` }}>
          {translation('payment-failure')}
        </h1>
        <Link
          style={{ color: `${rest.colorsSchema_id.mainTextColor}` }}
          to={createLink('/cart')}
        >
          {translation('go-to-cart')}
        </Link>
      </div>
    </section>
  )
}

export default PaymentFailure
