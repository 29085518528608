/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { FC, useState } from 'react'

import { ADDED_ITEM, DECREASE_ITEM } from '../../services/actions'
import { useDispatch, useSelector } from '../../services/hooks/redux-hooks'
import { TDish, TRest } from '../../utils/typesFromBackend'
import ButtonMinus from '../ui/button-minus/button-minus'
import ButtonPlus from '../ui/button-plus/button-plus'
import ButtonPopupClose from '../ui/button-popup-close/button-popup-close'
import style from './dish.module.css'
import imageNoPhoto from '../../assets/images/no_photo.png'
import { BASE_URL_CDN } from '../../utils/const'
import { IDishInCart } from '../../services/reducers/cart'
import { useCurrentLanguage } from '../../contexts/CurrentLanguageContext'
import { useTextTranslation } from '../../contexts/TextTranslation'
import DishInfo from '../dish-info/dishInfo'

interface IDish {
  data: TDish
  isNoImage: boolean
  rest: TRest
  openDishModal: (
    isNoImage: boolean,
    activeModifiers: boolean,
    dish: TDish
  ) => void
  setDishesIdForModalDel?: (dishesId: string) => void
  setIsOpenDishModalDel?: (value: boolean) => void
  horizontalDisplay: boolean
  setIsDishLoaded?: (arg: boolean) => void
}
const Dish: FC<IDish> = ({
  data,
  isNoImage,
  rest,
  openDishModal,
  setDishesIdForModalDel,
  setIsOpenDishModalDel,
  horizontalDisplay,
  setIsDishLoaded
}) => {
  const [countBooleanInCart, setCountBooleanInCart] = React.useState(false)
  const [countInCart, setCountInCart] = React.useState(0)
  const arrayDishesInCart = useSelector((store) => store.cart.dishes)
  const [activeModifiers, setActiveModifiers] = React.useState(false)
  const [isVisible, setIsVisible] = React.useState(false) // параметр отвечает за то, что был ли виден элемент пользователю
  const [dishes, setDishes] = React.useState<IDishInCart[]>()
  const [showProductsControl, setShowProductsControl] = React.useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const nodeImage = React.useRef<HTMLImageElement>(null)
  const [img, setImg] = React.useState('')
  const currentLanguage = useCurrentLanguage()
  const [likeSrc, setLikeSrc] = React.useState(
    'https://img.icons8.com/parakeet-line/32/FA5252/like.png'
  )
  const translation = useTextTranslation()
  const handleImageClick = (): void => {
    if (likeSrc === 'https://img.icons8.com/color/32/filled-like.png') {
      setLikeSrc('https://img.icons8.com/parakeet-line/32/FA5252/like.png')
    } else {
      setLikeSrc('https://img.icons8.com/color/32/filled-like.png')
    }
  }

  React.useEffect(() => {
    if (rest.tariff === 'Basic') {
      setShowProductsControl(false)
    } else {
      setShowProductsControl(true)
    }
  }, [rest.tariff])

  React.useEffect(() => {
    onScroll()
  }, [])

  const onScroll = (): void => {
    if (!isVisible) {
      if (nodeImage.current == null) {
        return
      }
      const top = nodeImage.current.getBoundingClientRect().top
      setIsVisible(top >= 0 && top <= window.innerHeight)
    }
  }

  React.useEffect(() => {
    document.addEventListener('scroll', onScroll)
    return () => document.removeEventListener('scroll', onScroll)
  })

  const dispatch = useDispatch()

  React.useEffect(() => {
    setDishes(arrayDishesInCart.filter((item) => item.dish._id === data._id))
    if (arrayDishesInCart.some((item) => item.dish._id === data._id)) {
      setCountBooleanInCart(true)
    } else {
      setCountBooleanInCart(false)
    }
  }, [arrayDishesInCart, data._id])

  React.useEffect(() => {
    if (countBooleanInCart) {
      let count = 0
      const myDish: IDishInCart[] = []
      arrayDishesInCart.forEach((dish) => {
        if (dish.dish._id === data._id) {
          count += dish.quantity
          myDish.push(dish)
        }
      })
      setCountInCart(count)
    } else {
      setCountInCart(0)
    }
  }, [arrayDishesInCart, countBooleanInCart, data._id])

  React.useEffect(() => {
    if (
      (data.groupModifiers_ids.length > 0 &&
        data.groupModifiers_ids.some((item) => item.active)) ||
      (data.modifiers_ids.length > 0 &&
        data.modifiers_ids.some((item) => item.active))
    ) {
      setActiveModifiers(true)
    } else {
      setActiveModifiers(false)
    }
  }, [data])

  // добавление фото блюда
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (data.image) {
      setImg(BASE_URL_CDN + '/' + `${data.image}`)
    } else {
      if (rest.logoPath.length > 0) {
        // если нет фотки - берем лого ресторана
        setImg(BASE_URL_CDN + '/' + `${rest.logoPath}`)
      } else {
        setImg(imageNoPhoto)
      }
    }
  }, [data.image, rest.logoPath])

  React.useEffect(() => {
    if (setIsDishLoaded) {
      setIsDishLoaded(true)
    }
  }, [])

  const {
    loght,
    mainColor,
    textLight,
    buttonTextColor,
    mainTextColor,
    buttonColor,
    backgroundColor,
    isShowMenuInfoDishDetail,
    infoBackdropBackgroundColor,
    infoBackdropTextColor
  } = rest.colorsSchema_id

  const styleLoght = {
    background: loght
  }

  /* const arcticleBorder = {
    border: `0.7px solid ${mainColor}`
  } */

  const styleInCartBackground = {
    background: mainColor
  }
  const styleNoImage = {
    background: backgroundColor
  }

  const styleMainTextColor = {
    color: mainTextColor
  }
  /* const stylePriceColorInCart = {
    color: buttonTextColor
  } */

  const styleInCartCount = {
    color: textLight
  }
  const bottomColor = {
    background: rest.colorsSchema_id.bottomDishColor
  }

  const dishSale = {
    color: buttonTextColor,
    backgroundColor: rest.colorsSchema_id.popupBackup
  }

  const borderDish = {
    border: `0.7px solid ${rest.colorsSchema_id.borderDish}`
  }

  // заполнение svg
  const fill = rest.colorsSchema_id.mainColor
  const stroke = rest.colorsSchema_id.mainColor
  const fillInCart = rest.colorsSchema_id.buttonTextColor

  function handleClickAdd(): void {
    if (activeModifiers) {
      openDishModal(isNoImage, activeModifiers, data)
    } else {
      dispatch({ type: ADDED_ITEM, item: data, quantity: 1 })
    }
  }

  function handleClickDecrease(): void {
    if (dishes?.length !== undefined) {
      if (dishes.length > 1) {
        dispatch({ type: DECREASE_ITEM, item: data, quantity: 1 })
        if (setDishesIdForModalDel != null && activeModifiers) {
          setDishesIdForModalDel(data._id)
        }
        if (
          setIsOpenDishModalDel != null &&
          countInCart > 0 &&
          activeModifiers
        ) {
          setIsOpenDishModalDel(true)
        }
      } else {
        dispatch({ type: DECREASE_ITEM, item: data, quantity: 1, alone: true })
      }
    }
  }

  function handleClickOpenPopup(): void {
    openDishModal(isNoImage, activeModifiers, data)
  }

  const dividedPrice = data.price
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

  const styleInvertedDishSize = {
    width: '366px',
    height: '200px'
  }

  const stylePosition: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row-reverse'
  }

  const combinedStyles = {
    ...borderDish,
    ...(horizontalDisplay ? styleInvertedDishSize : {})
  }

  const infoButtonStyle = {
    color: rest.colorsSchema_id.buttonTextColor,
    backgroundColor: rest.colorsSchema_id.buttonColor
  }

  const [showDishInfo, setShowDishInfo] = useState(false)

  const handleClickInfoOpen = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation()
    e.preventDefault()
    handleShowDishInfo(!showDishInfo)
  }

  const handleShowDishInfo = (value: boolean): void => {
    setShowDishInfo(value)
  }

  return isNoImage ? (
    // карточка блюда без фото в меню
    <div
      className={`${style['product-container-no-image']} additional-class`}
      style={{ ...borderDish, ...styleNoImage }}
    >
      <div
        className={
          countBooleanInCart
            ? `${style['in-cart']} ${style.product} ${style['product-no-image']}`
            : `${style.product} ${style['product-no-image']}`
        }
      >
        <div
          className={style.product__top_no_image}
          onClick={handleClickOpenPopup}
        >
          <div style={{ display: 'none' }} className={style.liked}>
            <img
              width='32'
              height='34'
              src={likeSrc}
              onClick={handleImageClick}
              alt='filled-like'
            />
          </div>
          {countBooleanInCart && (
            <div style={styleInCartCount} onClick={handleClickOpenPopup}></div>
          )}
          <div
            className={`${style.product__title} ${style['product__title-no-image']}`}
            style={styleMainTextColor}
          >
            {data.title[currentLanguage]}
          </div>
        </div>
        <div className={style.product__bottom}>
          {showProductsControl ? (
            <div
              className={style['product__price-block']}
              style={countBooleanInCart ? styleInCartBackground : styleLoght}
            >
              {/* {!activeModifiers && countBooleanInCart
                    ? ( */}
              <div
                className={style.product__minus}
                onClick={handleClickDecrease}
              >
                <ButtonMinus
                  fill={countBooleanInCart ? fillInCart : fill}
                  stroke={stroke}
                />
              </div>
              {/* ) */}
              {/* : (
                      ''
                    )} */}
              <div className={style.product__price}>
                <span
                  className={style['product__price-value']}
                  style={{ color: rest.colorsSchema_id.colorPrice }}
                >
                  {dividedPrice}&nbsp;{rest.currentCurrency}
                </span>
              </div>
              <div className={style.product__plus} onClick={handleClickAdd}>
                <ButtonPlus
                  fill={countBooleanInCart ? fillInCart : fill}
                  stroke=''
                />
              </div>
            </div>
          ) : (
            <div
              className={`${style['product__price-block']} ${style['product__price-block_standart']}`}
              style={styleLoght}
            >
              <div
                className={`${style.product__price} ${style.product__price_standart}`}
              >
                <span
                  className={style['product__price-value']}
                  style={{ color: rest.colorsSchema_id.colorPrice }}
                >
                  {dividedPrice}&nbsp;{rest.currentCurrency}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    // карточка блюда с фото в меню
    <article
      className={`${style['product-container']} additional-class`}
      style={combinedStyles}
    >
      <div
        style={horizontalDisplay ? stylePosition : {}}
        className={
          countBooleanInCart
            ? `${style.product} ${style['in-cart']}`
            : style.product
        }
      >
        <div className={style['img-container']} onClick={handleClickOpenPopup}>
          {isShowMenuInfoDishDetail && (
            <>
              <div
                style={infoButtonStyle}
                className={style.infoButton}
                onClick={handleClickInfoOpen}
              >
                {showDishInfo ? (
                  <ButtonPopupClose
                    fill={rest.colorsSchema_id.buttonTextColor}
                  />
                ) : (
                  <h4 className={style.infoButtonText}>Состав</h4>
                )}
              </div>
              <DishInfo
                showDishInfo={showDishInfo}
                infoBackdropBackgroundColor={infoBackdropBackgroundColor}
                infoBackdropTextColor={infoBackdropTextColor}
                handlerShowDishInfo={handleShowDishInfo}
                info={data.description}
              />
            </>
          )}
          {data.discount > 0 && (
            <div className={style.discount} style={dishSale}>
              {data.discount}% Off
            </div>
          )}
          {countBooleanInCart && (
            <div
              className={style['in-cart__count']}
              onClick={handleClickOpenPopup}
            >
              {countInCart}
            </div>
          )}
          <img
            style={{ objectFit: horizontalDisplay ? 'cover' : 'contain' }}
            ref={nodeImage}
            className={`${style.product__image} ${style.product__top}`}
            src={
              isVisible
                ? `${img}`
                : rest.logoPath.length > 0
                ? `${rest.logoPath}`
                : `${imageNoPhoto}`
            }
          />
        </div>
        <div style={{ display: 'none' }} className={style.liked}>
          <img
            width='32'
            height='34'
            src={likeSrc}
            onClick={handleImageClick}
            alt='filled-like'
          />
        </div>
        <div className={style.data} style={bottomColor}>
          {/* <div className={style['rating-result']}>
              <span className={style['rating-active']}></span>
              <span className={style['rating-active']}></span>
              <span className={style['rating-active']}></span>
              <span></span>
              <span></span>
            </div> */}
          <div className={style.flex__space_between}>
            <div className={style.product__title} style={styleMainTextColor}>
              {data.title[currentLanguage]}
            </div>
          </div>
          <span style={{ color: `${rest.colorsSchema_id.dishWeightColor}` }}>
            {data.unit === 'шт' ? '1' : data.weight ? data.weight : ''}&nbsp;
            {data.unit}
          </span>
          <div
            style={{ marginBottom: horizontalDisplay ? '10px' : '0px' }}
            className={style.flex__space_between}
          >
            {countBooleanInCart ? (
              <>
                <div
                  className={style.product__minus}
                  style={styleInCartBackground}
                  onClick={handleClickDecrease}
                >
                  <ButtonMinus fill={fillInCart} stroke='' />
                </div>
                <div className={style.product__price}>
                  <span
                    className={style['product__price-value']}
                    style={{ color: rest.colorsSchema_id.colorPrice }}
                  >
                    {dividedPrice}&nbsp;{rest.currentCurrency}
                  </span>
                </div>
                <div
                  className={style.product__plus}
                  onClick={handleClickAdd}
                  style={styleInCartBackground}
                >
                  <ButtonPlus fill={fill} stroke='' />
                </div>
              </>
            ) : (
              <>
                <div className={style.product__price}>
                  <span
                    className={style['product__price-value']}
                    style={{ color: rest.colorsSchema_id.colorPrice }}
                  >
                    {dividedPrice}&nbsp;{rest.currentCurrency}
                  </span>
                </div>
                <div className={style.product__bottom}>
                  <div
                    className={style['product__price-block']}
                    style={{ background: `${buttonColor}` }}
                    onClick={handleClickAdd}
                  >
                    <p style={{ color: `${buttonTextColor}` }}>
                      {translation('add-to-cart')}
                    </p>
                    <div className={style.product__busket} />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </article>
  )
}

export default Dish
