// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".socialNetworks_article__sQp5C {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.socialNetworks_ul__nh-s4 {\n    width: 100%;\n    display: flex;\n    list-style-type: none;\n    justify-content: space-between;\n    padding: 0;\n}\n.socialNetworks_li__1WZpB {\n    width: 30px;\n    height: 30px;\n    margin-left: 8px;\n}\n.socialNetworks_li__1WZpB:first-child {\n    margin-left: 0;\n}\n.socialNetworks_li__1WZpB:hover {\n    cursor: pointer;\n}\n.socialNetworks_logo__WQ28v {\n    display: block;\n    width: 30px;\n    height: 30px;\n    background-position: center;\n    background-repeat: no-repeat;\n}\n\n", "",{"version":3,"sources":["webpack://./src/components/social-networks/socialNetworks.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,aAAa;IACb,qBAAqB;IACrB,8BAA8B;IAC9B,UAAU;AACd;AACA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,cAAc;AAClB;AACA;IACI,eAAe;AACnB;AACA;IACI,cAAc;IACd,WAAW;IACX,YAAY;IACZ,2BAA2B;IAC3B,4BAA4B;AAChC","sourcesContent":[".article {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.ul {\n    width: 100%;\n    display: flex;\n    list-style-type: none;\n    justify-content: space-between;\n    padding: 0;\n}\n.li {\n    width: 30px;\n    height: 30px;\n    margin-left: 8px;\n}\n.li:first-child {\n    margin-left: 0;\n}\n.li:hover {\n    cursor: pointer;\n}\n.logo {\n    display: block;\n    width: 30px;\n    height: 30px;\n    background-position: center;\n    background-repeat: no-repeat;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"article": "socialNetworks_article__sQp5C",
	"ul": "socialNetworks_ul__nh-s4",
	"li": "socialNetworks_li__1WZpB",
	"logo": "socialNetworks_logo__WQ28v"
};
export default ___CSS_LOADER_EXPORT___;
