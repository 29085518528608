/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { getCookie } from './utils'

const BASE_URL = 'https://easyQR/api'
const handleResponse = async (response: Response) => {
  if (response.ok) return await response.json()
  else return await Promise.reject(response.status)
}
interface IRegistrationUser {
  email: string
  password: string
  name: string
}
export const registrationUser = async ({
  email,
  password,
  name
}: IRegistrationUser) => {
  return await fetch(`${BASE_URL}/auth/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email,
      password,
      name
    })
  }).then(async (res) => await handleResponse(res))
}
interface IAutorizationUser {
  password: string
  email: string
}
export const autorizationUser = async ({ password, email }: IAutorizationUser) => {
  return await fetch(`${BASE_URL}/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      password,
      email
    })
  }).then(async (res) => await handleResponse(res))
}
export const logoutUser = async () => {
  return await fetch(`${BASE_URL}/auth/logout`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      token: localStorage.refreshToken
    })
  }).then(async (res) => await handleResponse(res))
}
export const refreshToken = async () => {
  return await fetch(`${BASE_URL}/auth/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      token: localStorage.refreshToken
    })
  }).then(async (res) => await handleResponse(res))
}
export const getUser = async () => {
  return await fetch(`${BASE_URL}/auth/user`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getCookie('token')}`
    }
  }).then(async (res) => await handleResponse(res))
}
interface IUpdateUser {
  email: string
  password: string
  name: string
}
export const updateUser = async ({ email, password, name }: IUpdateUser) => {
  return await fetch(`${BASE_URL}/auth/user`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getCookie('token')}`
    },
    body: JSON.stringify({
      email,
      password,
      name
    })
  }).then(async (res) => await handleResponse(res))
}
