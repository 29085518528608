// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".person-update_person-page__EvUPW {\n  max-width: 600px;\n  margin: 0 auto;\n  padding: 20px 15px;\n}\n.person-update_btn__7y-iX {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  padding: 12px 16px;\n  border-radius: 8px;\n  -webkit-appearance: none;\n  text-decoration: none;\n  cursor: pointer;\n  transition: 1s;\n}\n.person-update_btn__7y-iX:hover {\n  opacity: 0.7;\n}", "",{"version":3,"sources":["webpack://./src/pages/person-update/person-update.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,wBAAwB;EACxB,qBAAqB;EACrB,eAAe;EACf,cAAc;AAChB;AACA;EACE,YAAY;AACd","sourcesContent":[".person-page {\n  max-width: 600px;\n  margin: 0 auto;\n  padding: 20px 15px;\n}\n.btn {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  padding: 12px 16px;\n  border-radius: 8px;\n  -webkit-appearance: none;\n  text-decoration: none;\n  cursor: pointer;\n  transition: 1s;\n}\n.btn:hover {\n  opacity: 0.7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"person-page": "person-update_person-page__EvUPW",
	"btn": "person-update_btn__7y-iX"
};
export default ___CSS_LOADER_EXPORT___;
