// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cart-delivery-block_cart-delivery-block__item__9t2MG {\n  color: #091c3b;\n  font-size: 12px;\n  line-height: 24px;\n  text-align: center;\n  letter-spacing: 0.02em;\n  width: 50%;\n  text-align: center;\n  cursor: pointer;\n  margin: 5px;\n  transition: 1s;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.cart-delivery-block_cart-delivery-block__item__9t2MG:hover {\n  opacity: 0.7;\n}\n", "",{"version":3,"sources":["webpack://./src/components/cart-delivery-block/cart-delivery-block.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;EACtB,UAAU;EACV,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,YAAY;AACd","sourcesContent":[".cart-delivery-block__item {\n  color: #091c3b;\n  font-size: 12px;\n  line-height: 24px;\n  text-align: center;\n  letter-spacing: 0.02em;\n  width: 50%;\n  text-align: center;\n  cursor: pointer;\n  margin: 5px;\n  transition: 1s;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.cart-delivery-block__item:hover {\n  opacity: 0.7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cart-delivery-block__item": "cart-delivery-block_cart-delivery-block__item__9t2MG"
};
export default ___CSS_LOADER_EXPORT___;
