import { FC } from 'react'

interface IButtonPopupLine {
  fill: string
  stroke: string
}

const ButtonPopupLine: FC<IButtonPopupLine> = ({ fill, stroke }) => {
  return (
    <svg
      width="48"
      height="5"
      viewBox="0 0 48 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.5C0 1.11929 1.11929 0 2.5 0H45.5C46.8807 0 48 1.11929 48 2.5C48 3.88071 46.8807 5 45.5 5H2.5C1.11929 5 0 3.88071 0 2.5Z"
        fill={fill}
        stroke={stroke}
      />
    </svg>
  )
}

export default ButtonPopupLine
