/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { FC } from 'react'
import { useTextTranslation } from '../../contexts/TextTranslation'
import { TOrder, TRest } from '../../utils/typesFromBackend'
import style from './order-card.module.css'
import { useCurrentLanguage } from '../../contexts/CurrentLanguageContext'
import { useLocalStorageState } from '../../services/hooks/useLocalStorageState'

interface IOrderCard {
  order: TOrder
  rest: TRest
  openModal: (id: string) => void
}
const OrderCard: FC<IOrderCard> = ({ order, rest, openModal }) => {
  const currentLanguage = useCurrentLanguage()
  const [orderStatus, setOrderStatus] = React.useState('')
  const [orderType, setOrderType] = useLocalStorageState('orderType', '')
  const [generalSum, setGeneralSum] = React.useState(0)
  const translation = useTextTranslation()

  React.useEffect(() => {
    if (typeof order.orderStatusId === 'string') {
      const resultStatus = rest.orderStatus_ids.find(
        (status) => status._id === order.orderStatusId
      )
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (resultStatus) {
        setOrderStatus(
          resultStatus.title[currentLanguage]
            ? resultStatus.title[currentLanguage]
            : ''
        )
      }
    }
    if (typeof order.orderType_id === 'string') {
      const resultOrderType = rest.orderType_ids.find(
        (orderType) => orderType._id === order.orderType_id
      )
      setOrderType(
        resultOrderType != null ? resultOrderType.title[currentLanguage] : ''
      )
    }
    if (order.deliveryPrice != null) {
      setGeneralSum(
        Math.round((order.deliveryPrice + order.orderPrice) * 100) / 100
      )
    } else {
      setGeneralSum(order.orderPrice)
    }
  }, [currentLanguage])

  const styleOrderBorder = {
    border: `1px solid ${rest.colorsSchema_id.mainColor}`
  }

  const styleButton = {
    color: rest.colorsSchema_id.buttonTextColor,
    backgroundColor: rest.colorsSchema_id.buttonColor
  }

  const styleTextLight = {
    color: rest.colorsSchema_id.textLight
  }
  const styleTextStatus = {
    color: rest.colorsSchema_id.mainColor
  }
  function handleClick(): void {
    openModal(order._id)
  }
  const dividedGeneralSum = generalSum
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return (
    <div className={style.order} style={styleOrderBorder}>
      <div className={style.order__top}>
        <div className={style.order__title}>
          {translation('order-N')}
          {order.incomingOrderId}
        </div>
        <div className={style.order__price} style={styleTextStatus}>
          {translation('price')}&nbsp;&nbsp;{dividedGeneralSum}&nbsp;
          {rest.currentCurrency}
        </div>
      </div>
      <div className={style.order__middle} style={styleTextLight}>
        <div className={style.order__status}>
          {translation('status')}:&nbsp;
          <span style={styleTextStatus}>{orderStatus}</span>
        </div>
        <div className={style.order__date}>{orderType}</div>
      </div>
      <div className={style.order__bottom}>
        <button
          className={style.order__bottom_button}
          style={{ ...styleOrderBorder, ...styleButton }}
          onClick={handleClick}
        >
          {translation('order-details')}
        </button>
      </div>
    </div>
  )
}
export default OrderCard
