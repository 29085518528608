export const SHOW_CATEGORIES_CARD_VIEW = 'SHOW_CATEGORIES_CARD_VIEW' as const

export interface IToggleCategoriesCardViewState {
  type: typeof SHOW_CATEGORIES_CARD_VIEW
  payload: boolean
}

export const showCategoriesCardView = (
  show: boolean
): IToggleCategoriesCardViewState => ({
  type: SHOW_CATEGORIES_CARD_VIEW,
  payload: show
})

export type ShowCategoriesCardView = ReturnType<typeof showCategoriesCardView>
