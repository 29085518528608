// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pd_container__Nhvs0{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n}\n\n.pd_pd__wXQj8{\n   font-family: 'SF Pro Display';\n   box-sizing: border-box;\n   width: 80%;\n}\n\n.pd_p__GLr0L {\n  white-space: pre;\n}\n\n.pd_hr__dedw2{\n  width: 95%;\n}", "",{"version":3,"sources":["webpack://./src/pages/pd/pd.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;GACG,6BAA6B;GAC7B,sBAAsB;GACtB,UAAU;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".container{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n}\n\n.pd{\n   font-family: 'SF Pro Display';\n   box-sizing: border-box;\n   width: 80%;\n}\n\n.p {\n  white-space: pre;\n}\n\n.hr{\n  width: 95%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "pd_container__Nhvs0",
	"pd": "pd_pd__wXQj8",
	"p": "pd_p__GLr0L",
	"hr": "pd_hr__dedw2"
};
export default ___CSS_LOADER_EXPORT___;
