import { FC } from 'react'
import { TRest } from '../../utils/typesFromBackend'
import HamburgerMenu from '../hamburger-menu/hamburger-menu'
import ButtonProfile from '../ui/button-profile/button-profile'
import style from './header.module.css'
import LanguageSelect from '../language-select/language-select'
import BottomCart from '../bottom-cart/bottom-cart'

interface IHeader {
  title: string
  rest: TRest
  hideButtonProfile?: boolean
  showBottomCart: boolean
  setIsScrollButtonVisible: (arg: boolean) => void
}
const Header: FC<IHeader> = ({
  title,
  rest,
  hideButtonProfile,
  showBottomCart,
  setIsScrollButtonVisible
}) => {
  const styleMainTextColor = {
    color: rest.colorsSchema_id.titleRestColor
  }

  const fill = rest.colorsSchema_id.mainColor
  const stroke = rest.colorsSchema_id.mainColor

  return (
    <div className={style.row}>
      <HamburgerMenu
        rest={rest}
        setIsScrollButtonVisible={setIsScrollButtonVisible}
      />

      {/* Наименование ресторана в Header */}
      <div className={style.title}>
        <p className={style.text} style={styleMainTextColor}>
          {rest.titleRest}
        </p>
      </div>

      <div className={style.cart}>
        {showBottomCart ? <BottomCart rest={rest} /> : ''}
      </div>
      <div className={`${style.nav_items} ${style.close}`}>
        <LanguageSelect rest={rest} />
      </div>
      {hideButtonProfile ?? false ? (
        ''
      ) : (
        <ButtonProfile fill={fill} stroke={stroke} rest={rest} />
      )}
    </div>
  )
}
export default Header
