import React, { FC } from 'react'
import { TCustomInput, TRest } from '../../../utils/typesFromBackend'

import style from './custom-input.module.css'
import { useCurrentLanguage } from '../../../contexts/CurrentLanguageContext'
import { IBorder } from '../../cart-detali/cart-detali'

interface ICustomInput {
  value: string
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  input: TCustomInput
  border: IBorder
  rest: TRest
}
const CustomInput: FC<ICustomInput> = ({ value, handleChange, input, border, rest }) => {
  const currentLanguage = useCurrentLanguage()
  function handleChangeInput (e: React.ChangeEvent<HTMLInputElement>): void {
    handleChange(e)
  }

  const textColor = {
    color: rest.colorsSchema_id.mainTextColor,
    backgroundColor: rest.colorsSchema_id.backgroundColor
  }

  const inputStyle = {
    color: rest.colorsSchema_id.popupInputColor,
    backgroundColor: rest.colorsSchema_id.backgroundColor
  }

  return (
    <div className={style['input-wrapper']}>
      <label style={textColor} className={style['cart-detail__subtitle']}>{input.label[currentLanguage]}</label>

      <input
        className={style['cart-input']}
        style={{ ...border, ...inputStyle }}
        type="text"
        name={input.name}
        required={input.required}
        placeholder={input.placeholder[currentLanguage]}
        value={value}
        onChange={handleChangeInput}
        autoComplete='off'
      />
    </div>
  )
}
export default CustomInput
