export const UPDATE_ORDER_FORM: 'UPDATE_ORDER_FORM' = 'UPDATE_ORDER_FORM'
export const RESET_ORDER_FORM = 'RESET_ORDER_FORM'

interface IUpdatedOrderFormAction {
  readonly type: typeof UPDATE_ORDER_FORM
  payload: IOrderFormValues
}

interface IResetOrderFormAction {
  type: typeof RESET_ORDER_FORM
}

export type OrderFormActionTypes =
  | IUpdatedOrderFormAction
  | IResetOrderFormAction

export interface IOrderFormValues {
  readonly name?: string
  readonly userPhone?: string
  readonly city?: string
  readonly street?: string
  readonly house?: string
  readonly flat?: string
  readonly porch?: string
  readonly floor?: string
  readonly email?: string
  readonly time?: string
}

// экшн-криэйтор для обновления формы заказа
export const updateOrderFormActionCreator = (
  formValues: IOrderFormValues
): OrderFormActionTypes => ({
  type: UPDATE_ORDER_FORM,
  payload: formValues
})

// экшн-криэйтор для сброса формы заказа
export const resetOrderForm = (): IResetOrderFormAction => ({
  type: RESET_ORDER_FORM
})
