import { FC, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../services/actions/notification'
import { useAnimate } from '../../services/hooks/animate'
import style from './notification.module.css'

interface INotification extends React.HTMLAttributes<HTMLDivElement> {
  type: string
  message: string
}

const getClass = (type: string): string | undefined => {
  if (type === 'success') {
    return style.notification__success
  }
  if (type === 'danger') {
    return style.notification__danger
  }
  if (type === 'warning') {
    return style.notification__warning
  }
}

const ANIMATE_PARAMS = {
  mount: {
    style: {
      opacity: 1
    },
    delay: 100
  },
  unMount: {
    style: {
      opacity: 0
    },
    delay: 100
  }
}

const Notification: FC<INotification> = (props) => {
  const { message, type } = props
  const timeout = useRef<ReturnType<typeof setTimeout>>()
  const dispatch = useDispatch()

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const removeNotification = () => {
    dispatch(setNotification({ message: '', type: 'success' }))
  }

  const { styleAnimate } = useAnimate(ANIMATE_PARAMS)

  useEffect(() => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      removeNotification()
    }, 5000)
  }, [message, timeout.current])

  return (
    <div className={style.container} id="notification" onClick={removeNotification}>
      <div className={getClass(type)} style={styleAnimate} >
        <p className={style.notification__p}>{message}</p>
       </div>
    </div>
  )
}

export default Notification
