import React, { createContext, useContext, PropsWithChildren } from 'react'
import { useLocalStorageState } from '../services/hooks/useLocalStorageState'

interface IYandexCity {
  yandexCity: string
  setYandexCity: (arg: string) => void
  yandexStreet: string
  setYandexStreet: (arg: string) => void
  yandexHouse: string
  setYandexHouse: (arg: string) => void
  choiseYandexCity: boolean
  setChoiseYandexCity: (arg: boolean) => void
  choiseYandexStreet: boolean
  setChoiseYandexStreet: (arg: boolean) => void
  choiseYandexHouse: boolean
  setChoiseYandexHouse: (arg: boolean) => void
  optionsCity: any
  setOptionsCity: (arg: any) => void
  optionsStreet: any
  setOptionsStreet: (arg: any) => void
  optionsHouse: any
  setOptionsHouse: (arg: any) => void
}

const YandexCityContext = createContext<IYandexCity>({
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  yandexCity: '',
  setYandexCity: () => {},
  yandexStreet: '',
  setYandexStreet: () => {},
  yandexHouse: '',
  setYandexHouse: () => {},
  choiseYandexCity: false,
  setChoiseYandexCity: () => {},
  choiseYandexStreet: false,
  setChoiseYandexStreet: () => {},
  choiseYandexHouse: false,
  setChoiseYandexHouse: () => {},
  optionsCity: [],
  setOptionsCity: () => {},
  optionsStreet: [],
  setOptionsStreet: () => {},
  optionsHouse: [],
  setOptionsHouse: () => {}
})

export const YandexCityProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  const [yandexCity, setYandexCity] = useLocalStorageState('yandexCity', '')
  const [yandexStreet, setYandexStreet] = useLocalStorageState(
    'yandexStreet',
    ''
  )
  const [yandexHouse, setYandexHouse] = useLocalStorageState('yandexHouse', '')
  const [choiseYandexCity, setChoiseYandexCity] = React.useState(false)
  const [choiseYandexStreet, setChoiseYandexStreet] = React.useState(false)
  const [choiseYandexHouse, setChoiseYandexHouse] = React.useState(false)
  const [optionsCity, setOptionsCity] = React.useState<any>([])
  const [optionsStreet, setOptionsStreet] = React.useState<any>([])
  const [optionsHouse, setOptionsHouse] = React.useState<any>([])

  return (
    <YandexCityContext.Provider
      value={{
        yandexCity,
        setYandexCity,
        yandexStreet,
        setYandexStreet,
        yandexHouse,
        setYandexHouse,
        choiseYandexCity,
        setChoiseYandexCity,
        choiseYandexStreet,
        setChoiseYandexStreet,
        choiseYandexHouse,
        setChoiseYandexHouse,
        optionsCity,
        setOptionsCity,
        optionsStreet,
        setOptionsStreet,
        optionsHouse,
        setOptionsHouse
      }}
    >
      {children}
    </YandexCityContext.Provider>
  )
}

export const useYandexCity = (): IYandexCity => useContext(YandexCityContext)
