/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { FC } from 'react'
import { useTextTranslation } from '../../contexts/TextTranslation'
import style from './cart-address-block.module.css'

import { TCity, TRest, TStreet } from '../../utils/typesFromBackend'
import InputCart from '../ui/input-cart-address/input-cart'
import { IBorder } from '../cart-detali/cart-detali'
import { useYandexCity } from '../../contexts/YandexCityProvider'
import { getCity, getHouse, getStreet } from '../../utils/api/yandexApi'
import { setNotification } from '../../services/actions/notification'
import { useDispatch } from 'react-redux'

interface ICartAdressBlock {
  rest: TRest
  isRemoteOrder: boolean
  currentCityId: string
  setCurrentCityId: (arg0: string) => void
  currentStreet: string
  setCurrentStreet: (arg: string) => void
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  city: string
  street: string
  house: string
  flat: string
  porch: string
  floor: string
  border: IBorder
  setIsDeliveryPrice?: (arg: boolean) => void
  yandexDelivery?: boolean
}
const CartAdressBlock: FC<ICartAdressBlock> = ({
  rest,
  isRemoteOrder,
  currentCityId,
  setCurrentCityId,
  currentStreet,
  setCurrentStreet,
  handleChange,
  city,
  street,
  house,
  flat,
  porch,
  floor,
  border,
  setIsDeliveryPrice,
  yandexDelivery
}) => {
  const translation = useTextTranslation()
  // eslint-disable-next-line
  const [cities, setCities] = React.useState<TCity[]>([])
  // eslint-disable-next-line
  const [streets, setStreets] = React.useState<TStreet[]>([])
  const [openSelectCity, setOpenSelectCity] = React.useState(true)
  const [openSelectStreet, setOpenSelectStreet] = React.useState(true)
  const [openSelectHouse, setOpenSelectHouse] = React.useState(true)
  const dispatch = useDispatch()
  const {
    yandexCity,
    setYandexCity,
    yandexStreet,
    setYandexStreet,
    yandexHouse,
    setYandexHouse,
    choiseYandexCity,
    setChoiseYandexCity,
    choiseYandexStreet,
    setChoiseYandexStreet,
    choiseYandexHouse,
    setChoiseYandexHouse,
    optionsCity,
    setOptionsCity,
    optionsStreet,
    setOptionsStreet,
    optionsHouse,
    setOptionsHouse
  } = useYandexCity()

  const showAlert = (
    message: string,
    type: 'success' | 'danger' | 'warning'
  ): void => {
    dispatch(setNotification({ message, type }))
  }

  React.useEffect(() => {
    if (yandexCity === '') {
      return setOpenSelectCity(false)
    }
    setOpenSelectCity(true)
  }, [yandexCity])

  React.useEffect(() => {
    if (yandexStreet === '') {
      return setOpenSelectStreet(false)
    }
    setOpenSelectStreet(true)
  }, [yandexStreet])

  React.useEffect(() => {
    if (yandexHouse === '') {
      return setOpenSelectHouse(false)
    }
    setOpenSelectHouse(true)
  }, [yandexHouse])

  const prevYandexCityRef = React.useRef(yandexCity)
  const prevYandexStreetRef = React.useRef(yandexStreet)
  const prevYandexHouseRef = React.useRef(yandexHouse)

  React.useEffect(() => {
    const prevYandexCity = prevYandexCityRef.current

    // Проверяем, изменилось ли значение yandexCity
    if (prevYandexCity !== yandexCity) {
      setYandexStreet('')
      setYandexHouse('')
    }

    if (prevYandexCity === yandexCity) {
      setOpenSelectCity(false)
    }

    // Обновляем значение рефа
    prevYandexCityRef.current = yandexCity
  }, [yandexCity])

  React.useEffect(() => {
    const prevYandexStreet = prevYandexStreetRef.current

    // Проверяем, изменилось ли значение yandexStreet
    if (prevYandexStreet !== yandexStreet) {
      setYandexHouse('')
    }

    if (prevYandexStreet === yandexStreet) {
      setOpenSelectStreet(false)
    }

    // Обновляем значение рефа
    prevYandexStreetRef.current = yandexStreet
  }, [yandexStreet])

  React.useEffect(() => {
    const prevYandexHouse = prevYandexHouseRef.current

    if (prevYandexHouse === yandexHouse) {
      setOpenSelectHouse(false)
    }

    // Обновляем значение рефа
    prevYandexHouseRef.current = yandexHouse
  }, [yandexStreet])

  React.useEffect(() => {
    if (yandexCity.trim() !== '' && !choiseYandexCity) {
      getCity(yandexCity)
        .then((data) => {
          // eslint-disable-next-line
          if (data?.results) {
            return setOptionsCity(data.results)
          }
        })
        .catch((err) => {
          showAlert(translation('error-determining-address'), 'danger')
          console.error(err)
        })
    }
  }, [yandexCity])

  React.useEffect(() => {
    if (yandexStreet.trim() !== '' && !choiseYandexStreet) {
      getStreet(yandexCity, yandexStreet)
        .then((data: any) => {
          // eslint-disable-next-line
          if (data?.results) {
            return setOptionsStreet(data.results)
          }
          setOptionsStreet([])
        })
        .catch((err) => {
          showAlert(translation('error-determining-address'), 'danger')
          console.error(err)
        })
    }
  }, [yandexStreet])

  React.useEffect(() => {
    if (yandexHouse.trim() !== '' && !choiseYandexHouse) {
      getHouse(yandexCity, yandexStreet, yandexHouse)
        .then((data) => {
          // eslint-disable-next-line
          if (data?.results) {
            return setOptionsHouse(data.results)
          }
          setOptionsHouse([])
        })
        .catch((err) => {
          showAlert(translation('error-determining-address'), 'danger')
          console.error(err)
        })
    }
  }, [yandexHouse])

  React.useEffect(() => {
    // eslint-disable-next-line
    if (
      yandexCity === '' ||
      !optionsCity.some((option: any) => {
        const [cityName] = yandexCity.split(', ')
        return option.title.text === cityName.trim()
      })
    ) {
      setChoiseYandexCity(false)
      if (setIsDeliveryPrice !== undefined && setIsDeliveryPrice !== null) {
        setIsDeliveryPrice(false)
      }
    }
  }, [yandexCity, optionsCity])

  React.useEffect(() => {
    // eslint-disable-next-line
    if (
      yandexStreet === '' ||
      !optionsStreet.some((option: any) => option.title.text === yandexStreet)
    ) {
      setChoiseYandexStreet(false)
      if (setIsDeliveryPrice !== undefined && setIsDeliveryPrice !== null) {
        setIsDeliveryPrice(false)
      }
    }
  }, [yandexStreet, optionsStreet])

  React.useEffect(() => {
    // eslint-disable-next-line
    if (
      yandexHouse === '' ||
      !optionsHouse.some((option: any) => {
        const houseArray = option.title.text.split(',')
        houseArray.shift()
        return houseArray.join(',') === yandexHouse
      })
    ) {
      setChoiseYandexHouse(false)
      if (setIsDeliveryPrice !== undefined && setIsDeliveryPrice !== null) {
        setIsDeliveryPrice(false)
      }
    }
  }, [yandexHouse, optionsHouse])

  const textColor = {
    color: rest.colorsSchema_id.mainTextColor,
    backgroundColor: rest.colorsSchema_id.backgroundColor
  }

  const inputStyle = {
    color: rest.colorsSchema_id.popupInputColor,
    backgroundColor: rest.colorsSchema_id.backgroundColor
  }

  return (
    <div className={style['params-adress-block']}>
      {/* eslint-disable-next-line */}
      {yandexDelivery && (
        <div>
          <label style={textColor} className='cart-detail__subtitle'>
            {translation('enter-your-address')}
          </label>

          <input
            required={true}
            className={style['cart-input']}
            style={{ ...border, ...inputStyle }}
            placeholder={translation('find-city')}
            type='text'
            value={yandexCity}
            onChange={(e) => setYandexCity(e.target.value)}
          />

          {optionsCity.length > 0 && openSelectCity && (
            <div>
              {choiseYandexCity ? (
                ''
              ) : (
                <div style={{ paddingBottom: '20px' }}>
                  <strong>{translation('choise-city')}</strong>
                </div>
              )}
              {optionsCity.length > 0 &&
                optionsCity.map((option: any, index: any) => (
                  <div
                    style={{
                      cursor: 'pointer',
                      padding: '10px 0px 5px 0px',
                      border: `1px solid ${rest.colorsSchema_id.borderInputColor}`,
                      marginTop: '10px',
                      textAlign: 'center'
                    }}
                    onClick={() => {
                      // eslint-disable-next-line
                      setYandexCity(
                        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        `${option.title.text}, ${option?.subtitle?.text || ''}`
                      )
                      setTimeout(() => setOpenSelectCity(false), 300)
                      setChoiseYandexCity(true)
                    }}
                    key={index}
                  >
                    {/*  eslint-disable-next-line */}
                    {option.title.text}, {option?.subtitle?.text || ''}
                  </div>
                ))}
            </div>
          )}

          <input
            required={true}
            className={style['cart-input']}
            style={{ ...border, ...inputStyle }}
            placeholder={translation('find-street')}
            type='text'
            value={yandexStreet}
            onChange={(e) => setYandexStreet(e.target.value)}
          />
          {optionsStreet.length > 0 && openSelectStreet && (
            <div>
              {choiseYandexStreet ? (
                ''
              ) : (
                <div style={{ paddingBottom: '20px' }}>
                  <strong>{translation('choise-street')}</strong>
                </div>
              )}
              {optionsStreet.length > 0 &&
                optionsStreet
                  .filter((el: any) => el.tags[0] === 'street')
                  .map((option: any, index: any) => (
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px 0px 5px 0px',
                        border: `1px solid ${rest.colorsSchema_id.borderInputColor}`,
                        marginTop: '10px',
                        textAlign: 'center'
                      }}
                      onClick={() => {
                        // eslint-disable-next-line
                        setYandexStreet(`${option.title.text}`)
                        setTimeout(() => setOpenSelectStreet(false), 300)
                        setChoiseYandexStreet(true)
                      }}
                      key={index}
                    >
                      {option.title.text}
                    </div>
                  ))}
            </div>
          )}
          <input
            required={true}
            className={style['cart-input']}
            style={{ ...border, ...inputStyle }}
            placeholder={translation('find-house')}
            type='text'
            value={yandexHouse}
            onChange={(e) => setYandexHouse(e.target.value)}
          />
          {optionsHouse.length > 0 && openSelectHouse && (
            <div>
              {choiseYandexHouse ? (
                ''
              ) : (
                <div style={{ paddingBottom: '20px' }}>
                  <strong>{translation('choise-house')}</strong>
                </div>
              )}
              {optionsHouse.length > 0 &&
                optionsHouse
                  .filter((el: any) => el.tags[0] === 'house')
                  .map((option: any, index: any) => {
                    const house = option.title.text.split(',')
                    house.shift()
                    return (
                      <div
                        style={{
                          cursor: 'pointer',
                          padding: '10px 0px 5px 0px',
                          border: `1px solid ${rest.colorsSchema_id.borderInputColor}`,
                          marginTop: '10px',
                          textAlign: 'center'
                        }}
                        onClick={() => {
                          // eslint-disable-next-line
                          setYandexHouse(`${house}`)
                          setTimeout(() => setOpenSelectHouse(false), 300)
                          setChoiseYandexHouse(true)
                        }}
                        key={index}
                      >
                        {house}
                      </div>
                    )
                  })}
            </div>
          )}
        </div>
      )}
      {/* eslint-disable-next-line */}
      {!yandexDelivery && (
        <>
          <InputCart
            name={'city'}
            placeholder={translation('city')}
            value={city}
            required={true}
            handleChange={handleChange}
            border={border}
            inputStyle={inputStyle}
          />
          <InputCart
            name={'street'}
            placeholder={translation('street')}
            value={street}
            required={true}
            handleChange={handleChange}
            border={border}
            inputStyle={inputStyle}
          />
          <InputCart
            name={'house'}
            placeholder={translation('house')}
            value={house}
            required={true}
            handleChange={handleChange}
            border={border}
            inputStyle={inputStyle}
          />
        </>
      )}
      <InputCart
        name={'flat'}
        placeholder={translation('flat')}
        value={flat}
        handleChange={handleChange}
        required={false}
        border={border}
        inputStyle={inputStyle}
      />
      <InputCart
        name={'porch'}
        placeholder={translation('porch')}
        value={porch}
        handleChange={handleChange}
        required={false}
        border={border}
        inputStyle={inputStyle}
      />
      <InputCart
        name={'floor'}
        placeholder={translation('floor')}
        value={floor}
        handleChange={handleChange}
        required={false}
        border={border}
        inputStyle={inputStyle}
      />
    </div>
  )
}
export default CartAdressBlock
