import React, { FC } from 'react'
import { TDish, TRest } from '../../utils/typesFromBackend'
import style from './cart-product.module.css'
import imageNoPhoto from '../../assets/images/no_photo.png'
import ButtonPlus from '../ui/button-plus/button-plus'
import ButtonMinus from '../ui/button-minus/button-minus'

import { useDispatch } from '../../services/hooks/redux-hooks'
import { ADDED_ITEM, DECREASE_ITEM } from '../../services/actions'
import { BASE_URL_CDN } from '../../utils/const'
import { useCurrentLanguage } from '../../contexts/CurrentLanguageContext'

interface ICartProduct {
  dish: TDish
  rest: TRest
  prodCount: number
  id: string
}
const CartProduct: FC<ICartProduct> = ({ rest, dish, prodCount, id }) => {
  const currentLanguage = useCurrentLanguage()
  const dispatch = useDispatch()
  function handleClickAdd (): void {
    dispatch({ type: ADDED_ITEM, item: dish, quantity: 1, id })
  }

  function handleClickDecrease (): void {
    dispatch({ type: DECREASE_ITEM, item: dish, quantity: 1, id })
  }
  const [prodPrice, setProdPrice] = React.useState(0)
  React.useEffect(() => {
    let priceForOneDish = 0
    priceForOneDish += dish.price
    if (dish.modifiers_ids.length > 0) {
      dish.modifiers_ids.forEach((modifier) => {
        priceForOneDish += modifier.price
      })
    }
    setProdPrice(Math.round(priceForOneDish * 100) / 100)
  }, [dish])

  const dishWeightColor = {
    color: rest.colorsSchema_id.dishWeightColor
  }

  const fill = rest.colorsSchema_id.mainColor
  const stroke = rest.colorsSchema_id.mainColor

  const dividedProdPrice = (prodPrice * prodCount)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return (
    <div className={style['cart-product']}>
      <div className={style['cart-product__image']}>
        <div
          className={style.image}
          style={
            dish.image?.length > 0
              ? { backgroundImage: `url(${BASE_URL_CDN}/${dish.image})` }
              : rest.logoPath.length > 0
                ? { backgroundImage: `url(${rest.logoPath})` }
                : { backgroundImage: `url(${imageNoPhoto})` }
          }
        ></div>
      </div>
      <div className={style['cart-product__title']}>
        <div>
          <span style={dishWeightColor} className={style['cart-product__title-unit']}>
            {/* eslint-disable-next-line */}
          {dish.unit === 'шт' ? '1' : dish.weight ? dish.weight : ''}&nbsp;{dish.unit}
          </span>
        </div>
        <span className={style['cart-product__dish_title']}>
          {dish.title[currentLanguage]}{' '}
        </span>
        {dish.modifiers_ids.length > 0
          ? dish.modifiers_ids.map((modifier) => (
              // eslint-disable-next-line react/jsx-key
              <div
                key={modifier._id}
                className={style['cart-product__title-modifier']}
              >
                <span className={style['cart-product__modifier_title']}>
                  {'- ' + modifier.title[currentLanguage]}
                </span>
              </div>
          ))
          : ''}
      </div>
      <div className={style['cart-product__controls']}>
        <div
          className={style['cart-product__controls-price']}
          style={{ paddingRight: '5px' }}
        >
          <span>{dividedProdPrice}&nbsp;{rest.currentCurrency}</span>
        </div>
        <div className={style['cart-product__controls-buttons']}>
          <div
            className={style['cart-product__controls-buttons-minus']}
            onClick={handleClickDecrease}
            style={{
              border: `1px solid ${rest.colorsSchema_id.borderButtonColor}`
            }}
          >
            <ButtonMinus fill={fill} stroke={stroke} />
          </div>
          <div className={style['cart-product__controls-buttons-count']}>
            {prodCount}&nbsp;
          </div>
          <div
            className={style['cart-product__controls-buttons-plus']}
            onClick={handleClickAdd}
            style={{
              border: `1px solid ${rest.colorsSchema_id.borderButtonColor}`
            }}
          >
            <ButtonPlus fill={fill} stroke={stroke} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default CartProduct
export const CartProductMemo = React.memo(CartProduct)
