/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FC, useEffect, useState } from 'react'
import { TDish, TRest } from '../../utils/typesFromBackend'
import ButtonPopupClose from '../ui/button-popup-close/button-popup-close'
import { CustomSpinner } from '../spinners/customSpinner/customSpinner'
import * as recommendationAPI from '../../utils/api/recommendations-api'
import { useDispatch } from '../../services/hooks/redux-hooks'
import { setNotification } from '../../services/actions/notification'
import { useReactMediaRecorder } from 'react-media-recorder'
import Dish from '../dish/dish'
import styles from './dish-recommendations.module.css'
import Microfon from '../ui/microphone/microphone'
import { useTextTranslation } from '../../contexts/TextTranslation'
import Slider from 'react-slick'

interface IDishClue {
  recommendationDishes: TDish[]
  rest: TRest
  openDishModal: (
    isNoImage: boolean,
    activeModifiers: boolean,
    dish: TDish
  ) => void
  setShowRecomendations: (showRecomendation: boolean) => void
  setRecommendationDishes: (dishes: []) => void
  language: string
  horizontalDisplay: boolean
}

export const DishRecommendations: FC<IDishClue> = ({
  rest,
  openDishModal,
  setShowRecomendations,
  recommendationDishes,
  setRecommendationDishes,
  language,
  horizontalDisplay
}) => {
  const [recommendationsValue, setRecommendationsValue] = useState<string[]>([])
  const [showRecommendation, setShowRecommendation] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isClueSended, setClueSended] = useState<boolean>(false)
  const translation = useTextTranslation()
  const [activeSlide, setActiveSlide] = useState(false)

  useEffect(() => {
    setClueSended(false)
  }, [])
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    initialSlide: 0,
    swipe: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1170,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 545,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 495,
        settings: {
          slidesToShow: 1
        }
      }
    ],
    beforeChange: () => {
      setActiveSlide(true)
    },
    afterChange: () => setActiveSlide(false)
  }

  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const showAlert = (
    message: string,
    type: 'success' | 'danger' | 'warning'
  ) => {
    dispatch(setNotification({ message, type }))
  }

  const handleChange = (e: any): void => {
    setRecommendationsValue(e.target.value)
  }

  const fillButtonClose = rest.colorsSchema_id.buttonTextColor
  const backgroundColorButton = rest.colorsSchema_id.buttonColor
  const styleMainColorButton = {
    backgroundColor: rest.colorsSchema_id.buttonColor,
    color: rest.colorsSchema_id.buttonTextColor
  }
  const handleClickSend = () => {
    setIsLoading(true)
    setRecommendationsValue([''])
    setClueSended(true)
    recommendationAPI
      .sendRecommendationText(rest._id, recommendationsValue)
      .then((res: []) => {
        setRecommendationDishes(res)
        setShowRecomendations(true)
      })
      .catch(() => {
        showAlert(translation('is-error'), 'danger')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleClickClose = () => {
    setShowRecommendation(false)
  }

  const showModal = () => {
    const fixedElem = document.getElementById('icon-recomendation') as HTMLElement
    fixedElem.style.left = ''
    setClueSended(false)
    setShowRecommendation(true)
  }

  const { startRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder(
    { audio: true, blobPropertyBag: { type: 'audio/wav' } }
  )

  const handleStartRecording = () => {
    startRecording()
  }

  const handleStopRecording = () => {
    stopRecording()
    setRecommendationsValue([''])
    setClueSended(true)
  }

  useEffect(() => {
    async function uploadVoice () {
      setIsLoading(true)
      if (mediaBlobUrl) {
        const audioBlob = await fetch(mediaBlobUrl).then(
          async (r) => await r.blob()
        )
        const audiofile = new File([audioBlob], 'audiofile.wav', {
          type: 'audio/wav'
        })
        const formData = new FormData()
        formData.append('file', audiofile)
        formData.append('rest_id', rest._id)
        recommendationAPI
          .sendRecommendationVoice(formData)
          .then((res: []) => {
            setRecommendationDishes(res)
            setShowRecomendations(true)
          })
          .catch((e) => {
            showAlert(`${translation('is-error')} ${e.message}`, 'danger')
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    }
    if (mediaBlobUrl) {
      uploadVoice()
    }
  }, [mediaBlobUrl])

  return showRecommendation
    ? (
        !isClueSended
          ? (
              isLoading
                ? <CustomSpinner rest={rest} />
                : <div className={styles.container} >
                  <div className={styles.recommendations_content}>
                    <div className={styles.popup__container}>
                      <div
                        className={styles.popup__close}
                        style={{ backgroundColor: `${backgroundColorButton}` }}
                        onClick={handleClickClose}
                      >
                        <ButtonPopupClose fill={fillButtonClose} />
                      </div>
                      <div className={styles['theme-input-title']}>
                        {translation('enter-request')}
                      </div>
                      <input
                        type='text'
                        required
                        placeholder={translation('enter-request')}
                        className={styles['theme-input']}
                        value={recommendationsValue}
                        onChange={handleChange}
                      />
                      <button
                        className={styles.btn}
                        style={styleMainColorButton}
                        onClick={handleClickSend}
                      >
                        {translation('request-recommendations')}
                      </button>
                      <div
                        className={styles.microfon}
                        style={styleMainColorButton}
                        onMouseDown={handleStartRecording}
                        onTouchStart={handleStartRecording}
                        onMouseUp={handleStopRecording}
                        onTouchEnd={handleStopRecording}
                      >
                        <Microfon />
                      </div>
                    </div>
                  </div>
                </div >
            )
          : (
            <div className={styles.container}>
              <div className={styles.recommendations_content}>
                <div className={styles.popup__container}>
                  <div
                    className={styles.popup__close}
                    style={{ backgroundColor: `${backgroundColorButton}` }}
                    onClick={handleClickClose}
                  >
                    <ButtonPopupClose fill={fillButtonClose} />
                  </div>
                  {recommendationDishes.length === 0
                    ? (
                        ''
                      )
                    : (
                      <Slider {...settings}>
                        {recommendationDishes.map((dish) => (
                          <Dish
                            key={dish._id}
                            data={dish}
                            isNoImage={false}
                            rest={rest}
                            openDishModal={activeSlide ? () => { } : openDishModal}
                            horizontalDisplay={horizontalDisplay}
                          />
                        ))}
                      </Slider>
                      )}
                </div>
              </div>
            </div>
            )
      )
    : (
      <div className={styles.icon_wrapper} id='icon-recomendation'>
        <button
          className={styles.icon}
          style={styleMainColorButton}
          onClick={showModal}
        >
          ?
        </button>
      </div>
      )
}
