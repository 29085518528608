import { Dispatch, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store'
import { updateOrderFormActionCreator } from '../actions'

export const useFormWithValidation = (): {
  values: any
  handleChange: (event: any) => void
  errors: any
  isValid: boolean
  resetForm: (newValues?: {}, newErrors?: {}, newIsValid?: boolean) => void
  setValues: Dispatch<any>
} => {
  const [values, setValues] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const [isValid, setIsValid] = useState(false)

  const dispatch = useDispatch()
  const orderFormValues = useSelector((state: RootState) => state.orderForm) // берет данные из стейта Redux

  // устанавливает значения из стейта Redux в форму и localStorage
  useEffect(() => {
    if (orderFormValues !== null) {
      setValues(orderFormValues)
      localStorage.setItem('orderFormValues', JSON.stringify(orderFormValues))
    }
  }, [orderFormValues]) // зависимость от изменений orderFormValues

  const handleChange = (event: any): void => {
    const target = event.target
    const name = target.name
    const value = target.value
    dispatch(updateOrderFormActionCreator({ ...values, [name]: value }))
    setErrors({ ...errors, [name]: target.validationMessage })
    setIsValid(target.closest('form').checkValidity())
  }

  const resetForm = useCallback(
    (newValues = {}, newErrors = {}, newIsValid = false) => {
      setValues(newValues)
      setErrors(newErrors)
      setIsValid(newIsValid)
    },
    [setValues, setErrors, setIsValid]
  )

  return { values, handleChange, errors, isValid, resetForm, setValues }
}
