import { TouchEvent, Touch, useState } from 'react'
import { IUseSwipeProps, IUseSwipeReturn } from './types'

export const useSwipe = ({
  slideRight,
  slideLeft,
  slideUp,
  slideDown,
  delta = 150,
  deltaRight,
  deltaLeft,
  deltaUp,
  deltaDown
}: IUseSwipeProps): IUseSwipeReturn => {
  const [beingTouched, setBeingTouched] = useState(false)
  const [touchStartX, setTouchStartX] = useState(0)
  const [touchStartY, setTouchStartY] = useState(0)

  const handleStart = (touchElement: Touch): void => {
    setTouchStartX(touchElement.clientX)
    setTouchStartY(touchElement.clientY)
    setBeingTouched(true)
  }

  const handleMove = (touchElement: Touch): void => {
    if (beingTouched) {
      const { clientX, clientY } = touchElement

      if (clientX - touchStartX > (deltaRight ?? delta)) {
        slideRight?.()
      }
      if (clientX - touchStartX < -(deltaLeft ?? delta)) {
        slideLeft?.()
      }
      if (clientY - touchStartY < -(deltaUp ?? delta)) {
        slideUp?.()
      }
      if (clientY - touchStartY > (deltaDown ?? delta)) {
        slideDown?.()
      }
    }
  }

  function handleEnd(): void {
    setTouchStartX(0)
    setTouchStartY(0)
  }

  const handleTouchStart = (touchStartEvent: TouchEvent): void => {
    handleStart(touchStartEvent.targetTouches.item(0))
  }

  const handleTouchMove = (touchMoveEvent: TouchEvent): void => {
    handleMove(touchMoveEvent.targetTouches.item(0))
  }

  const handleTouchEnd = (): void => {
    handleEnd()
  }

  return {
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd
  }
}
