// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".payment-failure_section__ftAoA {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n  width: 100vw;\n}\n\n.payment-failure_body__vKDMQ{\n  width: 100%;\n  height: 75%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  a{\n    text-decoration: none;\n    font-size: 1.5em;\n    color: black;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/payment-failure/payment-failure.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,WAAW;EACX,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB;IACE,qBAAqB;IACrB,gBAAgB;IAChB,YAAY;EACd;AACF","sourcesContent":[".section {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n  width: 100vw;\n}\n\n.body{\n  width: 100%;\n  height: 75%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  a{\n    text-decoration: none;\n    font-size: 1.5em;\n    color: black;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "payment-failure_section__ftAoA",
	"body": "payment-failure_body__vKDMQ"
};
export default ___CSS_LOADER_EXPORT___;
