// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".offer_body__10zuI {\n    width: 90%;\n    margin: auto;\n    line-height: 1.5;\n    font-size: 1.1rem;\n    text-align: justify;\n    padding-bottom: 30px;\n}", "",{"version":3,"sources":["webpack://./src/pages/offer/offer.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,oBAAoB;AACxB","sourcesContent":[".body {\n    width: 90%;\n    margin: auto;\n    line-height: 1.5;\n    font-size: 1.1rem;\n    text-align: justify;\n    padding-bottom: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "offer_body__10zuI"
};
export default ___CSS_LOADER_EXPORT___;
