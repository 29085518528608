// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button-id_slide-menu__helper__RnxjT {\n  text-decoration: none;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 20px;\n}\n.button-id_slide-menu__helper__RnxjT + .button-id_slide-menu__helper__RnxjT {\n  margin-top: 16px;\n}\n.button-id_btn__FH9Dp {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  padding: 12px 16px;\n  border: none;\n  border-radius: 8px;\n  -webkit-appearance: none;\n  text-decoration: none;\n  cursor: pointer;\n  transition: 1s;\n  width: 100%;\n}\n.button-id_btn__FH9Dp:hover {\n  opacity: 0.7;\n}\n.button-id_btn__FH9Dp.button-id_blue-color__tSmCE {\n  background: #091c3b;\n  color: #fff;\n}\n\n.button-id_btn__FH9Dp.button-id_gray-color__JdxF2 {\n  background: #f0f7fa;\n  color: #091c3b;\n}\n", "",{"version":3,"sources":["webpack://./src/components/button-id/button-id.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,wBAAwB;EACxB,qBAAqB;EACrB,eAAe;EACf,cAAc;EACd,WAAW;AACb;AACA;EACE,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".slide-menu__helper {\n  text-decoration: none;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 20px;\n}\n.slide-menu__helper + .slide-menu__helper {\n  margin-top: 16px;\n}\n.btn {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  padding: 12px 16px;\n  border: none;\n  border-radius: 8px;\n  -webkit-appearance: none;\n  text-decoration: none;\n  cursor: pointer;\n  transition: 1s;\n  width: 100%;\n}\n.btn:hover {\n  opacity: 0.7;\n}\n.btn.blue-color {\n  background: #091c3b;\n  color: #fff;\n}\n\n.btn.gray-color {\n  background: #f0f7fa;\n  color: #091c3b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slide-menu__helper": "button-id_slide-menu__helper__RnxjT",
	"btn": "button-id_btn__FH9Dp",
	"blue-color": "button-id_blue-color__tSmCE",
	"gray-color": "button-id_gray-color__JdxF2"
};
export default ___CSS_LOADER_EXPORT___;
