// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input-cart_cart-input__T885H {\n  font-weight: normal;\n  font-size: 14px;\n  width: 100%;\n  box-sizing: border-box;\n  border-radius: 4px;\n  padding: 12px;\n  margin-top: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/input-cart-address/input-cart.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,eAAe;EACf,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".cart-input {\n  font-weight: normal;\n  font-size: 14px;\n  width: 100%;\n  box-sizing: border-box;\n  border-radius: 4px;\n  padding: 12px;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cart-input": "input-cart_cart-input__T885H"
};
export default ___CSS_LOADER_EXPORT___;
