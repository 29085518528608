// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".group-modifier-in-modal_groupModifier__dlGR1 {\n  display: flex;\n  flex-direction: column;\n}\n.group-modifier-in-modal_container-groupModifier__0hTtO {\n  display: flex;\n  flex-direction: column;\n}\n.group-modifier-in-modal_groupModifier-title__zABkb {\n  margin: 10px;\n}\n.group-modifier-in-modal_container-groupModifier_no_image__01p2R {\n  display: flex;\n  flex-direction: column;\n}\n@media (min-width: 768px) {\n  .group-modifier-in-modal_container-groupModifier_no_image__01p2R {\n    flex-wrap: wrap;\n    flex-direction: row;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/group-modifier-in-modal/group-modifier-in-modal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,YAAY;AACd;AACA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE;IACE,eAAe;IACf,mBAAmB;EACrB;AACF","sourcesContent":[".groupModifier {\n  display: flex;\n  flex-direction: column;\n}\n.container-groupModifier {\n  display: flex;\n  flex-direction: column;\n}\n.groupModifier-title {\n  margin: 10px;\n}\n.container-groupModifier_no_image {\n  display: flex;\n  flex-direction: column;\n}\n@media (min-width: 768px) {\n  .container-groupModifier_no_image {\n    flex-wrap: wrap;\n    flex-direction: row;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"groupModifier": "group-modifier-in-modal_groupModifier__dlGR1",
	"container-groupModifier": "group-modifier-in-modal_container-groupModifier__0hTtO",
	"groupModifier-title": "group-modifier-in-modal_groupModifier-title__zABkb",
	"container-groupModifier_no_image": "group-modifier-in-modal_container-groupModifier_no_image__01p2R"
};
export default ___CSS_LOADER_EXPORT___;
