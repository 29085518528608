import { FC } from 'react'
import { TRest } from '../../utils/typesFromBackend'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { toggleArrowButton } from '../../services/actions/arrow-button'
import style from './change-rest-button.module.css'

interface IChangeRestButton {
  rest: TRest
  buttonTitle: string
  styleMainTextColor: any
}
const ChangeRestButton: FC<IChangeRestButton> = ({ rest, buttonTitle, styleMainTextColor }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isItRestaurantChain = rest.isItRestaurantChain ? rest.isItRestaurantChain : false

  const handleClick = (): void => {
    dispatch(toggleArrowButton(true))
    history.push('/rests-list')
  }

  return (
    <div>
      {isItRestaurantChain ? (
        <div
          onClick={handleClick}
          className={`${style['name-rest']} ${style['center-text']}`}
          style={styleMainTextColor}
        >
          {buttonTitle}
          <span className={style['logo-change-rest']}>
            &#9660;
          </span>
        </div>
      ) : (
        <div
          className={`${style['name-rest']} ${style['center-text']}`}
          style={styleMainTextColor}
        >
          {buttonTitle}
        </div>
      )}
    </div>
  )
}

export default ChangeRestButton
