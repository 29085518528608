/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export function setCookie (
  name: string,
  value: string | null,
  props: any // { [x: string]: any, expires?: any } | undefined
) {
  props = (props != null) || {}
  let exp = props.expires
  if (typeof exp === 'number' && exp) {
    const d = new Date()
    d.setTime(d.getTime() + exp * 1000)
    exp = props.expires = d
  }
  if (exp && exp.toUTCString) {
    props.expires = exp.toUTCString()
  }
  if (value) {
    value = encodeURIComponent(value)
  }
  let updatedCookie = name + '=' + value
  for (const propName in props) {
    updatedCookie += '; ' + propName
    const propValue = props[propName]
    if (propValue !== true) {
      updatedCookie += '=' + propValue
    }
  }
  document.cookie = updatedCookie
}
export function getCookie (name: string) {
  const matches = document.cookie.match(
    // eslint-disable-next-line no-useless-escape
    new RegExp(
      '(?:^|; )' +
        // eslint-disable-next-line no-useless-escape
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)'
    )
  )
  return (matches != null) ? decodeURIComponent(matches[1]) : undefined
}
export function deleteCookie (name: string) {
  setCookie(name, null, { expires: -1 })
}
export function setTokens (res: any) {
  const accessToken = res.accessToken.split('Bearer ')[1]
  const refreshToken = res.refreshToken
  setCookie('token', accessToken, {})
  localStorage.setItem('refreshToken', refreshToken)
}

export function signOut () {
  localStorage.removeItem('refreshToken')
  deleteCookie('token')
}

export function correctPositionWhenOnModal (): void {
  const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth
  const fixedElem = document.getElementById('icon-recomendation') as HTMLElement
  if (fixedElem) {
    const currentLeft = fixedElem.getBoundingClientRect().left
    fixedElem.style.left = `${currentLeft}px`
  }

  document.body.style.overflow = 'hidden'
  document.body.style.paddingRight = `${scrollBarWidth}px`

  const fixedElements = Array.from(document.body.getElementsByTagName('*')).filter(el => window.getComputedStyle(el).position === 'fixed')
  fixedElements.forEach((el) => {
    if (el instanceof HTMLElement) {
      el.style.paddingRight = `${scrollBarWidth}px`
      el.style.boxSizing = 'border-box'
    }
  })
}

export function correctPositionWhenCloseModal () {
  document.body.style.overflow = ''
  document.body.style.paddingRight = ''
  const fixedElements = Array.from(document.body.getElementsByTagName('*')).filter(el => window.getComputedStyle(el).position === 'fixed')
  fixedElements.forEach(el => {
    if (el instanceof HTMLElement) {
      el.style.paddingRight = ''
    }
  })
  const fixedElem = document.getElementById('icon-recomendation') as HTMLElement
  if (fixedElem) {
    fixedElem.style.left = '85%'
  }
}
