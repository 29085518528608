/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/indent */
import React, { FC } from 'react'
import { useCurrentLanguage } from '../../contexts/CurrentLanguageContext'
import { useTextTranslation } from '../../contexts/TextTranslation'

import {
  TCategory,
  TDish,
  TRest,
  TSubCategories
} from '../../utils/typesFromBackend'
import Dish from '../dish/dish'
import style from './sub-category.module.css'
import { Element } from 'react-scroll'

interface ISubCategory {
  category: TCategory
  dishes: TDish[] | []
  subcategories: TSubCategories[] | []
  rest: TRest
  openDishModal: (
    isNoImage: boolean,
    activeModifiers: boolean,
    dish: TDish
  ) => void
  horizontalDisplay: boolean
  setIsDishLoaded: (arg: boolean) => void
}
const SubCategory: FC<ISubCategory> = ({
  category,
  dishes,
  subcategories,
  rest,
  openDishModal,
  horizontalDisplay,
  setIsDishLoaded
}) => {
  const [isProducts, setIsProducts] = React.useState(false)
  const [isSubCategories, setIsSubCategories] = React.useState(false)
  const translation = useTextTranslation()
  const currentLanguage = useCurrentLanguage()

  React.useEffect(() => {
    if (dishes.length > 0) {
      setIsProducts(true)
    } else {
      setIsProducts(false)
    }
  }, [dishes])

  React.useEffect(() => {
    if (subcategories.length > 0) {
      setIsSubCategories(true)
    } else {
      setIsSubCategories(false)
    }
  }, [subcategories])

  const noImagesCategory = category.noImagesCategory
  const title = category.title

  const styleMainTextColor = {
    color: rest.colorsSchema_id.categoryTitleColor
  }

  const windowWidth = window.innerWidth

  return (
    <>
      <div
        className={`${style['category-block__title']} ${style.subtitle}`}
        style={styleMainTextColor}
      >
        {title[currentLanguage]}
      </div>
      <div className={style['products-block']}>
        {noImagesCategory ? (
          isProducts ? (
            <div className={style['products-no-image']}>
              {dishes.map((dish) => (
                <Dish
                  key={dish._id}
                  data={dish}
                  isNoImage={true}
                  rest={rest}
                  openDishModal={openDishModal}
                  horizontalDisplay={horizontalDisplay}
                  setIsDishLoaded={setIsDishLoaded}
                />
              ))}
            </div>
          ) : (
            ''
          )
        ) : isProducts ? (
          <div
            className={
              rest.colorsSchema_id.viewGrid
                ? style.products
                : windowWidth < 768
                ? style['products-flex']
                : style.products
            }
          >
            {dishes.map((dish) => (
              <Dish
                key={dish._id}
                data={dish}
                isNoImage={false}
                rest={rest}
                openDishModal={openDishModal}
                horizontalDisplay={horizontalDisplay}
                setIsDishLoaded={setIsDishLoaded}
              />
            ))}
          </div>
        ) : (
          ''
        )}
      </div>
      {isProducts ? (
        ''
      ) : (
        <div className={style.noproducts}>
          <div className={`${style.alert} ${style['alert-danger']}`}>
            {translation('no-elements')}
          </div>
        </div>
      )}
      {isSubCategories
        ? subcategories.map((subcategory) => (
            <Element
              name={subcategory.category._id}
              className={style['category-block']}
              key={subcategory.category._id}
            >
              <SubCategory
                key={subcategory.category._id}
                category={subcategory.category}
                dishes={subcategory.dishes}
                subcategories={subcategory.subcategories}
                rest={rest}
                openDishModal={openDishModal}
                horizontalDisplay={horizontalDisplay}
                setIsDishLoaded={setIsDishLoaded}
              />
            </Element>
          ))
        : ''}
    </>
  )
}

export default SubCategory
