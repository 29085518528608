import { useEffect } from 'react'

interface ICountdown {
  seconds: number
  setTimer: (param: boolean) => void
  setSeconds: (arg: number) => void
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const CountDownTimer = ({ seconds, setTimer, setSeconds }: ICountdown) => {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const tick = () => {
    if (seconds === 0) {
      setTimer(true)
    }
    setSeconds(seconds - 1)
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type

  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000)
    return () => clearInterval(timerId)
  })

  return (
        <div>
            <p>{': ' + ` ${seconds.toString().padStart(2, '0')}`}</p>
        </div>
  )
}
