import { FC } from 'react'
import { BASE_URL_CDN } from '../../utils/const'
import { TSale } from '../../utils/typesFromBackend'
import style from './sale.module.css'

interface ISale {
  data: TSale
  openSaleModal: (sale: TSale) => void
}
const Sale: FC<ISale> = ({ data, openSaleModal }) => {
  function handleClick (): void {
    openSaleModal(data)
  }
  const image = data.image

  return (
    <div className={style.eventsBlockLink} onClick={handleClick}>
        {/* <picture>
          <source srcSet={`${BASE_URL_CDN}${'/'}${image}`} media="(min-width: 800px)" />
          <img
            className={style.imageSales_blur}
            src={`${BASE_URL_CDN}${'/'}${image}`}
            alt=""
          />
        </picture> */}
      <picture className={style.imageSales_container}>
        {/* Нужно добавить изображение для мобилок */}
        <source srcSet={`${BASE_URL_CDN}${'/'}${image}`} media="(min-width: 800px)" />
        <img
          className={style.imageSales}
          src={`${BASE_URL_CDN}${'/'}${image}`}
          alt=""
        />
      </picture>

    </div>
  )
}

export default Sale
