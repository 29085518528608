import { useCallback, useRef } from 'react'
import { Fn, Options, Result } from './types'

export const useThrottleFn = (
  fn: Fn,
  options: Options = {
    wait: 1000,
    leading: true,
    trailing: true
  }
): Result => {
  const timerRef = useRef<any>()
  const isRunRef = useRef(true)
  const optionsRef = useRef<Options>({})
  optionsRef.current = {
    wait: typeof options.wait === 'number' ? options.wait : 1000,
    leading: typeof options.leading === 'boolean' ? options.leading : true,
    trailing: typeof options.trailing === 'boolean' ? options.trailing : true
  }
  const fnRef = useRef<Fn>(fn)
  fnRef.current = fn

  const throttled = useCallback((...args: any[]) => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!optionsRef.current.leading && !optionsRef.current.trailing) return
    if (isRunRef.current) {
      isRunRef.current = false
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (optionsRef.current.leading) {
        fnRef.current(...args)
      }
      timerRef.current = setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!optionsRef.current.leading) {
          fnRef.current(...args)
        }
        isRunRef.current = true
      }, optionsRef.current.wait)
    }
  }, [])

  const cancel = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
  }, [])

  const flush = useCallback(() => {
    throttled()
  }, [throttled])

  return {
    throttled,
    cancel,
    flush
  }
}
