/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FC, useState } from 'react'
import { useTextTranslation } from '../../contexts/TextTranslation'
import { TDish, TRest } from '../../utils/typesFromBackend'
import Dish from '../dish/dish'
import styles from './dish-recommendations.module.css'
import Slider from 'react-slick'

interface IRecomendationMenu {
  recommendationDishes: TDish[] | []
  rest: TRest
  openDishModal: (
    isNoImage: boolean,
    activeModifiers: boolean,
    dish: TDish
  ) => void
  language: string
  horizontalDisplay: boolean
}

export const RecomendationMenu: FC<IRecomendationMenu> = ({
  recommendationDishes,
  rest,
  openDishModal,
  language,
  horizontalDisplay
}) => {
  const translation = useTextTranslation()
  const [activeSlide, setActiveSlide] = useState(false)

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    initialSlide: 0,
    swipe: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 1265,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1020,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 790,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1
        }
      }
    ],
    beforeChange: () => {
      setActiveSlide(true)
    },
    afterChange: () => setActiveSlide(false)
  }

  const styleMainTextColor = {
    color: rest.colorsSchema_id.mainTextColor,
    borderLeft: `3px solid ${rest.colorsSchema_id.mainTextColor}`
  }

  return (
    <div>
      <div className={styles.menu__container}>
        {' '}
        <h2 className={styles.h1} style={styleMainTextColor}>{translation('your-recommendations')}</h2>
      </div>
      <div className={styles.recommendationArray}>
        <div className={styles.carousel__wrapper}>
          <div
            className={styles.carousel__content}
          >
            <Slider {...settings} className={styles.carousel__slick_slider}>
              {recommendationDishes.map((dish) => (
                <Dish
                  key={dish._id}
                  data={dish}
                  isNoImage={false}
                  rest={rest}
                  openDishModal={activeSlide ? () => { } : openDishModal}
                  horizontalDisplay={horizontalDisplay}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}
