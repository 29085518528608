/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useRef, FC, useState } from 'react'
import { useAnimate } from '../../services/hooks/animate'
import { useOnClickOutside } from '../../services/hooks/on-click-outside'
import { TRest } from '../../utils/typesFromBackend'
import { CountDownTimer } from '../timer/timer'
import * as validatePhoneAPI from '../../utils/api/validate-phone-api'
import ButtonPopupClose from '../ui/button-popup-close/button-popup-close'
import style from './modal-with-code.module.css'
import { useDispatch } from '../../services/hooks/redux-hooks'
import { setNotification } from '../../services/actions/notification'
import { useTextTranslation } from '../../contexts/TextTranslation'
import { CustomSpinner } from '../spinners/customSpinner/customSpinner'

interface IModalWithCode {
  closeModal: () => void
  codeInput: string
  setInputCode: (arg0: string) => void
  rest: TRest
  createOrder: (code: string) => void
  phone: string
  ucallerId?: number
}

const ModalWithCode: FC<IModalWithCode> = ({
  closeModal,
  rest,
  codeInput,
  setInputCode,
  createOrder,
  phone,
  ucallerId
}) => {
  const [seconds, setSeconds] = useState<number>(60)
  const [timer, setTimer] = useState<boolean>(false)
  const [retryButton, SetRetryButton] = useState<boolean>(true)
  const [beingTouched, setBeingTouched] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [touchStartX, setTouchStartX] = useState<number>(0)
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const showAlert = (message: string, type: 'success' | 'danger' | 'warning') => {
    dispatch(setNotification({ message, type }))
  }
  const translation = useTextTranslation()

  const params = {
    mount: {
      style: {
        opacity: 1,
        background: 'rgb(5 32 46 / 90%)'
      },
      delay: 500
    },
    unMount: {
      style: {
        opacity: 0,
        background: 'rgb(5 32 46 / 90%)'
      },
      delay: 1000
    }
  }
  const { styleAnimate, handleClose } = useAnimate(params)

  React.useEffect(() => {
    document.addEventListener('keydown', closeESC)
    return () => document.removeEventListener('keydown', closeESC)
  })
  const EscCode = 27

  function closeESC (evt: KeyboardEvent): void {
    if (evt.keyCode === EscCode) {
      handleClose(closeModal)
    }
  }

  const node = useRef<HTMLDivElement>(null)
  useOnClickOutside(node, handleClick)

  const fillButtonClose = rest.colorsSchema_id.buttonTextColor
  const backgroundColorButton = rest.colorsSchema_id.buttonColor
  const styleMainColorButton = {
    backgroundColor: rest.colorsSchema_id.buttonColor,
    color: rest.colorsSchema_id.buttonTextColor
  }

  function handleClick (): void {
    handleClose(closeModal)
  }
  function handleChange (e: React.ChangeEvent<HTMLInputElement>): void {
    setInputCode(e.target.value)
  }
  function handleClickSend (): void {
    setIsLoading(true)
    createOrder(codeInput)
    setInputCode('')
    handleClose(closeModal)
    setIsLoading(false)
  }
  function handleRetrySendCode (): void {
    validatePhoneAPI
      .sendCodeRetry(phone, ucallerId)
      .then((res) => { })
      .catch(() => {
        showAlert(translation('error-with-sending-sms'), 'danger')
      })
    SetRetryButton(false)
  }

  const handleStart = (clientX: number) => {
    setTouchStartX(clientX)
    setBeingTouched(true)
  }

  const handleMove = (clientX: number) => {
    if (beingTouched) {
      const touchX = clientX
      let deltaX = touchX - touchStartX
      if (deltaX < -150) {
        handleClose(closeModal)
      } else if (deltaX > 0) {
        deltaX = 0
      }
    }
  }

  function handleEnd () {
    setTouchStartX(0)
  }

  const handleTouchStart = (touchStartEvent: React.TouchEvent) => {
    handleStart(touchStartEvent.targetTouches[0].clientX)
  }

  const handleTouchMove = (touchMoveEvent: React.TouchEvent) => {
    handleMove(touchMoveEvent.targetTouches[0].clientX)
  }

  const handleTouchEnd = () => {
    handleEnd()
  }

  return (
    <div
      className={style.popup}
      style={styleAnimate}
      onTouchStart={(touchStartEvent) => handleTouchStart(touchStartEvent)}
      onTouchMove={(touchMoveEvent) => handleTouchMove(touchMoveEvent)}
      onTouchEnd={() => handleTouchEnd()}
    >
      {isLoading
        ? <CustomSpinner rest={rest} />
        : ''
      }
      {retryButton
        ? (
          <div className={style.popup__container} ref={node}>
            <div
              className={style.popup__close}
              style={{ backgroundColor: `${backgroundColorButton}` }}
              onClick={handleClick}
            >
              <ButtonPopupClose fill={fillButtonClose} />
            </div>
            <div className={style['theme-input-title']}>
              {translation('robot-call-on-phone-in-minute')}
            </div>
            <input
              type="text"
              name="userCode"
              required
              placeholder={translation('confirmation-code-4-last-digits')}
              className={style['theme-input']}
              value={codeInput}
              onChange={handleChange}
            />
            <button
              className={style.btn}
              style={styleMainColorButton}
              onClick={handleClickSend}
            >
              {translation('send-code')}
            </button>
            {!timer
              ? (
                <div className={style.retry}>
                  {translation('repeat-send')}{' '}
                  <CountDownTimer
                    seconds={seconds}
                    setTimer={setTimer}
                    setSeconds={setSeconds}
                  />
                </div>
                )
              : (
                <button
                  className={style.btn}
                  style={styleMainColorButton}
                  onClick={handleRetrySendCode}
                >
                  {translation('request-code-again')}
                </button>
                )}
          </div>
          )
        : (
          <div className={style.popup__container} ref={node}>
            <div
              className={style.popup__close}
              style={{ backgroundColor: `${backgroundColorButton}` }}
              onClick={handleClick}
            >
              <ButtonPopupClose fill={fillButtonClose} />
            </div>
            <div className={style['theme-input-title']}>
              {translation('enter-confirmation-code-4-last-digits')}
            </div>
            <input
              type="text"
              name="userCode"
              required
              placeholder={translation('confirmation-code-4-last-digits')}
              className={style['theme-input']}
              value={codeInput}
              onChange={handleChange}
            />
            <button
              className={style.btn}
              style={styleMainColorButton}
              onClick={handleClickSend}
            >
              {translation('send-code')}
            </button>
          </div>
          )}
    </div>
  )
}
export default ModalWithCode
