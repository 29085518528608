import { CSSProperties, useCallback, useEffect, useState } from 'react'

interface UseAnimateProps {
  mount: {
    style: CSSProperties
    delay: number
  }
  unMount: {
    style: CSSProperties
    delay: number
  }
}

export const useAnimate = (config: UseAnimateProps): { handleClose: (handleClick: () => void) => () => void, styleAnimate: CSSProperties } => {
  const { mount, unMount } = config
  const [styleAnimate, setStyle] = useState<CSSProperties>(unMount.style)

  /** Размонтирование компонента */
  const handleClose = useCallback(
    (handleClick: () => void) => {
      setStyle({
        ...unMount.style,
        transition: `all ${unMount.delay / 1000}s`
      })

      const timer = setTimeout(() => handleClick(), unMount.delay)
      return () => clearTimeout(timer)
    },
    [unMount]
  )

  /** Монтирование компонента */
  useEffect(() => {
    const timer = setTimeout(
      () =>
        setStyle({ ...mount.style, transition: `all ${mount.delay / 1000}s` }),
      mount.delay
    )

    return () => clearTimeout(timer)
  }, [])

  return { handleClose, styleAnimate }
}
