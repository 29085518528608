export const TOGGLE_ARROW_BUTTON = 'TOGGLE_ARROW_BUTTON' as const

export interface IToggleArrowButtonState {
  type: typeof TOGGLE_ARROW_BUTTON
  payload: boolean
}

export const toggleArrowButton = (show: boolean): IToggleArrowButtonState => ({
  type: TOGGLE_ARROW_BUTTON,
  payload: show
})

export type ToggleArrowButtonAction = ReturnType<typeof toggleArrowButton>
