import { BASE_URL } from '../const'
import { handleResponse } from '../helpers'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
export const sendCode = async (phone: string) => {
  return await fetch(`${BASE_URL}/validate/phone`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      phone
    })
  }).then(async (res) => await handleResponse(res))
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const sendCodeRetry = async (phone: string, ucallerId?: number) => {
  return await fetch(`${BASE_URL}/validate/phone`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      phone,
      ucallerId
    })
  }).then(async (res) => await handleResponse(res))
}
