import React, { FC } from 'react'

import style from './textarea-comment.module.css'
import { IBorder } from '../../cart-detali/cart-detali'
import { TRest } from '../../../utils/typesFromBackend'

interface ITextAreaComment {
  value: string
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  border: IBorder
  rest: TRest
}
const TextareaComment: FC<ITextAreaComment> = ({ value, handleChange, border, rest }) => {
  const inputStyle = {
    color: rest.colorsSchema_id.popupInputColor,
    backgroundColor: rest.colorsSchema_id.backgroundColor
  }
  return (
    <textarea
      name="comment"
      rows={4}
      value={value}
      onChange={handleChange}
      className={style['cart-input']}
      style={{ ...border, ...inputStyle }}
    />
  )
}
export default TextareaComment
