import { combineReducers } from 'redux'
import { cartReducer } from './cart'
import { notificationReducer } from './notification'
import { userReducer } from './user'
import { wsReducer } from './websocket'
import orderFormValuesReducer from './order-form'
import { arrowButtonReducer } from './arrow-button'
import { showCategoriesCardViewReducer } from './categories-card-view'

export const rootReducer = combineReducers({
  cart: cartReducer,
  orderForm: orderFormValuesReducer,
  user: userReducer,
  socket: wsReducer,
  notification: notificationReducer,
  showArrowButton: arrowButtonReducer,
  showCategoriesCards: showCategoriesCardViewReducer
})
