import React, { FC } from 'react'
import { useTextTranslation } from '../../contexts/TextTranslation'
import Header from '../../components/header/header'
import { TRest } from '../../utils/typesFromBackend'
/* import { BASE_URL_CDN } from '../../utils/const' */
import style from './condition-for-returning-goods.module.css'

interface TConditonForReturning {
  rest: TRest
  setIsScrollButtonVisible: (arg: boolean) => void
}

const ConditonForReturning: FC<TConditonForReturning> = ({
  rest,
  setIsScrollButtonVisible
}) => {
  const translation = useTextTranslation()

  return (
    <>
      <section className={style.section}>
        <Header
          title={translation('terms-of-payment')}
          rest={rest}
          showBottomCart={true}
          setIsScrollButtonVisible={setIsScrollButtonVisible}
        />
        {/* eslint-disable-next-line */}
        {rest.isReturningGoods ? (
          rest.textReturningGoods
        ) : (
          <div
            style={{ color: rest.colorsSchema_id.mainTextColor }}
            className={style.body}
          >
            <h2>
              Условия возврата товаров, купленных в онлайн-ресторане «
              {rest.titleRest}».
            </h2>
            <h3>Когда можно вернуть продукты питания:</h3>
            <p>
              Возврату подлежат продукты питания ненадлежащего качества при
              условии, что недостаток возник до передачи товара покупателю.
              Продукт является товаром ненадлежащего качества, если:
            </p>
            <ul>
              <li>имеет неприятный, несвойственный ему запах,</li>
              <li>имеет несвойственный ему цвет,</li>
              <li>упаковка вскрыта,</li>
              <li>описание на упаковке не соответствует товару,</li>
              <li>внутри продукта есть сторонние организмы или предметы.</li>
            </ul>
            <p>
              Продукты питания надлежащего качества в неповрежденной упаковке и
              с действующим сроком годности возврату и обмену не подлежат. Вы
              вправе отказаться от покупки товара в любое время до его доставки,
              во время приема доставки до момента его оплаты курьеру. Если вы
              приобрели товар ненадлежащего качества, обратитесь к продавцу.
              Контакты указаны на данной <a href='/about-us'>странице</a>{' '}
              веб-сайта. Вы вправе требовать обмена товара ненадлежащего
              качества или возврата денег в соответствии
              <a href='https://www.consultant.ru/document/cons_doc_LAW_305/'>
                с Федеральным законом РФ «О защите прав потребителей»
              </a>
              . Условия возврата непродовольственных товаров в онлайн-ресторане
              «{rest.titleRest}»
            </p>
            <p>
              Когда можно вернуть товар: Непродовольственный товар надлежащего
              качества вы вправе вернуть продавцу в течение 7 дней (
              <a href='https://www.consultant.ru/document/cons_doc_LAW_305/'>
                ст. 26.1 ФЗ «О защите прав потребителей»
              </a>
              ) с момента его покупки без объяснения причин при соблюдении
              следующих условий:
            </p>
            <ul>
              <li>товар не был в употреблении;</li>
              <li>сохранен первоначальный вид товара и ярлыки;</li>
              <li>комплектация и упаковка не повреждены;</li>
              <li>товар не относится к товарам-исключениям;</li>
              <li>сохранен товарный или кассовый чеки.</li>
            </ul>
            <p>
              Не подлежат возврату следующие товары (
              <a href='https://www.consultant.ru/document/cons_doc_LAW_17579/'>
                постановление Правительства РФ от 19 января 1998 г. № 55
              </a>
              ): Товары для профилактики и лечения заболеваний в домашних
              условиях (предметы санитарии и гигиены из металла, резины,
              текстиля и других материалов, инструменты, приборы и аппаратура
              медицинские, средства гигиены полости рта, линзы очковые, предметы
              по уходу за детьми), лекарственные препараты.
              <br />
              Предметы личной гигиены (зубные щетки, расчески, заколки, бигуди
              для волос, парики, шиньоны и другие аналогичные товары).
              Парфюмерно-косметические товары.
              <br />
              Текстильные товары (хлопчатобумажные, льняные, шелковые, шерстяные
              и синтетические ткани, товары из нетканых материалов типа тканей —
              ленты, тесьма, кружево и другие); кабельная продукция (провода,
              шнуры, кабели); строительные и отделочные материалы (линолеум,
              пленка, ковровые покрытия и другие) и другие товары, отпускаемые
              на метраж. Швейные и трикотажные изделия (белье, чулочно-носочные
              изделия).
              <br />
              Изделия и материалы, контактирующие с пищевыми продуктами, из
              полимерных материалов, в том числе для разового использования
              (посуда и принадлежности столовые и кухонные, емкости и
              упаковочные материалы для хранения и транспортирования пищевых
              продуктов). Товары бытовой химии, пестициды и агрохимикаты.
              <br />
              Мебель бытовая (мебельные гарнитуры и комплекты)
              <br />
              Ювелирные и другие изделия из драгоценных металлов и (или)
              драгоценных камней, ограненные драгоценные камни.
              <br />
              Автомобили и мотовелотовары, прицепы и номерные агрегаты к ним;
              мобильные средства малой механизации сельскохозяйственных работ;
              прогулочные суда и иные плавсредства бытового назначения.
              Технически сложные товары бытового назначения, на которые
              установлены гарантийные сроки (станки металлорежущие и
              деревообрабатывающие бытовые; электробытовые машины и приборы;
              бытовая радиоэлектронная аппаратура; бытовая вычислительная и
              множительная техника; фото- и киноаппаратура; телефонные аппараты
              и факсимильная аппаратура; электромузыкальные инструменты; игрушки
              электронные, бытовое газовое оборудование и устройства; часы
              наручные и карманные механические, электронно-механические и
              электронные, с двумя и более функциями).
              <br />
              Гражданское оружие, основные части гражданского и служебного
              огнестрельного оружия, патроны к нему. Животные и растения.
              Непериодические издания (книги, брошюры, альбомы, картографические
              и нотные издания, листовые изоиздания, календари, буклеты,
              издания, воспроизведенные на технических носителях информации).
              Непродовольственный товар ненадлежащего качества, при условии, что
              недостаток возник до передачи товара покупателю, можно вернуть
              продавцу в соответствии с{' '}
              <a href='https://www.consultant.ru/document/cons_doc_LAW_305/'>
                Федеральным законом РФ «О защите прав потребителей»
              </a>
              . Предъявление товара ненадлежащего качества, купленного в
              онлайн-ресторане «{rest.titleRest}», обязательно. Наш
              онлайн-ресторан работает в строгом соответствии с Законом «О
              защите прав потребителей».
              <br />
              Согласно ст. 25 Закона «О защите прав потребителей», вы можете
              вернуть или обменять товар надлежащего качества, приобретённый в
              розничном магазине, в течение 14 дней, не считая дня покупки.
              Согласно ст. 26.1 Закона «О защите прав потребителей», вы вправе
              вернуть любой товар, приобретённый в интернет-магазине, в течение
              семи дней после получения товара без указания причин возврата.
              <br />
              Возврат товара надлежащего качества возможен в случае, если
              сохранены его товарный вид, потребительские свойства, а также
              документ, подтверждающий факт и условия покупки указанного товара.
              Отсутствие у потребителя документа, подтверждающего факт и условия
              покупки товара, не лишает его возможности ссылаться на другие
              доказательства приобретения товара у данного продавца. Потребитель
              не вправе отказаться от товара надлежащего качества, имеющего
              индивидуально-определенные свойства, если указанный товар может
              быть использован исключительно приобретающим его потребителем
              (прим.: если товар изготовлен на заказ).
              <br />
              При отказе потребителя от товара продавец должен возвратить ему
              денежную сумму, уплаченную потребителем по договору, за
              исключением расходов продавца на доставку от потребителя
              возвращенного товара, не позднее чем через десять дней со дня
              предъявления потребителем соответствующего требования.
              <br />
            </p>
            <h3>Процедура возврата денежных средств:</h3>
            <p>
              1)Обратитесь к нам по номеру телефона {`${rest.workPhone}`} или
              напишите письмо на адрес {rest.email} c указанием номера заказа,
              возвращаемого товара и причины возврата.
              <br /> 2) Согласуйте с менеджером, как Вы будете возвращать товар
              ненадлежащего качества - через курьера или через пункт самовывоза.
              <br /> 3) Возврат денежных средств происходит после возврата
              товара ненадлежащего качества в ресторан. Если Вы оплачивали заказ
              на сайте онлайн, деньги вернутся на карту. Если оплачивали курьеру
              или в пункте выдачи, менеджер запросит у Вас реквизиты для
              перечисления средств.
              <br /> Возврат денежных средств по ошибочным операциям:
              <br /> Для возврата денежных средств по операциям проведенными с
              ошибками необходимо обратиться с письменным заявлением и
              приложением копии паспорта и чеков/квитанций, подтверждающих
              ошибочное списание. Данное заявление необходимо направить по
              адресу {rest.email}.
            </p>
            <p>
              Контакты для связи с рестораном по вопросам обмена или возврата
              товаров:
              <br />
              Email: {rest.email}
              <br />
              Телефон: {`${rest.workPhone}`}
            </p>
          </div>
        )}
      </section>
    </>
  )
}

export default ConditonForReturning
