import { FC } from 'react'
import style from './hamburger.module.css'

interface IHamburger {
  open: boolean
  setOpen: (v: boolean) => void
  backgroundColor: string
}
const Hamburger: FC<IHamburger> = ({ open, setOpen, backgroundColor }) => {
  const styleBackgroundColor = {
    background: backgroundColor
  }
  return (
    <div
      className={style.close}
      onClick={() => setOpen(!open)}
    >
      <div
        className={`${style.line} ${style.lineClose}`}
        style={styleBackgroundColor}
      ></div>
      <div
        className={`${style.line} ${style.lineClose}`}
        style={styleBackgroundColor}
      ></div>
      <div
        className={`${style.line} ${style.lineClose}`}
        style={styleBackgroundColor}
      ></div>
    </div>
  )
}

export default Hamburger
