// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bottom-cart_bottom-line__TpJ5R {\n  box-sizing: border-box;\n  min-width: 20%;\n  display: flex;\n  justify-content: flex-end;\n}\n.bottom-cart_bottom-line__cart__IamgG {\n  text-decoration: none;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 6px 8px;\n  border-radius: 8px;\n  -webkit-appearance: none;\n}\n.bottom-cart_bottom-line__cart-title__HUMf- {\n  font-weight: normal;\n  font-size: 12px;\n  line-height: 16px;\n  color: inherit;\n}\n.bottom-cart_bottom-line__cart-sum__y5ZWA {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 20px;\n  color: inherit;\n  white-space: nowrap;\n  margin-left: 5px;\n}\n#bottom-cart_bottom-line__cart-sum__y5ZWA {\n  color: inherit;\n}\n", "",{"version":3,"sources":["webpack://./src/components/bottom-cart/bottom-cart.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,cAAc;EACd,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,qBAAqB;EACrB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,wBAAwB;AAC1B;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,cAAc;AAChB","sourcesContent":[".bottom-line {\n  box-sizing: border-box;\n  min-width: 20%;\n  display: flex;\n  justify-content: flex-end;\n}\n.bottom-line__cart {\n  text-decoration: none;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 6px 8px;\n  border-radius: 8px;\n  -webkit-appearance: none;\n}\n.bottom-line__cart-title {\n  font-weight: normal;\n  font-size: 12px;\n  line-height: 16px;\n  color: inherit;\n}\n.bottom-line__cart-sum {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 20px;\n  color: inherit;\n  white-space: nowrap;\n  margin-left: 5px;\n}\n#bottom-line__cart-sum {\n  color: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bottom-line": "bottom-cart_bottom-line__TpJ5R",
	"bottom-line__cart": "bottom-cart_bottom-line__cart__IamgG",
	"bottom-line__cart-title": "bottom-cart_bottom-line__cart-title__HUMf-",
	"bottom-line__cart-sum": "bottom-cart_bottom-line__cart-sum__y5ZWA"
};
export default ___CSS_LOADER_EXPORT___;
