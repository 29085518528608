import React, { FC } from 'react'
import { TButton } from '../../utils/typesFromBackend'
import * as buttonValueAPI from '../../utils/api/bottons-api'
import style from './button-id.module.css'
import { useCurrentLanguage } from '../../contexts/CurrentLanguageContext'

interface IButtonId {
  button: TButton
  setOpen: (v: boolean) => void
  open: boolean
  buttonTextColor: string
  buttonColor: string
  rest_id: string
  numberTable?: string | null
}

const ButtonId: FC<IButtonId> = ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  rest_id,
  button,
  numberTable,
  setOpen,
  open,
  buttonTextColor,
  buttonColor
}) => {
  const styleMainColorButton = {
    backgroundColor: buttonColor,
    color: buttonTextColor,
    marginBottom: '20px'
  }
  const language = useCurrentLanguage()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClick = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    buttonValueAPI.sendButtonValue(rest_id, button._id, numberTable)
  }

  return button.active
    ? (
      <button
        type="button"
        className={`${style.btn} ${style['slide-menu__helper']}`}
        style={styleMainColorButton}
        onClick={() => {
          setOpen(!open)
          handleClick()
        }}
      >
        {button.title[language]}
      </button>
      )
    : (
      <></>
      )
}

export default ButtonId
