import React, { FC } from 'react'
import { TDish, TRest } from '../../utils/typesFromBackend'
import style from './order-product.module.css'
import imageNoPhoto from '../../assets/images/no_photo.png'
import { useCurrentLanguage } from '../../contexts/CurrentLanguageContext'
import { BASE_URL_CDN } from '../../utils/const'

interface IOrderProduct {
  dish: TDish
  rest: TRest
  prodCount: number
}
const OrderProduct: FC<IOrderProduct> = ({ dish, rest, prodCount }) => {
  const [prodPrice, setProdPrice] = React.useState(0)
  const currentLanguage = useCurrentLanguage()
  React.useEffect(() => {
    let priceForOneDish = 0
    priceForOneDish += dish.price
    if (dish.modifiers_ids.length > 0) {
      dish.modifiers_ids.forEach((modifier) => {
        priceForOneDish += modifier.price
      })
    }
    setProdPrice(Math.round(priceForOneDish * 100) / 100)
  }, [dish])
  const styleLoght = {
    background: rest.colorsSchema_id.loght
  }

  const dividedProdPrice = prodPrice
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return (
    <div className={style['cart-product']}>
      <div className={style['cart-product__image']}>
        <img
          className={style.image}
          src={
            dish.image.length > 0
              ? `${BASE_URL_CDN}` + '/' + `${dish.image}`
              : rest.logoPath.length > 0
                ? `${BASE_URL_CDN}` + '/' + `${rest.logoPath}`
                : `${BASE_URL_CDN}` + '/' + `${imageNoPhoto}`
          }
        ></img>
      </div>
      <div className={style['cart-product__title']}>
        {dish.title.RU},{' '}
        <span className={style['cart-product__title-unit']}>
        {/* eslint-disable-next-line */}
        {dish.unit === 'шт' ? '1' : dish.weight ? dish.weight : ''}&nbsp;{dish.unit}
        </span>
        {dish.modifiers_ids.length > 0
          ? dish.modifiers_ids.map((modifier) => (
              // eslint-disable-next-line react/jsx-key
              <div className={style['cart-product__title-modifier']}>
                {'- ' + modifier.title[currentLanguage]}
              </div>
          ))
          : ''}
      </div>
      <div className={style['cart-product__controls']}>
        <div
          className={style['cart-product__controls-price']}
          style={{ paddingRight: '5px' }}
        >
          {dividedProdPrice}&nbsp;{rest.currentCurrency}
        </div>
        <div
          className={style['cart-product__controls-buttons']}
          style={styleLoght}
        >
          <div className={style['cart-product__controls-buttons-count']}>
            {prodCount}&nbsp;{'шт'}
          </div>
        </div>
      </div>
    </div>
  )
}
export default OrderProduct
