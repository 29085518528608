import { useHistory } from 'react-router'
import style from './adult.module.css'
import { TRest } from '../../utils/typesFromBackend'
import { FC } from 'react'
import { useTextTranslation } from '../../contexts/TextTranslation'

interface IIsAdult {
  rest: TRest
  setIsAdult: React.Dispatch<React.SetStateAction<boolean>>
}

const IsAdult: FC<IIsAdult> = ({ rest, setIsAdult }) => {
  const history = useHistory()
  const translation = useTextTranslation()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClickNotConfirm = () => {
    history.push('/adultNotConfirm')
    localStorage.clear()
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClicktConfirm = () => {
    localStorage.setItem('adult', 'adult')
    setIsAdult(false)
    history.push('/')
  }

  return (
    <div className={style.popup}>
      <div className={style.popup__container}>
        <h3>{translation('welcome-our-site')}</h3>
        <div className={style['theme-input-title']}>
          {translation('have-you-18-old')}
        </div>
        <div>
          <button className={style.btn__confirm} onClick={handleClicktConfirm}>
            {translation('yes')}
          </button>
          <button
            className={style.btn__notConfirm}
            onClick={handleClickNotConfirm}
          >
            {translation('no')}
          </button>
        </div>
      </div>
    </div>
  )
}
export default IsAdult
