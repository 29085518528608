// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".custom-input_input-wrapper__9r1Q\\+ {\n  position: relative;\n}\n\n.custom-input_input-wrapper__9r1Q\\+ label {\n  position: absolute;\n  top: -14px;\n  left: 20px;\n  background: #FFF;\n  padding: 0 10px;\n}\n\n.custom-input_cart-detail__subtitle__czkEN {\n  margin: 10px 0 5px;\n}\n.custom-input_cart-input__VaTwq {\n  font-weight: normal;\n  font-size: 14px;\n  width: 100%;\n  box-sizing: border-box;\n  border-radius: 4px;\n  padding: 12px;\n  margin-top: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/custom-input/custom-input.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU;EACV,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".input-wrapper {\n  position: relative;\n}\n\n.input-wrapper label {\n  position: absolute;\n  top: -14px;\n  left: 20px;\n  background: #FFF;\n  padding: 0 10px;\n}\n\n.cart-detail__subtitle {\n  margin: 10px 0 5px;\n}\n.cart-input {\n  font-weight: normal;\n  font-size: 14px;\n  width: 100%;\n  box-sizing: border-box;\n  border-radius: 4px;\n  padding: 12px;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-wrapper": "custom-input_input-wrapper__9r1Q+",
	"cart-detail__subtitle": "custom-input_cart-detail__subtitle__czkEN",
	"cart-input": "custom-input_cart-input__VaTwq"
};
export default ___CSS_LOADER_EXPORT___;
