/* eslint-disable multiline-ternary */
import React, { FC } from 'react'

import { TRest } from '../../utils/typesFromBackend'
import Header from '../../components/header/header'
import InputPhone from '../../components/ui/input-phone/input-phone'
import style from './authorization-user.module.css'
import ModalWithCode from '../../components/modal-with-code/modal-with-code'
import * as validatePhoneAPI from '../../utils/api/validate-phone-api'
import * as userAPI from '../../utils/api/user-api'
import { useDispatch } from '../../services/hooks/redux-hooks'
import { USER_REQUEST_SUCCESS } from '../../services/actions'
import { useHistory } from 'react-router-dom'
import { setNotification } from '../../services/actions/notification'
import { useTextTranslation } from '../../contexts/TextTranslation'
import { IBorder } from '../../components/cart-detali/cart-detali'
import { CustomSpinner } from '../../components/spinners/customSpinner/customSpinner'
import useLinkWithParams from '../../services/hooks/useLinkWithParams'

interface IAuthorizationUser {
  rest: TRest
  setIsScrollButtonVisible: (arg: boolean) => void
}
const AuthorizationUser: FC<IAuthorizationUser> = ({
  rest,
  setIsScrollButtonVisible
}) => {
  const [value, setValue] = React.useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validPhone, setValidPhone] = React.useState(true)
  const [isCodeInput, setIsCodeInput] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [codeInput, setCodeInput] = React.useState('')
  const [ucallerId, setUcallerId] = React.useState<number>()
  const translation = useTextTranslation()

  const border: IBorder = {
    border: `1px dashed ${rest.colorsSchema_id.borderInputColor}`
  }

  const createLink = useLinkWithParams()

  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const showAlert = (
    message: string,
    type: 'success' | 'danger' | 'warning'
  ) => {
    dispatch(setNotification({ message, type }))
  }
  const history = useHistory()

  const styleMainTextColor = {
    color: rest.colorsSchema_id.mainTextColor
  }

  const styleMainColorButton = {
    backgroundColor: rest.colorsSchema_id.buttonColor,
    color: rest.colorsSchema_id.buttonTextColor
  }

  const handleChange = (e: any): void => {
    setValue(e.target.value)
  }
  function closeModal(): void {
    setIsCodeInput(false)
  }
  const phone = value.replace(/[()-]/g, '').replace(/\s/g, '')
  function autorization(codeInput: string): void {
    setIsLoading(true)
    // пытаемся авторизироваться
    userAPI
      .authorizationUser(codeInput, phone)
      .then((user) => {
        // если успешно - то пользователя добавляем в стор и переходим на роут /person
        dispatch({ type: USER_REQUEST_SUCCESS, user: user.user_exist })

        history.push(createLink('/person'))
      })
      .catch(() => {
        showAlert(translation('you-can-not-autorization'), 'danger')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  function handleClick(): void {
    setIsLoading(true)
    setIsCodeInput(true)
    // вызываем модалку с кодом
    validatePhoneAPI
      .sendCode(phone)
      .then((res) => {
        setUcallerId(res.ucaller_id)
      })
      .catch(() => {
        showAlert(translation('error-sending-sms-code'), 'danger')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  return (
    <section className={style.section} style={styleMainTextColor}>
      {isLoading ? <CustomSpinner rest={rest} /> : ''}
      <Header
        title={translation('authorization')}
        rest={rest}
        showBottomCart={true}
        setIsScrollButtonVisible={setIsScrollButtonVisible}
      />
      <div className={style['person-sign']}>
        <InputPhone
          rest={rest}
          value={value}
          handleChange={handleChange}
          setValidPhone={setValidPhone}
          border={border}
        />
        <button
          className={style.btn}
          style={styleMainColorButton}
          onClick={handleClick}
          disabled={!validPhone}
        >
          {translation('enter')}
        </button>
      </div>
      {isCodeInput ? (
        <ModalWithCode
          setInputCode={setCodeInput}
          codeInput={codeInput}
          closeModal={closeModal}
          rest={rest}
          createOrder={autorization}
          phone={phone}
          ucallerId={ucallerId}
        />
      ) : (
        ''
      )}
    </section>
  )
}
export default AuthorizationUser
