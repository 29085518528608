import React, { FC } from 'react'
import Header from '../../components/header/header'
import ModalCurrentOrder from '../../components/modal-current-order/modal-current-order'
import OrderCard from '../../components/order-card/order-card'
import { TOrder, TRest } from '../../utils/typesFromBackend'
import style from './orders.module.css'
import * as orderAPI from '../../utils/api/order-api'
import { useSelector } from '../../services/hooks/redux-hooks'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../services/actions/notification'
import { CustomSpinner } from '../../components/spinners/customSpinner/customSpinner'
import { useTextTranslation } from '../../contexts/TextTranslation'
import { Link } from 'react-router-dom'
import useLinkWithParams from '../../services/hooks/useLinkWithParams'

interface IOrders {
  rest: TRest
  setIsScrollButtonVisible: (arg: boolean) => void
}
const Orders: FC<IOrders> = ({ rest, setIsScrollButtonVisible }) => {
  const [isVisibleOrder, setIsVisibleOrder] = React.useState(false)
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const [detailOrder, setDetailOrder] = React.useState<TOrder>({} as TOrder)
  const [orders, setOrders] = React.useState<TOrder[]>([])
  const [isLoaded, setIsLoaded] = React.useState<boolean>(false)
  const person = useSelector((store) => store.user)
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const showAlert = (
    message: string,
    type: 'success' | 'danger' | 'warning'
  ) => {
    dispatch(setNotification({ message, type }))
  }
  const translation = useTextTranslation()

  React.useEffect(() => {
    if (isVisibleOrder) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isVisibleOrder])

  React.useEffect(() => {
    orderAPI
      .getAllOrdersUserInRest({ rest_id: rest._id, user_id: person._id })
      .then((res) => {
        setOrders(res)
        setIsLoaded(true)
      })
      .catch(() => {
        showAlert(translation('do-not-take-your-orders'), 'danger')
      })
  }, [])
  function openModal(id: string): void {
    const resultOrder = orders.find((order) => order._id === id)
    if (resultOrder != null) {
      setDetailOrder(resultOrder)
      setIsVisibleOrder(true)
    }
  }

  function closeModal(): void {
    setIsVisibleOrder(false)
  }
  const mainColorStyle = {
    color: rest.colorsSchema_id.mainTextColor
  }

  const orderMadeButtonColor = {
    background: rest.colorsSchema_id.loght,
    color: rest.colorsSchema_id.mainColor
  }

  const createLink = useLinkWithParams()
  return (
    <section className={style.section}>
      <Header
        title={translation('orders')}
        rest={rest}
        showBottomCart={true}
        setIsScrollButtonVisible={setIsScrollButtonVisible}
      />
      <div className={style['order-made-block__bottom-button']}>
        <Link
          to={createLink('')}
          className={style.btn}
          style={orderMadeButtonColor}
        >
          {translation('go-back-main-page')}
        </Link>
      </div>
      {isLoaded ? (
        <div className={style['orders-block']} style={mainColorStyle}>
          {orders.length > 0 ? (
            orders.map((order) => (
              <OrderCard
                rest={rest}
                order={order}
                key={order._id}
                openModal={openModal}
              />
            ))
          ) : (
            <div className={style.alert}>
              {translation('you-do-not-have-orders')}
            </div>
          )}
        </div>
      ) : (
        <CustomSpinner rest={rest} />
      )}
      {isVisibleOrder ? (
        <ModalCurrentOrder
          rest={rest}
          closeModal={closeModal}
          currentOrder={detailOrder}
        />
      ) : (
        ''
      )}
    </section>
  )
}
export default Orders
