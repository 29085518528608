import { FC } from 'react'
import style from './rests-list.module.css'
import { useTranslation } from 'react-i18next'
import { INet } from '../../components/app/App'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { RootState } from '../../services/store'
import { TRest } from '../../utils/typesFromBackend'
import leftArrow from '../../assets/images/left_arrow.png'
import { BASE_URL_CDN } from '../../utils/const'

interface IRestsList {
  rest: TRest
  net: INet
  setSelected: (restId: string) => void
}

const RestsList: FC<IRestsList> = ({ rest, net, setSelected }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const styleCustomRestList = {
    background: rest?.colorsSchema_id?.leftMenuBgColor,
    color: rest?.colorsSchema_id?.mainTextColor
  }
  const styleCustomColor = {
    backgroundColor: rest?.colorsSchema_id?.mainTextColor
  }

  const showArrowButton = useSelector(
    (state: RootState) => state.showArrowButton.show
  )

  const handleRestaurantClick = (e: React.MouseEvent<HTMLElement>): void => {
    const restId = e.currentTarget.getAttribute('data-id')
    if (restId != null) {
      setSelected(restId)
    }
    history.push('/')
  }

  return (
    <div
      className={style.rests}
      style={showArrowButton ? styleCustomRestList : {}}
    >
      <h2 style={{ textAlign: 'center' }}>
        {t('please-select-where-you-will-place-your-order')}
      </h2>

      {net.data.rests[0].logoPath.length > 0 ? (
        <div className={style['logo-block']}>
          <img
            src={`${BASE_URL_CDN}${'/'}${net.data.rests[0].logoPath}`}
            className={style['logo-rest']}
            alt='Логотип'
          />
        </div>
      ) : null}

      <ul className={style.restsList}>
        {net.data.rests.map((rest) => (
          <li
            className={style.restListItem}
            data-id={rest._id}
            key={rest._id}
            onClick={handleRestaurantClick}
          >
            <span className={style.restListItemTitle}>{rest.titleRest} </span>
          </li>
        ))}
      </ul>
      {/* кнопка со стрелкой влево */}
      {showArrowButton && (
        <button
          className={style.buttonLeft}
          style={styleCustomColor}
          onClick={() => history.goBack()}
        >
          <img src={leftArrow} alt='Кнопка Назад' />
        </button>
      )}
    </div>
  )
}

export default RestsList
