/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { FC, FormEvent } from 'react'
import { Link } from 'react-router-dom'
import CartAdressBlock from '../../components/cart-adress-block/cart-address-block'
import Header from '../../components/header/header'
import InputName from '../../components/ui/input-name/input-name'
import InputPhone from '../../components/ui/input-phone/input-phone'
import { useTextTranslation } from '../../contexts/TextTranslation'
import { useFormWithValidation } from '../../services/hooks/form-validation'
import { useSelector } from '../../services/hooks/redux-hooks'
import { TRest } from '../../utils/typesFromBackend'
import style from './person-update.module.css'
import { IBorder } from '../../components/cart-detali/cart-detali'

interface IPersonUpdate {
  rest: TRest
  setIsScrollButtonVisible: (arg: boolean) => void
}
const PersonUpdate: FC<IPersonUpdate> = ({
  rest,
  setIsScrollButtonVisible
}) => {
  const { values, handleChange, setValues } = useFormWithValidation()
  const [validPhone, setValidPhone] = React.useState(true)
  const [currentCityId, setCurrentCityId] = React.useState('')
  const [currentStreet, setCurrentStreet] = React.useState('')
  const person = useSelector((store) => store.user)
  const translation = useTextTranslation()
  React.useEffect(() => {
    if (person.isLoggedIn) {
      setValues({
        ...values,
        city: person.city?.title,
        street: person.street,
        house: person.house,
        flat: person.flat,
        porch: person.porch,
        floor: person.floor,
        name: person.name,
        userPhone: person.phone
      })
    }
  }, [])

  const border: IBorder = {
    border: `1px dashed ${rest.colorsSchema_id.borderInputColor}`
  }

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault()
    // отправляем данные для апдейта пользователя
  }

  const mainColorButtonStyle = {
    backgroundColor: rest.colorsSchema_id.buttonColor,
    color: rest.colorsSchema_id.buttonTextColor,
    margin: '10px'
  }

  return (
    <>
      <Header
        title={translation('personal-account')}
        rest={rest}
        hideButtonProfile={true}
        showBottomCart={true}
        setIsScrollButtonVisible={setIsScrollButtonVisible}
      />
      <div className={style['person-page']}>
        <form className='' onSubmit={handleSubmit}>
          <InputName
            border={border}
            value={values.name || ''}
            handleChange={handleChange}
            rest={rest}
          />
          <InputPhone
            rest={rest}
            value={values.userPhone || ''}
            handleChange={handleChange}
            setValidPhone={setValidPhone}
            border={border}
          />
          <CartAdressBlock
            rest={rest}
            isRemoteOrder={false}
            currentCityId={currentCityId}
            setCurrentCityId={setCurrentCityId}
            currentStreet={currentStreet}
            setCurrentStreet={setCurrentStreet}
            handleChange={handleChange}
            city={values.city || ''}
            street={values.street || ''}
            house={values.house || ''}
            flat={values.flat || ''}
            porch={values.porch || ''}
            floor={values.floor || ''}
            border={border}
          />
          <div className='' style={{ margin: '10px 0', display: 'flex' }}>
            <Link
              to={'/person'}
              className={style.btn}
              style={mainColorButtonStyle}
            >
              {translation('back')}
            </Link>
            <button
              type='submit'
              className={style.btn}
              disabled={!validPhone}
              style={mainColorButtonStyle}
            >
              {translation('save')}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}
export default PersonUpdate
