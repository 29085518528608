// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button-profile_path__5pD13 {\n  fill: none;\n  fill-opacity: 0;\n  stroke-width: 30;\n  stroke-linecap: butt;\n  stroke-linejoin: miter;\n  stroke-opacity: 1;\n  stroke-miterlimit: 4;\n}\n.button-profile_section__I7krO {\n  transition: 1s;\n  cursor: pointer;\n}\n.button-profile_section__I7krO:hover {\n  opacity: 0.7;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/button-profile/button-profile.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,sBAAsB;EACtB,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,YAAY;AACd","sourcesContent":[".path {\n  fill: none;\n  fill-opacity: 0;\n  stroke-width: 30;\n  stroke-linecap: butt;\n  stroke-linejoin: miter;\n  stroke-opacity: 1;\n  stroke-miterlimit: 4;\n}\n.section {\n  transition: 1s;\n  cursor: pointer;\n}\n.section:hover {\n  opacity: 0.7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"path": "button-profile_path__5pD13",
	"section": "button-profile_section__I7krO"
};
export default ___CSS_LOADER_EXPORT___;
