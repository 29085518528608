/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { FC } from 'react'
import style from './customSpinner.module.css'
import { TRest } from '../../../utils/typesFromBackend'
import { BASE_URL_CDN } from '../../../utils/const'
import { DefaultSpinner } from '../defaultSpinner/defaultSpinner'

interface ICustomSpinner {
  rest: TRest
}

export const CustomSpinner: FC<ICustomSpinner> = ({ rest }) => {
  return rest?.colorsSchema_id?.useLoaderImage !== null && rest?.colorsSchema_id?.useLoaderImage !== undefined
    ? (
    <div style={{ backgroundColor: rest.colorsSchema_id.backgroundLoader }} className={style.container}>
      {
        <img
          src={
            `${BASE_URL_CDN}` + '/' + `${rest.colorsSchema_id.useLoaderImage}`
          }
          alt=""
          className={style.picture}
        />
      }
    </div>
      )
    : (
    <DefaultSpinner />
      )
}
