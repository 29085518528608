import { BASE_URL } from '../const'
import { handleResponse } from '../helpers'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
export const getRest = async (rest_id?: string) => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (rest_id) {
    return await fetch(`${BASE_URL}/rest/?rest_id=${rest_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(async (res) => await handleResponse(res))
  } else {
    return await fetch(`${BASE_URL}/rest`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(async (res) => await handleResponse(res))
  }
}
// eslint-disable-next-line
export const getNetRest = async () => {
  // eslint-disable-next-line
  return fetch(`${BASE_URL}/rest/CheckForChain`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(async (res) => await handleResponse(res))
}
