import React, { FC } from 'react'

import style from './input-cart.module.css'
import { IBorder } from '../../cart-detali/cart-detali'

interface IInputStyle {
  color: string
  backgroundColor: string
}

interface IInputCart {
  name: string
  placeholder: string
  required: boolean
  value: string
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  border: IBorder
  inputStyle: IInputStyle
}
const InputCart: FC<IInputCart> = ({
  name,
  placeholder,
  value,
  required,
  handleChange,
  border,
  inputStyle
}) => {
  return (
    <input
      className={style['cart-input']}
      type="text"
      name={name}
      style={{ ...border, ...inputStyle }}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      required={required}
    />
  )
}
export default InputCart
