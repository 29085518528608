// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".terms-of-payment_body__IjEhM {\n    width: 90%;\n    margin: auto;\n    line-height: 1.5;\n    font-size: 1.1rem;\n    text-align: justify;\n    padding-bottom: 30px;\n}\n\n.terms-of-payment_image__hQtbP {\n    width: 35%;\n}\n\n@media(max-width: 768px) {\n    .terms-of-payment_image__hQtbP {\n        width: 100%;\n    }\n}", "",{"version":3,"sources":["webpack://./src/pages/terms-of-payment/terms-of-payment.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".body {\n    width: 90%;\n    margin: auto;\n    line-height: 1.5;\n    font-size: 1.1rem;\n    text-align: justify;\n    padding-bottom: 30px;\n}\n\n.image {\n    width: 35%;\n}\n\n@media(max-width: 768px) {\n    .image {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "terms-of-payment_body__IjEhM",
	"image": "terms-of-payment_image__hQtbP"
};
export default ___CSS_LOADER_EXPORT___;
