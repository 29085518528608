/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { FC } from 'react'
import { useTextTranslation } from '../../contexts/TextTranslation'
import Header from '../../components/header/header'
import { TRest } from '../../utils/typesFromBackend'
/* import { BASE_URL_CDN } from '../../utils/const' */
import style from './offer.module.css'

interface TOffer {
  rest: TRest
  setIsScrollButtonVisible: (arg: boolean) => void
}

const Offer: FC<TOffer> = ({ rest, setIsScrollButtonVisible }) => {
  const translation = useTextTranslation()

  return (
    <>
      <section className={style.section}>
        <Header
          title={translation('offer')}
          rest={rest}
          showBottomCart={true}
          setIsScrollButtonVisible={setIsScrollButtonVisible}
        />
        <div
          style={{ color: rest.colorsSchema_id.mainTextColor }}
          className={style.body}
        >
          <h2>
            ДОГОВОР-ОФЕРТА ОТ{' '}
            {rest.terms_ids?.juryName ? rest.terms_ids?.juryName : ''}
          </h2>
          <p>
            {rest.terms_ids?.juryName ? rest.terms_ids?.juryName : ''} публикует
            настоящий договор, являющийся публичным договором-офертой в адрес
            как физических, так и юридических лиц (далее ПОКУПАТЕЛЬ) о
            нижеследующем:
          </p>
          <p>Статья 1. Предмет договора-оферты.</p>
          <ul>
            <li>
              1.1. ПРОДАВЕЦ обязуется передать в собственность ПОКУПАТЕЛЮ, а
              {/* eslint-disable-next-line */}
              ПОКУПАТЕЛЬ обязуется оплатить и принять заказанные в "{window.location.hostname}" товары (далее Заказ).
            </li>
          </ul>
          <p>Статья 2. Момент заключения договора.</p>
          <ul>
            <li>
              2.1. Текст данного Договора является публичной офертой (в
              соответствии со статьей 435 и частью 2 статьи 437 Гражданского
              кодекса РФ).
            </li>
            <li>
              2.2. Факт оформления ЗАКАЗА у ПРОДАВЦА как самостоятельно, так и
              через оператора, является безоговорочным принятием данного
              Договора, и ПОКУПАТЕЛЬ рассматривается как лицо, вступившее с
              {/* eslint-disable-next-line */}
              "{rest.terms_ids?.juryName ? rest.terms_ids?.juryName : ''}" в
              договорные отношения.
            </li>
            <li>
              2.3. Оформление Блюд и расчета осуществляется путем заказа
              ПОКУПАТЕЛЕМ в «
              {rest.terms_ids?.juryName ? rest.terms_ids?.juryName : ''}».
            </li>
          </ul>
          <p>Статья 3. Цена ТОВАРА.</p>
          <ul>
            <li>
              3.1. Цены в «
              {rest.terms_ids?.juryName ? rest.terms_ids?.juryName : ''}»
              указаны в валюте страны покупателя.
            </li>
            <li>
              3.2. Общая сумма ЗАКАЗА, которая в некоторых случаях (по желанию
              покупателя) может включать платную доставку и сборку Заказа,
              указывается в разделе «Корзина» в строке «К оплате».
            </li>
          </ul>
          <p>Статья 4. Оплата ТОВАРА.</p>
          <ul>
            <li>
              4.1. При наличной форме оплаты ПОКУПАТЕЛЬ обязан уплатить ПРОДАВЦУ
              цену Заказа в момент его передачи, а ПРОДАВЕЦ обязан предоставить
              ПОКУПАТЕЛЮ кассовый или товарный чек, или иной документ,
              подтверждающий оплату Заказа.
            </li>
            <li>
              4.2. При безналичной форме оплаты обязанность ПОКУПАТЕЛЯ по уплате
              цены Блюда считается исполненной с момента зачисления
              соответствующих денежных средств в размере 100% (ста процентов)
              предоплаты на расчетный счет ПРОДАВЦА по реквизитам, указанным в
              п. 13 (Реквизиты{' '}
              {rest.terms_ids?.juryName ? rest.terms_ids?.juryName : ''})
              настоящего ДОГОВОРА.
            </li>
            <li>
              4.3. При безналичной форме оплаты просрочка уплаты ПОКУПАТЕЛЕМ
              цены Блюда на срок свыше 1 (одного) дня является существенным
              нарушением настоящего договора. В этом случае ПРОДАВЕЦ вправе в
              одностороннем порядке отказаться от исполнения настоящего
              договора, уведомив об этом ПОКУПАТЕЛЯ.
            </li>
            <li>
              4.4. Блюда поставляются ПОКУПАТЕЛЮ по ценам, наименованию, в
              количестве, соответствующем счету, оплаченному ПОКУПАТЕЛЕМ.
            </li>
          </ul>
          <p>Статья 5. Доставка ТОВАРА.</p>
          <ul>
            <li>
              5.1. Доставка Блюда ПОКУПАТЕЛЮ осуществляется адресу и в сроки,
              согласованные ПОКУПАТЕЛЕМ и менеджером ПРОДАВЦА при оформлении
              ЗАКАЗА, либо ПОКУПАТЕЛЬ самостоятельно забирает Заказ у ПРОДАВЦА
              по адресу, указанному в п. 13 (Реквизиты ресторана «
              {rest.terms_ids?.juryName ? rest.terms_ids?.juryName : ''}»)
              настоящего ДОГОВОРА.
            </li>
            <li>
              5.2. Точная стоимость доставки Заказа определяется менеджером
              ПРОДАВЦА при оформлении заказа и не может быть изменена после
              согласования ПОКУПАТЕЛЕМ.
            </li>
            <li>
              5.3. Неявка ПОКУПАТЕЛЯ или не совершение иных необходимых действий
              для принятия Заказа могут рассматриваться ПРОДАВЦОМ в качестве
              отказа ПОКУПАТЕЛЯ от исполнения ДОГОВОРА.
            </li>
          </ul>
          <p>Статья 6. Права и обязанности сторон.</p>
          <ul>
            <li>6.1. ПРОДАВЕЦ обязуется:</li>
            <li>
              6.1.1. Не разглашать любую частную информацию ПОКУПАТЕЛЯ и не
              предоставлять доступ к этой информации третьим лицам, за
              исключением случаев, предусмотренных Российским законодательством.
            </li>
            <li>
              6.1.2. Предоставить ПОКУПАТЕЛЮ возможность получения бесплатных
              телефонных консультаций по телефонам, указанным на сайте «
              {rest.terms_ids?.juryName ? rest.terms_ids?.juryName : ''}». Объем
              консультаций ограничивается конкретными вопросами, связанными с
              выполнениями ЗАКАЗА.
            </li>
            <li>
              6.1.3. ПРОДАВЕЦ оставляет за собой право изменять настоящий
              ДОГОВОР в одностороннем порядке до момента его заключения.
            </li>
            <li>6.2. ПОКУПАТЕЛЬ обязуется:</li>
            <li>
              6.2.1. До момента заключения ДОГОВОРА ознакомиться с содержанием
              договора-оферты, условиями оплаты и доставки на сайте «
              {rest.terms_ids?.juryName ? rest.terms_ids?.juryName : ''}»
            </li>
            <li>
              6.2.2. Предоставлять достоверную информацию о себе (ФИО,
              контактные телефоны, адрес электронной почты) и реквизиты для
              доставки ЗАКАЗА.
            </li>
            <li></li>
            6.2.3. Принять и оплатить ЗАКАЗ в указанные в настоящем ДОГОВОРЕ
            сроки.
          </ul>
          <p>Статья 7. Ответственность сторон и разрешение споров.</p>
          <ul>
            <li>
              7.1. Стороны несут ответственность за неисполнение или
              ненадлежащее исполнение настоящего ДОГОВОРА в порядке,
              предусмотренном настоящим ДОГОВОРОМ и действующим
              законодательством РФ.
            </li>
            <li>
              7.2. Продавец не несет ответственности за доставку ЗАКАЗА, если
              ПОКУПАТЕЛЕМ указан неправильный адрес доставки.
            </li>
            <li>
              7.3. ПРОДАВЕЦ не несет ответственности, если ожидания ПОКУПАТЕЛЯ о
              потребительских свойствах ЗКАЗА оказались не оправданны.
            </li>
            <li>
              7.4. ПРОДАВЕЦ не несет ответственности за частичное или полное
              неисполнение обязательств по доставке ЗАКАЗА, если они являются
              следствием форс-мажорных обстоятельств.
            </li>
            <li>
              7.5. ПОКУПАТЕЛЬ, оформляя ЗАКАЗ, несет ответственность за
              достоверность предоставляемой информации о себе, а так же
              подтверждает, что с условиями настоящего ДОГОВОРА ознакомлен и
              согласен.
            </li>
            <li>
              7.6. Все споры и разногласия, возникающие при исполнении СТОРОНАМИ
              обязательств по настоящему Договору, решаются путем переговоров. В
              случае невозможности их устранения, СТОРОНЫ имеют право обратиться
              за судебной защитой своих интересов.
            </li>
          </ul>
          <p>Статья 8. Возврат и обмен товара.</p>
          <ul>
            <li>
              8.1. Требование ПОКУПАТЕЛЯ об обмене либо о возврате ЗАКАЗА
              подлежит удовлетворению, если заказ не был в употреблении,
              сохранены его потребительские свойства, сохранена и не нарушена
              упаковка, сохранены документы, подтверждающие факт покупки этого
              Заказа в «
              {rest.terms_ids?.juryName ? rest.terms_ids?.juryName : ''}»
            </li>
          </ul>
          <p>Статья 9. Форс-мажорные обстоятельства.</p>
          <ul>
            <li>
              9.1. Стороны освобождаются от ответственности за неисполнение или
              ненадлежащее исполнение обязательств по Договору на время действия
              непреодолимой силы. Под непреодолимой силой понимаются
              чрезвычайные и непреодолимые при данных условиях обстоятельства,
              препятствующие исполнению своих обязательств СТОРОНАМИ по
              настоящему Договору. К ним относятся стихийные явления
              (землетрясения, наводнения и т. п.), обстоятельства общественной
              жизни (военные действия, чрезвычайные положения, крупнейшие
              забастовки, эпидемии и т. п.), запретительные меры государственных
              органов (запрещение перевозок, валютные ограничения, международные
              санкции запрета на торговлю и т. п.). В течение этого времени
              СТОРОНЫ не имеют взаимных претензий, и каждая из СТОРОН принимает
              на себя свой риск последствия форс-мажорных обстоятельств.
            </li>
          </ul>
          <p>Статья 10. Срок действия договора.</p>
          <ul>
            <li>
              10.1. Настоящий ДОГОВОР вступает в силу с момента обращения к
              {/* eslint-disable-next-line */}
              "{window.location.hostname}". и
              оформления ЗАКАЗА, и заканчивается при полном исполнении
              обязательств СТОРОНАМИ.
            </li>
          </ul>
          <p>
            Статья 11. Реквизиты «
            {rest.terms_ids?.juryName ? rest.terms_ids?.juryName : ''}»
          </p>
          <p>ИНН: {rest.terms_ids?.INN ? rest.terms_ids?.INN : ''}</p>
          <p>Адрес: {rest.workAddress}</p>
          <p>Телефон заведения: {rest.workPhone}</p>
          <p>Email: {rest.email}</p>
          <p>ОГРН: {rest.terms_ids?.OGRN ? rest.terms_ids?.OGRN : ''}</p>
          <p>
            БАНК:{' '}
            {rest.terms_ids?.bankDetails ? rest.terms_ids?.bankDetails : ''}
          </p>
          <p>
            Корреспондентский счет:{' '}
            {rest.terms_ids?.correspondentAccount
              ? rest.terms_ids?.correspondentAccount
              : ''}
          </p>
          <p>
            Расчетный счет:{' '}
            {rest.terms_ids?.accountCalculation
              ? rest.terms_ids?.accountCalculation
              : ''}
          </p>
        </div>
      </section>
    </>
  )
}

export default Offer
