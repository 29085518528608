// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const Microfon = () => {
  return (
    <svg
      version="1.0"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      enableBackground="new 0 0 64 64"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier"></g>
      <g id="SVGRepo_tracerCarrier"></g>
      <g id="SVGRepo_iconCarrier">
        <g>
          {' '}
          <g>
            {' '}
            <path
              fill="#ffffff"
              d="M32,44c6.629,0,12-5.371,12-12V12c0-6.629-5.371-12-12-12S20,5.371,20,12v20C20,38.629,25.371,44,32,44z M28,12c0-2.211,1.789-4,4-4s4,1.789,4,4v20c0,2.211-1.789,4-4,4s-4-1.789-4-4V12z"
            ></path>
            <path
              fill="#ffffff"
              d="M52,28c-2.211,0-4,1.789-4,4c0,8.836-7.164,16-16,16s-16-7.164-16-16c0-2.211-1.789-4-4-4s-4,1.789-4,4 c0,11.887,8.656,21.73,20,23.641V60c0,2.211,1.789,4,4,4s4-1.789,4-4v-4.359C47.344,53.73,56,43.887,56,32 C56,29.789,54.211,28,52,28z"
            ></path>{' '}
          </g>
          <path
            fill="#ffffff"
            d="M28,12c0-2.211,1.789-4,4-4s4,1.789,4,4v20c0,2.211-1.789,4-4,4s-4-1.789-4-4V12z"
          ></path>
        </g>{' '}
      </g>
    </svg>
  )
}

export default Microfon
