import React, { FC } from 'react'
import { TRest } from '../../utils/typesFromBackend'
import Header from '../../components/header/header'
import { useTextTranslation } from '../../contexts/TextTranslation'
import style from './payment-success.module.css'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import useLinkWithParams from '../../services/hooks/useLinkWithParams'

interface IPaymentSuccess {
  rest: TRest
  setIsScrollButtonVisible: (arg: boolean) => void
}
const PaymentSuccess: FC<IPaymentSuccess> = ({
  rest,
  setIsScrollButtonVisible
}) => {
  const translation = useTextTranslation()
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch({ type: 'CLEAR_CART' })
  }, [])

  const createLink = useLinkWithParams()
  return (
    <section className={style.section}>
      <Header
        title={translation('payment-success')}
        rest={rest}
        showBottomCart={true}
        setIsScrollButtonVisible={setIsScrollButtonVisible}
      />
      <div className={style.body}>
        <h1 style={{ color: `${rest.colorsSchema_id.mainTextColor}`, textAlign: 'center' }}>
          {translation('payment-success')}
        </h1>
        <Link
          style={{ color: `${rest.colorsSchema_id.mainTextColor}` }}
          to={createLink('')}
        >
          {translation('go-back-main-page')}
        </Link>
      </div>
    </section>
  )
}

export default PaymentSuccess
