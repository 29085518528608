export const testIdRest = '632c1700641f6cf6642b2ba9'

let BASE_URL: string
let BASE_URL_CDN: string

if (process.env.REACT_APP_API_ENV === 'production') {
  BASE_URL = 'https://api.easyqr.ru/api/v1'
  BASE_URL_CDN = 'https://api.easyqr.ru/cdn'
} else {
  BASE_URL = 'https://kapayji.ru/api/v1'
  BASE_URL_CDN = 'https://kapayji.ru/cdn'
}
export { BASE_URL, BASE_URL_CDN }
