import { ChangeEvent, FC } from 'react'
import { useCurrentLanguage } from '../../contexts/CurrentLanguageContext'
import { useSetLanguage } from '../../contexts/SetLanguageContext'
import { ECountry, TRest } from '../../utils/typesFromBackend'
import style from './language.module.css'

interface ILanguageSelect {
  rest: TRest
}

const LanguageSelect: FC<ILanguageSelect> = ({ rest }) => {
  const currentLanguage = useCurrentLanguage()
  const setLanguage = useSetLanguage()
  const handleLanguageChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    setLanguage(event.target.value as ECountry)
  }
  const mainColorButtonStyle = {
    border: `1px solid ${rest.colorsSchema_id.buttonColor}`,
    color: rest.colorsSchema_id.textLanguage,
    marginLeft: '20px'
  }
  return (
    <div className={style.section}>
      <select style={mainColorButtonStyle} id="languageSelect" value={currentLanguage} onChange={handleLanguageChange}>
        {rest.languages.map(item =>
          <option key={item} value={item}>{item}</option>
        )}
      </select>
    </div>
  )
}
export default LanguageSelect
