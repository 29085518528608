import {
  TOGGLE_ARROW_BUTTON,
  ToggleArrowButtonAction
} from '../actions/arrow-button'

export interface IArrowButtonState {
  show: boolean
}

const initialState: IArrowButtonState = {
  show: false
}

export const arrowButtonReducer = (
  state = initialState,
  action: ToggleArrowButtonAction
): IArrowButtonState => {
  switch (action.type) {
    case TOGGLE_ARROW_BUTTON: {
      return {
        ...state,
        show: action.payload
      }
    }
    default:
      return state
  }
}
