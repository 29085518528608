import React, { FC } from 'react'
import CartProductMemo from '../cart-product/cart-product'
import ButtonPopupClose from '../ui/button-popup-close/button-popup-close'
import ButtonPopupLine from '../ui/button-popup-line/button-popup-line'

import style from './dish-modal-del.module.css'
import { TRest } from '../../utils/typesFromBackend'
import { IDishInCart } from '../../services/reducers/cart'
import { toggleBodyScrollLock } from '../../helpers/toggleBodyScrollLock'
import { useOnClickOutside } from '../../services/hooks/on-click-outside'
import { useSelector } from '../../services/hooks/redux-hooks'
import { useTextTranslation } from '../../contexts/TextTranslation'

interface IdishModalDel {
  rest: TRest
  dishesId: string
  setIsOpenDishModalDel: (value: boolean) => void
}

const DishModalDel: FC<IdishModalDel> = ({ rest, dishesId, setIsOpenDishModalDel }) => {
  const translation = useTextTranslation()
  const arrayDishesInCart = useSelector((store) => store.cart.dishes)
  const [dishes, setDishes] = React.useState<IDishInCart[]>()
  const node = React.useRef<HTMLDivElement>(null)
  const windowWidth = window.innerWidth
  const styleBackgroundColor = {
    background: rest.colorsSchema_id.backgroundColor,
    color: rest.colorsSchema_id.mainTextColor
  }
  const styleBackgroundColorButton = {
    background: rest.colorsSchema_id.buttonColor
  }
  const fillButtonClose = rest.colorsSchema_id.buttonTextColor
  const fill = rest.colorsSchema_id.mainColor
  const stroke = rest.colorsSchema_id.mainColor
  function handleClick (): void {
    setIsOpenDishModalDel(false)
  }

  React.useEffect(() => {
    setDishes(arrayDishesInCart.filter(item => item.dish._id === dishesId))
  }, [arrayDishesInCart])

  React.useEffect(() => {
    dishes?.length === 0 && setIsOpenDishModalDel(false)
  }, [dishes])

  React.useEffect(() => {
    toggleBodyScrollLock(true) // Блокировка прокрутки при открытии попапа
    return () => {
      toggleBodyScrollLock(false) // Разблокировка прокрутки при закрытии попапа
    }
  }, [])

  useOnClickOutside(node, handleClick)

  return (
    <div className={style.popup}>
      <div className={style['product-popup__body']} ref={node} style={styleBackgroundColor}>
        {windowWidth > 767
          ? (
            <div
              className={style.popup__close}
              style={styleBackgroundColorButton}
              onClick={handleClick}
            >
              <ButtonPopupClose fill={fillButtonClose} />
            </div>
            )
          : (
            <div className={style['popup__img-line']} onClick={handleClick}>
              <ButtonPopupLine fill={fill} stroke={stroke} />
            </div>
            )}
        <div className={style['cart-products']}>
          {dishes !== null
            ? (
                dishes?.map((dish) => (
                <CartProductMemo
                  dish={dish.dish}
                  rest={rest}
                  prodCount={dish.quantity}
                  id={dish.id}
                  key={dish.id}
                />
                ))
              )
            : (
              <div className={`${style.alert} ${style['alert-danger']}`}>
                {translation('empty')}
              </div>
              )}
        </div>
      </div>
    </div>
  )
}

export default DishModalDel
