import { FC, useEffect, useState } from 'react'
import { useTextTranslation } from '../../contexts/TextTranslation'
import { TRest } from '../../utils/typesFromBackend'
import style from './pd.module.css'
import Header from '../../components/header/header'

interface IPD {
  rest: TRest
  setIsScrollButtonVisible: (arg: boolean) => void
}

const PD: FC<IPD> = ({ rest, setIsScrollButtonVisible }) => {
  const [juryName, setJuryname] = useState<string>('')
  const [inn, setInn] = useState<string>('')
  const [juryAddress, setJuryAddress] = useState<string>('')
  const [index, setIndex] = useState<string>('')
  const [ogrn, setOgrn] = useState<string>('')
  const translation = useTextTranslation()
  const mainTextColor = {
    color: rest.colorsSchema_id.mainTextColor
  }

  useEffect(() => {
    if (rest.terms_ids != null) {
      const juryName = rest.terms_ids?.juryName
      const inn = rest.terms_ids?.INN
      const juryAddress = rest.terms_ids?.juryAddress
      const index = rest.terms_ids?.addr_index
      const ogrn = rest.terms_ids?.OGRN
      setJuryname(juryName)
      setInn(inn)
      setJuryAddress(juryAddress)
      setIndex(index)
      setOgrn(ogrn)
    }
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Header
        title={''}
        rest={rest}
        showBottomCart={true}
        setIsScrollButtonVisible={setIsScrollButtonVisible}
      />
      <section className={style.container} style={mainTextColor}>
        <h2 className={style.h2}>
          {translation('agree-proccesing-personal-data')}
        </h2>
        <hr className={style.hr}></hr>
        <div className={style.pd}>
          <p>
            {translation('i-give-my-agree')} {`${juryName}`},
            {translation('inn')}: {`${inn}`}, {translation('legal-address')}:{' '}
            {` ${index}`}, {`${juryAddress}`},
            {translation('for-processing-my-personal-data')}
          </p>
          <p>
            {translation('name')}: {`${juryName}`}
            <br></br>
            {translation('inn')}: {` ${inn}`}
            <br></br>
            {translation('ogrn')}: {` ${ogrn}`}
          </p>
          <p>{translation('for-the-purpose-providing-user')}</p>
          <p>{translation('personal-data-means')}</p>
          <p>{translation('term-termination')}</p>
          <p>
            {translation('i-reserve-right')} {` ${juryName} `}
            {translation('by-register-mail')} {` ${juryName}`}.
          </p>
        </div>
      </section>
    </>
  )
}

export default PD
