/* eslint-disable multiline-ternary */
import React, { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import ModalCurrentOrder from '../../components/modal-current-order/modal-current-order'
import { useTextTranslation } from '../../contexts/TextTranslation'
import { useSelector } from '../../services/hooks/redux-hooks'
import { TRest } from '../../utils/typesFromBackend'
import style from './order-made.module.css'
import useLinkWithParams from '../../services/hooks/useLinkWithParams'

interface IOrderMade {
  rest: TRest
}
const OrderMade: FC<IOrderMade> = ({ rest }) => {
  const [secondText, setSecontText] = React.useState('')
  const [isVisibleOrder, setIsVisibleOrder] = React.useState(false)
  const search = useLocation().search
  const numberTable = new URLSearchParams(search).get('numberTable')
  // достали текущий заказ из стора и потом прокинули в модалку <ModalCurrentOrder />
  const currentOrder = useSelector((store) => store.cart.currentOrder)
  const translation = useTextTranslation()

  const createLink = useLinkWithParams()

  React.useEffect(() => {
    if (numberTable != null) {
      setSecontText(translation('wait-call-message-from-administrator'))
    } else {
      setSecontText(translation('your-order-begin-do'))
    }
  }, [])

  const styleButtonTextCollor = {
    color: rest.colorsSchema_id.buttonTextColor
  }

  const pathStyleFill = rest.colorsSchema_id.mainColor
  const circleStyle = rest.colorsSchema_id.buttonTextColor
  const orderMadeButtonColor = {
    background: rest.colorsSchema_id.loght,
    color: rest.colorsSchema_id.mainColor
  }

  function handleClick(): void {
    setIsVisibleOrder(true)
  }

  function closeModal(): void {
    setIsVisibleOrder(false)
  }

  return (
    <div
      style={{
        backgroundColor: `${rest.colorsSchema_id.orderMadeBlock}`,
        height: '100vh'
      }}
      className={style['order-made-block']}
    >
      <div
        className={style['order-made-block__top']}
        style={styleButtonTextCollor}
      >
        <div className={style['order-made-block__icon']}>
          <svg
            width='92'
            height='92'
            viewBox='0 0 92 92'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle opacity='0.64' cx='46' cy='46' r='46' fill={circleStyle} />
            <circle cx='46' cy='46' r='40' fill={circleStyle} />
            <path
              d='M40.0534 60.8754C38.9148 62.0139 37.0804 62.0506 35.8972 60.9584L25.5639 51.42C24.5893 50.5203 24.5587 48.9905 25.4966 48.0526C26.3749 47.1742 27.7871 47.1371 28.7103 47.968L35.8996 54.4384C37.0856 55.5058 38.8996 55.4581 40.0278 54.3298L63.7521 30.6057C64.6594 29.6984 66.1304 29.6984 67.0377 30.6057C67.945 31.5129 67.945 32.9839 67.0377 33.8912L40.0534 60.8754Z'
              fill={pathStyleFill}
            />
          </svg>
        </div>
        <div className={style['order-made-block__main-text']}>
          {translation('order-accepted')}
        </div>
        <div className={style['order-made-block__second-text']}>
          {secondText}
        </div>
      </div>
      <div className={style['order-made-block__bottom-button']}>
        <button
          className={style.btn}
          onClick={handleClick}
          style={orderMadeButtonColor}
        >
          {translation('order-details')}
        </button>
      </div>
      <div className={style['order-made-block__bottom-button']}>
        <Link
          to={createLink('')}
          className={style.btn}
          style={orderMadeButtonColor}
        >
          {translation('go-back-main-page')}
        </Link>
      </div>
      {isVisibleOrder && currentOrder != null && (
        <ModalCurrentOrder
          rest={rest}
          closeModal={closeModal}
          currentOrder={currentOrder}
        />
      )}
    </div>
  )
}
export default OrderMade
