import { BASE_URL } from '../const'
import { handleResponse } from '../helpers'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
export const authorizationUser = async (smsCode: string, phone: string) => {
  return await fetch(`${BASE_URL}/user/get`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      phone,
      smsCode
    })
  }).then(async (res) => await handleResponse(res))
}
