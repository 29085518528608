// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".language_section__NCHx9{\n  height: 38px;\n}\n.language_section__NCHx9 select {\n  background-color: transparent;\n  max-width: 192px;\n  min-width: 150px;\n  padding: 5px 35px 5px 5px;\n  border-radius: 5px;\n  font-size: 16px;\n}\n\n\n", "",{"version":3,"sources":["webpack://./src/components/language-select/language.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,6BAA6B;EAC7B,gBAAgB;EAChB,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".section{\n  height: 38px;\n}\n.section select {\n  background-color: transparent;\n  max-width: 192px;\n  min-width: 150px;\n  padding: 5px 35px 5px 5px;\n  border-radius: 5px;\n  font-size: 16px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "language_section__NCHx9"
};
export default ___CSS_LOADER_EXPORT___;
