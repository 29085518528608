import React, { FC } from 'react'
import { useTextTranslation } from '../../contexts/TextTranslation'

import CartTimeDate from '../cart-time-date/cart-time-date'
import style from './cart-time-block.module.css'
import { IBorder } from '../cart-detali/cart-detali'
import { TRest } from '../../utils/typesFromBackend'

interface ICartTimeBlock {
  startDate: Date | null
  setStartDate: (date: Date | null) => void
  enableDateDelivery: boolean
  setCheck: (arg0: boolean) => void
  check: boolean
  valueTime: string
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  border: IBorder
  rest: TRest
}
const CartTimeBlock: FC<ICartTimeBlock> = ({
  startDate,
  setStartDate,
  enableDateDelivery, // enableDateDelivery - это булево значение, которое отвечает за то, показывать ли опцию "Ближайшее время" или нет
  setCheck,
  check, // check - это булево значение, которое отвечает за то, выбрана ли опция "Ближайшее время" или нет
  valueTime, // valueTime - это строка, которая отвечает за время заказа
  handleChange,
  border,
  rest
}) => {
  function handleClick(): void {
    setCheck(!check)
  }

  const textColor = {
    color: rest.colorsSchema_id.mainTextColor,
    backgroundColor: rest.colorsSchema_id.backgroundColor
  }

  const inputStyle = {
    color: rest.colorsSchema_id.popupInputColor,
    backgroundColor: rest.colorsSchema_id.backgroundColor
  }
  const translation = useTextTranslation()
  // CartTimeDate нужно показывать в зависимости от условий с бэка
  return (
    <div className={style['input-wrapper']}>
      <label style={textColor} className={style['cart-detail__subtitle']}>
        {translation('what-time')}
      </label>
      {check ? (
        ''
      ) : (
        <>
          {enableDateDelivery ? (
            <div>
              <CartTimeDate
                rest={rest}
                startDate={startDate}
                setStartDate={setStartDate}
                border={border}
              />
              <input
                type='time'
                name='time'
                className={style['cart-input']}
                placeholder={translation('time-order')}
                value={valueTime}
                onChange={handleChange}
                required
                style={{ ...border, ...inputStyle }}
              />
            </div>
          ) : null}
        </>
      )}
      <div className={style['cart-input']}>
        <div className={style['container-childModifier__title']}>
          <input
            onClick={handleClick}
            type='checkbox'
            name='time-now'
            className={style['checkbox-theme']}
            style={{ width: 'auto', marginLeft: '5px' }}
            checked={check}
            onChange={() => {}}
          />
          <label className='' htmlFor='time-now'>
            {translation('nearest-time')}
          </label>
        </div>
      </div>
    </div>
  )
}
export default CartTimeBlock
