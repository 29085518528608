import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from '../../../services/hooks/redux-hooks'
import { TRest } from '../../../utils/typesFromBackend'
import style from './button-profile.module.css'
import useLinkWithParams from '../../../services/hooks/useLinkWithParams'

interface IButtonProfile {
  fill: string
  stroke: string
  rest: TRest
}
const ButtonProfile: FC<IButtonProfile> = ({ fill, stroke, rest }) => {
  const person = useSelector((store) => store.user)

  const createLink = useLinkWithParams()
  // функция для создания ссылки на страницу пользователя в зависимости от того, авторизован ли он
  const getPersonLink = (): string => {
    if (person.isLoggedIn) {
      return createLink('/person')
    } else {
      return createLink('/person-sign-in')
    }
  }

  return (
    <>
      <Link to={getPersonLink()} className={style.section}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          width='36px'
          height='36px'
          viewBox='0 0 24 24'
          version='1.1'
        >
          <g id='surface1'>
            <path
              className={style.path}
              d='M 565.039062 300 C 565.039062 446.386719 446.386719 565.039062 300 565.039062 C 153.613281 565.039062 34.960938 446.386719 34.960938 300 C 34.960938 153.613281 153.613281 34.960938 300 34.960938 C 446.386719 34.960938 565.039062 153.613281 565.039062 300 Z M 565.039062 300 '
              transform='matrix(0.04,0,0,0.04,0,0)'
              fill={fill}
              stroke={stroke}
            />
            <path
              className={style.path}
              d='M 415.039062 229.980469 C 415.039062 293.554688 363.476562 345.019531 300 345.019531 C 236.523438 345.019531 184.960938 293.554688 184.960938 229.980469 C 184.960938 166.503906 236.523438 115.039062 300 115.039062 C 363.476562 115.039062 415.039062 166.503906 415.039062 229.980469 Z M 415.039062 229.980469 '
              transform='matrix(0.04,0,0,0.04,0,0)'
              fill={fill}
              stroke={stroke}
            />
            <path
              className={style.path}
              d='M 106.835938 481.445312 C 135.839844 399.609375 213.183594 345.019531 300 345.019531 C 386.816406 345.019531 464.160156 399.609375 493.164062 481.445312 '
              transform='matrix(0.04,0,0,0.04,0,0)'
              fill={fill}
              stroke={stroke}
            />
          </g>
        </svg>
      </Link>
    </>
  )
}
export default ButtonProfile
