import { BASE_URL } from '../const'
import { handleResponse } from '../helpers'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
export const sendRecommendationText = async (
  // eslint-disable-next-line @typescript-eslint/naming-convention
  rest_id: string,
  words: string[]
) => {
  return await fetch(`${BASE_URL}/recomendation/text`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      rest_id,
      words
    })
  }).then(async (res) => await handleResponse(res))
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const sendRecommendationVoice = async (formData: any) => {
  return await fetch(`${BASE_URL}/recomendation/voice`, {
    method: 'post',
    // headers: {
    //   // "Access-Control-Allow-Origin": "*",
    //   "Content-Type": "multipart/form-data",
    //   // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    //   //"Content-Type": "application/x-www-form-urlencoded",
    // },
    body: formData
  }).then(async (res) => await handleResponse(res))
}
