/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from '../../services/hooks/redux-hooks'
import { TOrder, TRest } from '../../utils/typesFromBackend'
import OrderCard from '../../components/order-card/order-card'
import style from './person.module.css'
import ModalCurrentOrder from '../../components/modal-current-order/modal-current-order'
import { LOGOUT_SUCCESS } from '../../services/actions'
import Header from '../../components/header/header'
import * as orderAPI from '../../utils/api/order-api'
import { setNotification } from '../../services/actions/notification'
import { useTextTranslation } from '../../contexts/TextTranslation'
import { CustomSpinner } from '../../components/spinners/customSpinner/customSpinner'
import useLinkWithParams from '../../services/hooks/useLinkWithParams'

interface IPerson {
  rest: TRest
  setIsScrollButtonVisible: (arg: boolean) => void
}
const Person: FC<IPerson> = ({ rest, setIsScrollButtonVisible }) => {
  const person = useSelector((store) => store.user)
  const [isVisibleOrder, setIsVisibleOrder] = React.useState(false)
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const [detailOrder, setDetailOrder] = React.useState<TOrder>({} as TOrder)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [orders, setOrders] = React.useState<TOrder[]>([])
  // const orders: TOrder[] = [] // тут нужно запросить заказы пользователя
  const dispatch = useDispatch()
  const translation = useTextTranslation()

  const createLink = useLinkWithParams()

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const showAlert = (
    message: string,
    type: 'success' | 'danger' | 'warning'
  ) => {
    dispatch(setNotification({ message, type }))
  }

  React.useEffect(() => {
    if (isVisibleOrder) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isVisibleOrder])

  React.useEffect(() => {
    setIsLoading(true)
    orderAPI
      .getAllOrdersUserInRest({ rest_id: rest._id, user_id: person._id })
      .then((res) => {
        setOrders(res)
      })
      .catch(() => {
        showAlert(translation('do-not-take-your-orders'), 'danger')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])
  function openModal(id: string): void {
    const resultOrder = orders.find((order) => order._id === id)
    if (resultOrder != null) {
      setDetailOrder(resultOrder)
      setIsVisibleOrder(true)
    }
  }

  function closeModal(): void {
    setIsVisibleOrder(false)
  }
  function handleClick(): void {
    dispatch({ type: LOGOUT_SUCCESS })
    // надо разлогиниться - удалить пользователя из стора
  }
  const mainColorButtonStyle = {
    backgroundColor: rest.colorsSchema_id.buttonColor,
    color: rest.colorsSchema_id.buttonTextColor
  }
  const mainColorStyle = {
    color: rest.colorsSchema_id.mainTextColor
  }

  const orderMadeButtonColor = {
    background: rest.colorsSchema_id.loght,
    color: rest.colorsSchema_id.mainColor
  }

  return (
    <>
      {isLoading ? <CustomSpinner rest={rest} /> : ''}
      <Header
        title={translation('personal-account')}
        rest={rest}
        hideButtonProfile={true}
        showBottomCart={true}
        setIsScrollButtonVisible={setIsScrollButtonVisible}
      />
      <div className={style['person-page']} style={mainColorStyle}>
        {person.name ? (
          <div className={style['flex-row']}>
            <div className={style['person-page__title']}>
              {translation('name')}
            </div>
            <div className={style['person-page__value']}>{person.name}</div>
          </div>
        ) : (
          ''
        )}

        <div className={style['flex-row']}>
          <div className={style['person-page__title']}>
            {translation('phone-number')}
          </div>
          <div className={style['person-page__value']}>{person.phone}</div>
        </div>

        {person.street ? (
          <div className={style['flex-row']}>
            <div className={style['person-page__title']}>
              {translation('street')}
            </div>
            <div className={style['person-page__value']}>{person.street}</div>
          </div>
        ) : (
          ''
        )}
        {person.house ? (
          <div className={style['flex-row']}>
            <div className={style['person-page__title']}>
              {translation('house')}
            </div>
            <div className={style['person-page__value']}>{person.house}</div>
          </div>
        ) : (
          ''
        )}
        {person.flat ? (
          <div className={style['flex-row']}>
            <div className={style['person-page__title']}>
              {translation('flat')}
            </div>
            <div className={style['person-page__value']}>{person.flat}</div>
          </div>
        ) : (
          ''
        )}

        <div className={style.links}>
          <Link
            to={createLink('/person-update')}
            className={style.btn}
            style={mainColorButtonStyle}
          >
            {translation('change')}
          </Link>
          <Link
            to={createLink('person-sign-in')}
            style={mainColorButtonStyle}
            className={style.btn}
            onClick={handleClick}
          >
            {translation('exit')}
          </Link>
        </div>
        <div className={style['order-made-block__bottom-button']}>
          <Link
            to={createLink('')}
            className={style.btn}
            style={orderMadeButtonColor}
          >
            {translation('go-back-main-page')}
          </Link>
        </div>
        <h2 className={style.subtitle}>{translation('list-orders')}</h2>
        <div className={style['orders-block']}>
          {orders.length > 0 ? (
            orders.map((order) => (
              <OrderCard
                rest={rest}
                order={order}
                key={order._id}
                openModal={openModal}
              />
            ))
          ) : (
            <div className={style.alert}>
              {translation('you-do-not-have-orders')}
            </div>
          )}
        </div>
      </div>
      {isVisibleOrder ? (
        <ModalCurrentOrder
          rest={rest}
          closeModal={closeModal}
          currentOrder={detailOrder}
        />
      ) : (
        ''
      )}
    </>
  )
}
export default Person
