import React, { FC } from 'react'
import { useTextTranslation } from '../../contexts/TextTranslation'
import Header from '../../components/header/header'
import { TRest } from '../../utils/typesFromBackend'
/* import { BASE_URL_CDN } from '../../utils/const' */
import style from './terms-of-payment.module.css'
import BankMap from '../../assets/images/BankMap.png'

interface TTermsOfPayment {
  rest: TRest
  setIsScrollButtonVisible: (arg: boolean) => void
}

const TermsOfPayment: FC<TTermsOfPayment> = ({
  rest,
  setIsScrollButtonVisible
}) => {
  const translation = useTextTranslation()

  return (
    <>
      <section className={style.section}>
        <Header
          title={translation('terms-of-payment')}
          rest={rest}
          showBottomCart={true}
          setIsScrollButtonVisible={setIsScrollButtonVisible}
        />
        <div
          style={{ color: rest.colorsSchema_id.mainTextColor }}
          className={style.body}
        >
          <h2>Правила оплаты</h2>
          <p>
            К оплате принимаются платежные карты: VISA Inc, MasterCard
            WorldWide, МИР <br />
            Способ оплаты: банковская карта, наличный расчёт, оплата по QR-коду{' '}
            <br />
            При оплате заказа банковской картой, обработка платежа происходит на
            авторизационной странице банка, где Вам необходимо ввести данные
            Вашей банковской карты:
            <br />
            <ol>
              <li>тип карты</li>
              <li>номер карты,</li>
              <li>срок действия карты (указан на лицевой стороне карты)</li>
              <li>
                Имя держателя карты (латинскими буквами, точно также как указано
                на карте)
              </li>
              <li>CVC2/CVV2 код</li>
            </ol>
          </p>
          <img className={style.image} src={BankMap} />
          <p>
            Если Ваша карта подключена к услуге 3D-Secure, Вы будете
            автоматически переадресованы на страницу банка, выпустившего карту,
            для прохождения процедуры аутентификации. Информацию о правилах и
            методах дополнительной идентификации уточняйте в Банке, выдавшем Вам
            банковскую карту.
            <br />
            Безопасность обработки интернет-платежей через платежный шлюз банка
            гарантирована международным сертификатом безопасности PCI DSS.
            Передача информации происходит с применением технологии шифрования
            TLS. Эта информация недоступна посторонним лицам.
            <br />
            <strong>
              Советы и рекомендации по необходимым мерам безопасности проведения
              платежей с использованием банковской карты:
            </strong>
            <ol>
              <li>
                <strong>берегите свои пластиковые карты</strong> так же, как
                бережете наличные деньги. Не забывайте их в машине, ресторане,
                магазине и т.д.
              </li>
              <li>
                никогда{' '}
                <strong>
                  не передавайте полный номер своей кредитной карты
                </strong>{' '}
                по телефону каким-либо лицам или компаниям
              </li>
              <li>
                всегда имейте под рукой номер телефона для экстренной связи с
                банком, выпустившим вашу карту, и в случае ее утраты немедленно
                свяжитесь с банком
              </li>
              <li>
                вводите реквизиты карты только при совершении покупки. Никогда
                не указывайте их по каким-то другим причинам.
              </li>
            </ol>
          </p>
        </div>
      </section>
    </>
  )
}

export default TermsOfPayment
