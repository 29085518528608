import React, { FC } from 'react'

// eslint-disable-next-line @typescript-eslint/no-extra-parens
export const TextTranslationContext = React.createContext<(text: string) => string>(() => '')

interface TextTranslationProviderProps {
  value: (text: string) => string
  children: React.ReactNode
}

export const TextTranslationProvider: FC<TextTranslationProviderProps> = ({ value, children }) => {
  return (
    <TextTranslationContext.Provider value={value}>
      {children}
    </TextTranslationContext.Provider>
  )
}

export const useTextTranslation = (): (text: string) => string => React.useContext(TextTranslationContext)
