// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".change-rest-button_logo-change-rest__OtXPz{\n  padding-left: 10px;\n  font-size: 18px;\n  cursor: pointer;\n  transition: 1s;\n}\n\n.change-rest-button_logo-change-rest__OtXPz:hover {\n  opacity: 0.7;\n}\n\n.change-rest-button_name-rest__0-zU5 {\n  padding-left: 20px;\n  font-weight: bold;\n  font-size: 30px;\n  font-size: 1.5rem;\n  text-decoration: none;\n}\n\n.change-rest-button_name-rest__0-zU5.change-rest-button_center-text__rezeg {\n  padding-left: 0px;\n  width: 100%;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/components/change-rest-button/change-rest-button.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[".logo-change-rest{\n  padding-left: 10px;\n  font-size: 18px;\n  cursor: pointer;\n  transition: 1s;\n}\n\n.logo-change-rest:hover {\n  opacity: 0.7;\n}\n\n.name-rest {\n  padding-left: 20px;\n  font-weight: bold;\n  font-size: 30px;\n  font-size: 1.5rem;\n  text-decoration: none;\n}\n\n.name-rest.center-text {\n  padding-left: 0px;\n  width: 100%;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo-change-rest": "change-rest-button_logo-change-rest__OtXPz",
	"name-rest": "change-rest-button_name-rest__0-zU5",
	"center-text": "change-rest-button_center-text__rezeg"
};
export default ___CSS_LOADER_EXPORT___;
