import React, { FC } from 'react'
import { TRest } from '../../utils/typesFromBackend'

import style from './socialNetworks.module.css'
import { BASE_URL_CDN } from '../../utils/const'

export interface IsocialNetworksProps {
  rest: TRest
}
// facebook telegram
// youtube, fb, vk, inst
const SocialNetworks: FC<IsocialNetworksProps> = ({ rest }) => {
  return (
    <>
    <div className={style.article}>
      {rest.social_ids.map((item) => {
        if (item.active) {
          return <a key={item._id} href={item.link}><img style={{ height: '30px', width: '30px' }} src={`${BASE_URL_CDN + '/' + `${item.image}`}`}/></a>
        }
        return ''
      })}
    </div>
    </>
  )
}

export default SocialNetworks
