// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cart-address-block_params-adress-block__pmvCr {\n    position: relative;\n}\n  \n.cart-address-block_params-adress-block__pmvCr label {\n    position: absolute;\n    top: -2px;\n    left: 20px;\n    background: #FFF;\n    padding: 0 10px;\n    color: #000;\n}\n\n.cart-address-block_cart-input__ZubW2 {\n    font-weight: normal;\n    font-size: 14px;\n    width: 100%;\n    box-sizing: border-box;\n    border-radius: 4px;\n    padding: 12px;\n    margin-top: 10px;\n}", "",{"version":3,"sources":["webpack://./src/components/cart-adress-block/cart-address-block.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,gBAAgB;IAChB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".params-adress-block {\n    position: relative;\n}\n  \n.params-adress-block label {\n    position: absolute;\n    top: -2px;\n    left: 20px;\n    background: #FFF;\n    padding: 0 10px;\n    color: #000;\n}\n\n.cart-input {\n    font-weight: normal;\n    font-size: 14px;\n    width: 100%;\n    box-sizing: border-box;\n    border-radius: 4px;\n    padding: 12px;\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"params-adress-block": "cart-address-block_params-adress-block__pmvCr",
	"cart-input": "cart-address-block_cart-input__ZubW2"
};
export default ___CSS_LOADER_EXPORT___;
