import { FC } from 'react'

interface IButtonPlus {
  fill: string
  stroke: string
}

const ButtonPlus: FC<IButtonPlus> = ({ fill, stroke }) => {
  return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.92308 5.07692V0.923077C6.92308 0.413276 6.5098 0 6 0C5.4902 0 5.07692 0.413276 5.07692 0.923077V5.07692H0.923077C0.413276 5.07692 0 5.4902 0 6C0 6.5098 0.413276 6.92308 0.923077 6.92308H5.07692V11.0769C5.07692 11.5867 5.4902 12 6 12C6.5098 12 6.92308 11.5867 6.92308 11.0769V6.92308H11.0769C11.5867 6.92308 12 6.5098 12 6C12 5.4902 11.5867 5.07692 11.0769 5.07692H6.92308Z" fill={fill} stroke={stroke}/>
      </svg>
  )
}

export default ButtonPlus
