// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const handleResponse = async (response: Response) => {
  if (response.ok) return await response.json()
  else {
    const errorData = await response.json()
    return await Promise.reject(errorData)
  }
}

export const clearLocalStorageFields = (): void => {
  const fieldsToClear = [
    'activeMethods',
    'paymentMethods',
    'yandexCoordinates',
    'selectedOffer',
    'yandexDelivery',
    'deliverySum',
    'offerData',
    'flagDelivery',
    'cartState',
    'isDisableButton',
    'enableDelivery',
    'yandexHouse',
    'yandexStreet',
    'yandexCity'
  ]
  fieldsToClear.forEach(localStorage.removeItem)

  const storedData = localStorage.getItem('orderFormValues')
  if (storedData != null) {
    const { flat, floor, porch, ...rest } = JSON.parse(storedData)
    localStorage.setItem('orderFormValues', JSON.stringify(rest))
  }
}
