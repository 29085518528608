import { BASE_URL } from '../const'
import { handleResponse } from '../helpers'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
export const createOrder = async (order: any) => {
  return await fetch(`${BASE_URL}/order/create`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ...order
    })
  }).then(async (response) => {
    const resp = await handleResponse(response)
    return resp
  })
}

interface IRequestOrders {
  rest_id: string
  user_id: string
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getAllOrdersUserInRest = async (request: IRequestOrders) => {
  return await fetch(
    `${BASE_URL}/order/user/${request.user_id}?rest_id=${request.rest_id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  ).then(async (res) => await handleResponse(res))
}
