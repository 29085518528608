import { FC } from 'react'
import { useTextTranslation } from '../../contexts/TextTranslation'
import style from './adult.module.css'

const AdultIsNotConfirmed: FC = () => {
  const translation = useTextTranslation()
  return (
    <div className={style.popup}>
      <div className={style.popup__container}>
        <h3 className={style.h3}>{translation('thank-you-honest-answer')}</h3>
        <div>{translation('we-care-about-health')}</div>
      </div>
    </div>
  )
}

export default AdultIsNotConfirmed
