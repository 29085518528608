import React, { FC } from 'react'
import { ECountry } from '../utils/typesFromBackend'

// eslint-disable-next-line @typescript-eslint/no-extra-parens
export const SetLanguageContext = React.createContext<(lng: ECountry) => void>(() => {})

interface SetLanguageProviderProps {
  value: (lng: ECountry) => void
  children: React.ReactNode
}

export const SetLanguageProvider: FC<SetLanguageProviderProps> = ({ value, children }) => {
  return (
    <SetLanguageContext.Provider value={value}>
      {children}
    </SetLanguageContext.Provider>
  )
}

export const useSetLanguage = (): (lng: ECountry) => void => React.useContext(SetLanguageContext)
