/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { RefObject, useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useOnClickOutside = (
  ref: RefObject<HTMLDivElement>,
  closeMenu: () => void
) => {
  useEffect(() => {
    const listener = (event: any): void => {
      if (event.target) {
        if (event.target.id) {
          if (event.target.id === 'notification') {
            return
          }
        }
      }
      if (
        (ref.current != null) &&
        (event.target != null) &&
        ref.current.contains(event.target as Node)
      ) {
        return
      }
      closeMenu()
    }

    document.addEventListener('mousedown', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, closeMenu])
}
