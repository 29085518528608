import React, { FC, useRef } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import './cart-time-date.css'

import ru from 'date-fns/locale/ru'
import { useTextTranslation } from '../../contexts/TextTranslation'
import { IBorder } from '../cart-detali/cart-detali'
import { TRest } from '../../utils/typesFromBackend'
registerLocale('ru', ru)

interface ICartTimeDate {
  startDate: Date | null
  setStartDate: (date: Date | null) => void
  border: IBorder
  rest: TRest
}
const CartTimeDate: FC<ICartTimeDate> = ({
  startDate,
  setStartDate,
  border,
  rest
}) => {
  const handleChange = (date: Date | null): void => {
    setStartDate(date)
  }

  const datePickerRef = useRef<any>(null)

  const inputStyle = {
    color: rest.colorsSchema_id.popupInputColor,
    backgroundColor: rest.colorsSchema_id.backgroundColor
  }

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain, @typescript-eslint/strict-boolean-expressions
    if (datePickerRef.current && datePickerRef.current.input) {
      datePickerRef.current.input.style.border = border.border
      datePickerRef.current.input.style.color = inputStyle.color
      datePickerRef.current.input.style.backgroundColor =
        inputStyle.backgroundColor
    }
  }, [border])

  const input = document.querySelector('.cart-input') as HTMLInputElement
  // eslint-disable-next-line
  if (input) {
    input.setAttribute('inputmode', 'none')
  }

  React.useEffect(() => {
    // eslint-disable-next-line
    if (datePickerRef.current) {
      const input = datePickerRef.current.input
      // eslint-disable-next-line
      if (input) {
        input.setAttribute('readonly', true)
      }
    }
  }, [])

  const translation = useTextTranslation()
  return (
    <DatePicker
      selected={startDate}
      ref={datePickerRef}
      onChange={(date) => handleChange(date)}
      minDate={new Date()}
      placeholderText={translation('select-desire-date')}
      dateFormat='dd/MM/yyyy'
      closeOnScroll={true}
      locale='ru'
      className='cart-input'
      popperPlacement='auto'
      isClearable
      withPortal
      required
    />
  )
}
export default CartTimeDate
