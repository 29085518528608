import { FC } from 'react'
import { useCurrentLanguage } from '../../contexts/CurrentLanguageContext'
import { TModifier, TRest } from '../../utils/typesFromBackend'
import style from './modifier-in-modal.module.css'

interface IModifierInModal {
  modifier: TModifier
  groupModifierId?: string
  check: boolean
  rest: TRest
  toggleCheckModifier?: (id: string) => void
  toggleCheckGroupModifier?: (
    groupModifierId: string,
    modifierId: string
  ) => void
  noImage: boolean
}
const ModifierInModal: FC<IModifierInModal> = ({
  modifier,
  groupModifierId,
  check,
  rest,
  toggleCheckModifier,
  toggleCheckGroupModifier,
  noImage
}) => {
  const currentLanguage = useCurrentLanguage()
  const styleModifierColor = {
    color: rest.colorsSchema_id.colorModificator
  }

  const windowWidth = window.innerWidth

  const styleWidthWrap = {
    width: '50%'
  }
  const styleMainColorMod = {
    border: `3px dotted ${rest.colorsSchema_id.buttonColor}`
  }
  function handleClick (): void {
    if (toggleCheckModifier != null) {
      toggleCheckModifier(modifier._id)
    }
    if (toggleCheckGroupModifier != null) {
      if (groupModifierId != null) {
        toggleCheckGroupModifier(groupModifierId, modifier._id)
      }
    }
  }
  return (
    <div
      className={style.modifier}
      style={noImage ? (windowWidth > 767 ? styleWidthWrap : {}) : styleMainColorMod}
    >
      <div
        className={style['container-childModifier__title']}
        onClick={handleClick}
      >
        <input
          type="checkbox"
          className={style['input-childModifier']}
          value={modifier.price}
          name={modifier._id}
          checked={check}
          onChange={() => {}}
        />

        <label className={style.modifier__title} style={styleModifierColor}>
          {modifier.title[currentLanguage]}
        </label>
      </div>
      <p style={styleModifierColor} className={style.modifier__price}>
        {' + ' + modifier.price.toString() + ` ${rest.currentCurrency}`}
      </p>
    </div>
  )
}
export default ModifierInModal
