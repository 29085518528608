import {
  UPDATE_ORDER_FORM,
  RESET_ORDER_FORM,
  OrderFormActionTypes,
  IOrderFormValues
} from '../actions/order-form'

const loadOrderFormValuesFromLS = (): IOrderFormValues => {
  try {
    const serializedState = localStorage.getItem('orderFormValues')
    if (serializedState === null) {
      return {
        name: '',
        userPhone: '',
        city: '',
        street: '',
        house: '',
        flat: '',
        porch: '',
        floor: '',
        email: '',
        time: ''
      }
    }
    return JSON.parse(serializedState)
  } catch (err) {
    console.error('Error loading order form values from local storage', err)
    return {
      name: '',
      userPhone: '',
      city: '',
      street: '',
      house: '',
      flat: '',
      porch: '',
      floor: '',
      email: '',
      time: ''
    }
  }
}

const initialState: IOrderFormValues = loadOrderFormValuesFromLS()

const orderFormValuesReducer = (
  state = initialState,
  action: OrderFormActionTypes
): IOrderFormValues => {
  switch (action.type) {
    case UPDATE_ORDER_FORM:
      return { ...state, ...action.payload }
    case RESET_ORDER_FORM:
      return {
        name: '',
        userPhone: '',
        city: '',
        street: '',
        house: '',
        flat: '',
        porch: '',
        floor: '',
        email: '',
        time: ''
      }
    default:
      return state
  }
}

export default orderFormValuesReducer
