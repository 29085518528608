/* eslint-disable multiline-ternary */
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useTextTranslation } from '../../contexts/TextTranslation'
import { useSelector } from '../../services/hooks/redux-hooks'
import { TRest } from '../../utils/typesFromBackend'

import style from './bottom-cart.module.css'
import useLinkWithParams from '../../services/hooks/useLinkWithParams'

interface IBottomCart {
  rest: TRest
}

interface Dish {
  quantity: string
}

const BottomCart: FC<IBottomCart> = ({ rest }) => {
  const arrayDishesInCart = useSelector((store) => store.cart.dishes) as Dish[]
  const wantOrder = false
  const mainColorButtonStyle = {
    backgroundColor: rest.colorsSchema_id.buttonColor,
    color: rest.colorsSchema_id.buttonTextColor
  }
  const translation = useTextTranslation()

  const createLink = useLinkWithParams()

  const countDish = React.useMemo(
    () =>
      arrayDishesInCart.reduce(
        (acc: number, item) => Number(item.quantity) + acc,
        0
      ),
    [arrayDishesInCart]
  )

  return (
    <div className={style['bottom-line']}>
      <Link
        to={createLink('/cart')}
        className={style['bottom-line__cart']}
        style={mainColorButtonStyle}
      >
        <span className={style['bottom-line__cart-title']}>
          {wantOrder ? translation('want-order') : translation('cart')}
        </span>
        {countDish !== 0 ? (
          <span className={style['bottom-line__cart-sum']}>
            {countDish !== 0 && countDish}
          </span>
        ) : (
          ''
        )}
      </Link>
    </div>
  )
}
export default BottomCart
