// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sale_eventsBlockLink__0tHYk {\n  position: relative;\n  display: block;\n  height: 100%;\n  width: 100vw;\n  margin: 10px 0;\n}\n\n.sale_imageSales__oHHm7 {\n  margin: 0;\n  max-width: 100vw;\n  max-height: 50vh;\n  cursor: pointer;\n}\n\n.sale_imageSales_container__xEZrD {\n  display: flex;\n  justify-content: center;\n}\n\n.sale_imageSales_blur__pucEh {\n  position: absolute;\n  z-index: -1;\n  margin: 0;\n  width: 100vw;\n  max-height: 50vh;\n  cursor: pointer;\n  filter: blur(5px);\n}", "",{"version":3,"sources":["webpack://./src/components/sale/sale.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,YAAY;EACZ,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,SAAS;EACT,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".eventsBlockLink {\n  position: relative;\n  display: block;\n  height: 100%;\n  width: 100vw;\n  margin: 10px 0;\n}\n\n.imageSales {\n  margin: 0;\n  max-width: 100vw;\n  max-height: 50vh;\n  cursor: pointer;\n}\n\n.imageSales_container {\n  display: flex;\n  justify-content: center;\n}\n\n.imageSales_blur {\n  position: absolute;\n  z-index: -1;\n  margin: 0;\n  width: 100vw;\n  max-height: 50vh;\n  cursor: pointer;\n  filter: blur(5px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eventsBlockLink": "sale_eventsBlockLink__0tHYk",
	"imageSales": "sale_imageSales__oHHm7",
	"imageSales_container": "sale_imageSales_container__xEZrD",
	"imageSales_blur": "sale_imageSales_blur__pucEh"
};
export default ___CSS_LOADER_EXPORT___;
