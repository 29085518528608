// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sales_eventsBlock__cPogm {\n  margin-top: 16px;\n  padding: 0;\n  box-sizing: border-box;\n\n  z-index: 2;\n}\n", "",{"version":3,"sources":["webpack://./src/components/sales/sales.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,UAAU;EACV,sBAAsB;;EAEtB,UAAU;AACZ","sourcesContent":[".eventsBlock {\n  margin-top: 16px;\n  padding: 0;\n  box-sizing: border-box;\n\n  z-index: 2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eventsBlock": "sales_eventsBlock__cPogm"
};
export default ___CSS_LOADER_EXPORT___;
