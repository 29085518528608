import { TCity, TUser } from '../../utils/typesFromBackend'
import {
  USER_REQUEST_SUCCESS,
  TUserActions,
  LOGOUT_SUCCESS
} from '../actions/index'

interface IUserInitialStore extends TUser {
  isLoggedIn: boolean
}
const userInitialState: IUserInitialStore = {
  _id: '',
  nickname: '',
  birthdate: '',
  name: '',
  phone: '',
  street: '',
  house: '',
  flat: '',
  porch: '',
  floor: '',
  email: '',
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  city: {} as TCity,
  isLoggedIn: false
}
export const userReducer = (
  state = userInitialState,
  action: TUserActions
): IUserInitialStore => {
  switch (action.type) {
    /* case USER_REGISTRATION_SUCCESS: {
      return {
        ...state,
        userRequest: false,
        name: action.user.name,
        email: action.user.email,
        isLoggedIn: true
      }
    } */
    case USER_REQUEST_SUCCESS: {
      return {
        ...state,
        _id: action.user._id,
        nickname: action.user.nickname,
        birthdate: action.user.birthdate,
        name: action.user.name,
        phone: action.user.phone,
        street: action.user.street,
        house: action.user.house,
        flat: action.user.flat,
        city: action.user.city,
        email: action.user.email,
        porch: action.user.porch,
        floor: action.user.floor,
        isLoggedIn: true
      }
    }
    /* case USER_REQUEST_FAILED: {
      return {
        ...state,
        userRequest: false,
        isLoggedIn: false,
        email: '',
        name: ''
      }
    }
    case LOGOUT_REQUEST: {
      return {
        ...state,
        logoutRequest: true,
        logoutFailed: false
      }
    } */
    case LOGOUT_SUCCESS: {
      return {
        ...state,
        _id: '',
        nickname: '',
        birthdate: '',
        name: '',
        phone: '',
        street: '',
        house: '',
        flat: '',
        porch: '',
        floor: '',
        email: '',
        // city: {},
        isLoggedIn: false
      }
    }
    /*
    case LOGOUT_FAILED: {
      return {
        ...state,
        logoutRequest: false,
        logoutFailed: true
      }
    }
    case UPDATE_USER_REQUEST: {
      return {
        ...state,
        updateUserRequest: true,
        updateUserSuccess: false
      }
    }
    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        updateUserRequest: false,
        updateUserSuccess: true,
        name: action.user.name,
        email: action.user.email
      }
    }
    case UPDATE_USER_FAILED: {
      return {
        ...state,
        updateUserRequest: false,
        updateUserSuccess: false
      }
    }
    case TOKEN_REQUEST: {
      return {
        ...state,
        tokenRequest: true,
        tokenSuccess: false
      }
    }
    case TOKEN_SUCCESS: {
      return {
        ...state,
        tokenRequest: false,
        tokenSuccess: true
      }
    }
    case TOKEN_FAILED: {
      return {
        ...state,
        tokenRequest: false,
        tokenSuccess: false
      }
    } */
    default: {
      return state
    }
  }
}
