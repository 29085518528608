import React, { FC } from 'react'
import style from './modal-select-offers.module.css'
import { useTextTranslation } from '../../contexts/TextTranslation'
import { TRest } from '../../utils/typesFromBackend'
import ButtonPopupClose from '../ui/button-popup-close/button-popup-close'
import { useAnimate } from '../../services/hooks/animate'
import { correctPositionWhenCloseModal } from '../../utils/utils'

interface TModalSelectOffers {
  offerData: any
  setSelectedOffer: (arg: any) => void
  selectedOffer: any
  rest: TRest
  setOpenModal: (arg: boolean) => void
}

const ModalSelectOffers: FC<TModalSelectOffers> = ({
  offerData,
  setSelectedOffer,
  selectedOffer,
  rest,
  setOpenModal
}) => {
  const translation = useTextTranslation()
  const [beingTouched, setBeingTouched] = React.useState<boolean>(false)
  const [touchStartX, setTouchStartX] = React.useState<number>(0)
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const selectedIndex = event.target.selectedIndex
    const selectedOffer = offerData.offers[selectedIndex]
    setSelectedOffer(selectedOffer)
  }

  const styleBackgroundColorButton = {
    background: rest.colorsSchema_id.buttonColor
  }

  const fillButtonClose = rest.colorsSchema_id.buttonTextColor

  const handleStart = (clientX: number): void => {
    setTouchStartX(clientX)
    setBeingTouched(true)
  }

  const params = {
    mount: {
      style: {
        opacity: 1,
        background: 'rgb(5 32 46 / 90%)'
      },
      delay: 500
    },
    unMount: {
      style: {
        opacity: 0,
        background: 'rgb(5 32 46 / 90%)'
      },
      delay: 500
    }
  }

  const closeModal = (): void => {
    correctPositionWhenCloseModal()
    setOpenModal(false)
  }

  const { styleAnimate, handleClose } = useAnimate(params)

  const handleMove = (clientX: number): void => {
    if (beingTouched) {
      const touchX = clientX
      let deltaX = touchX - touchStartX
      if (deltaX < -150) {
        handleClose(closeModal)
      } else if (deltaX > 0) {
        deltaX = 0
      }
    }
  }

  function handleEnd (): void {
    setTouchStartX(0)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleTouchStart = (touchStartEvent: React.TouchEvent) => {
    handleStart(touchStartEvent.targetTouches[0].clientX)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleTouchMove = (touchMoveEvent: React.TouchEvent) => {
    handleMove(touchMoveEvent.targetTouches[0].clientX)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleTouchEnd = () => {
    handleEnd()
  }

  const styleMainColorButton = {
    backgroundColor: rest.colorsSchema_id.buttonColor,
    color: rest.colorsSchema_id.buttonTextColor,
    padding: '7px',
    borderRadius: '5px',
    border: 'none'
  }

  return (
    <div
        className={style.popup}
        style={styleAnimate}
        onTouchStart={touchStartEvent => handleTouchStart(touchStartEvent)}
        onTouchMove={touchMoveEvent => handleTouchMove(touchMoveEvent)}
        onTouchEnd={() => handleTouchEnd()}>
    <div
      style={{
        background: rest.colorsSchema_id.popupBackup,
        border: `2px solid ${rest.colorsSchema_id.borderInputColor}`
      }}
      className={style.modal}
    >
      <h4>{translation('choise-delivery')}</h4>
      <div
        className={style.popup__close}
        style={styleBackgroundColorButton}
        onClick={() => setOpenModal(false)}
      >
        <ButtonPopupClose fill={fillButtonClose} />
      </div>
      <div>
        <select
          style={{ color: rest.colorsSchema_id.mainTextColor }}
          onChange={handleChange}
        >
          {offerData.offers.map((offer: any, index: number) => (
            <option key={index} value={JSON.stringify(offer)}>
              {offer.description === 'express' && translation('express')}
              {offer.description === 'express_30min_longer' &&
                translation('express_30min_longer')}
              {offer.description === '2_hours_delivery' &&
                translation('2_hours_delivery')}
              {offer.description === '4_hours_delivery' &&
                translation('4_hours_delivery')}
            </option>
          ))}
        </select>
      </div>
      <p>
        {translation('price-delivery')}{' '}
      {/* eslint-disable-next-line */}
        {selectedOffer && selectedOffer?.price?.total_price_with_vat}{' '}
        {rest.currentCurrency}
      </p>
      <p>
        {selectedOffer?.description === 'express' &&
          translation('courier-express')}
        {selectedOffer?.description === 'express_30min_longer' &&
          translation('courier-express-30-min')}
        {selectedOffer?.description === '2_hours_delivery' &&
          translation('courier-express-2-hours')}
        {selectedOffer?.description === '4_hours_delivery' &&
          translation('courier-express-4-hours')}
      </p>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button style={styleMainColorButton} onClick={() => setOpenModal(false)}>ОК</button>
        </div>
    </div>
    </div>
  )
}

export default ModalSelectOffers
