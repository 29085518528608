import { BASE_URL } from '../const'
import { handleResponse } from '../helpers'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/naming-convention
export const sendButtonValue = async (rest_id: string, title: string, numberTable?: string | null) => {
  let fetchURL = ''
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  numberTable
    ? fetchURL = `${BASE_URL}/telegram-user?rest_id=${rest_id}&title=${title}&numberTable=${numberTable}`
    : fetchURL = `${BASE_URL}/telegram-user?rest_id=${rest_id}&title=${title}`

  return await fetch(fetchURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(async (res) => await handleResponse(res))
}
