import { TDish, TOrder } from '../../utils/typesFromBackend'

export const DELETE_ITEM: 'DELETE_ITEM' = 'DELETE_ITEM'
export const ADDED_ITEM: 'ADDED_ITEM' = 'ADDED_ITEM'
export const DECREASE_ITEM: 'DECREASE_ITEM' = 'DECREASE_ITEM'
export const CLEAR_CART: 'CLEAR_CART' = 'CLEAR_CART'
export const ADDED_CURRENT_ORDER: 'ADDED_CURRENT_ORDER' = 'ADDED_CURRENT_ORDER'

interface IDeleteItemAction {
  readonly type: typeof DELETE_ITEM
  readonly id: string
}
interface IAddedItemAction {
  readonly type: typeof ADDED_ITEM
  readonly item: TDish
  readonly quantity: number
  readonly id?: string
}
interface IDecreaseItemAction {
  readonly type: typeof DECREASE_ITEM
  readonly item: TDish
  readonly quantity: number
  readonly alone?: boolean
  readonly id?: string
}
interface IClearCartAction {
  readonly type: typeof CLEAR_CART
}
interface IAddedCurrentOrder {
  readonly type: typeof ADDED_CURRENT_ORDER
  readonly order: TOrder
}
export type TCartActions =
  | IDeleteItemAction
  | IAddedItemAction
  | IClearCartAction
  | IDecreaseItemAction
  | IAddedCurrentOrder
