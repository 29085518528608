import React, { FC } from 'react'
import { ECountry } from '../utils/typesFromBackend'

export const CurrentLanguageContext = React.createContext<ECountry>(ECountry.RU)

interface CurrentLanguageProviderProps {
  value: ECountry
  children: React.ReactNode
}

export const CurrentLanguageProvider: FC<CurrentLanguageProviderProps> = ({ value, children }) => {
  return (
    <CurrentLanguageContext.Provider value={value}>
      {children}
    </CurrentLanguageContext.Provider>
  )
}

export const useCurrentLanguage = (): ECountry => React.useContext(CurrentLanguageContext)
