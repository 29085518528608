import React, { FC, useRef } from 'react'
import style from './modal-current-order.module.css'

import { useAnimate } from '../../services/hooks/animate'
import { useOnClickOutside } from '../../services/hooks/on-click-outside'
import { TOrder, TRest } from '../../utils/typesFromBackend'
import ButtonPopupClose from '../ui/button-popup-close/button-popup-close'
import ButtonPopupLine from '../ui/button-popup-line/button-popup-line'
import OrderProduct from '../order-product/order-product'
import { v4 as makeUUID } from 'uuid'
import { useTextTranslation } from '../../contexts/TextTranslation'

interface IModalCurrentOrder {
  closeModal: () => void
  rest: TRest
  currentOrder: TOrder
}
const ModalCurrentOrder: FC<IModalCurrentOrder> = ({
  rest,
  closeModal,
  currentOrder
}) => {
  const [isDelivery, setIsDelivery] = React.useState(false)
  const [addressText, setAddressText] = React.useState('')
  const [generalSum, setGeneralSum] = React.useState(0)
  const [beingTouched, setBeingTouched] = React.useState<boolean>(false)
  const [touchStartX, setTouchStartX] = React.useState<number>(0)
  const translation = useTextTranslation()
  React.useEffect(() => {
    rest.orderType_ids.forEach((orderType) => {
      if (
        orderType._id === currentOrder?.orderType_id &&
        orderType.isDelivery
      ) {
        setIsDelivery(true)
        let resultString = ''
        if (currentOrder.delivery_detail?.city != null) {
          if (rest.city_ids.length > 0) {
            const citySearch = rest.city_ids.find(
              (city) => city._id === currentOrder.delivery_detail?.city
            )
            if (citySearch != null) {
              resultString += citySearch.title + ', '
            }
          } else {
            resultString += currentOrder.delivery_detail?.city + ', '
          }
        }
        if (currentOrder.delivery_detail?.street != null) {
          resultString += currentOrder.delivery_detail?.street + ', '
        }
        if (currentOrder.delivery_detail?.house != null) {
          resultString += currentOrder.delivery_detail?.house + ', '
        }
        if (currentOrder.delivery_detail?.house != null) {
          resultString += currentOrder.delivery_detail?.house + ', '
        }
        if (resultString.length > 0) {
          resultString = resultString.substring(0, resultString.length - 1)
          setAddressText(resultString)
        }
        if (currentOrder.deliveryPrice != null) {
          setGeneralSum(
            Math.round(
              (currentOrder.deliveryPrice + currentOrder.orderPrice) * 100
            ) / 100
          )
        }
      } else {
        if (currentOrder != null) {
          setGeneralSum(currentOrder?.orderPrice)
        }
      }
    })
  }, [currentOrder, rest.orderType_ids])

  const params = {
    mount: {
      style: {
        opacity: 1,
        background: 'rgb(5 32 46 / 90%)'
      },
      delay: 500
    },
    unMount: {
      style: {
        opacity: 0,
        background: 'rgb(5 32 46 / 90%)'
      },
      delay: 1000
    }
  }
  const { styleAnimate, handleClose } = useAnimate(params)

  React.useEffect(() => {
    document.addEventListener('keydown', closeESC)
    return () => document.removeEventListener('keydown', closeESC)
  })

  const EscCode = 27

  function closeESC (evt: KeyboardEvent): void {
    if (evt.keyCode === EscCode) {
      handleClose(closeModal)
    }
  }

  const node = useRef<HTMLDivElement>(null)
  const fill = rest.colorsSchema_id.mainColor
  const stroke = rest.colorsSchema_id.mainColor
  const windowWidth = window.innerWidth
  const fillButtonClose = rest.colorsSchema_id.buttonTextColor

  const styleBackgroundColorButton = {
    background: rest.colorsSchema_id.buttonColor
  }
  const styleBackgroundColor = {
    background: rest.colorsSchema_id.backgroundColor,
    color: rest.colorsSchema_id.mainTextColor
  }
  const styleMainColorText = {
    color: rest.colorsSchema_id.mainColor
  }
  const styleLoght = {
    background: rest.colorsSchema_id.loght
  }
  const styleTextLight = {
    color: rest.colorsSchema_id.textLight
  }

  useOnClickOutside(node, handleClick)

  function handleClick (): void {
    handleClose(closeModal)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleStart = (clientX: number) => {
    setTouchStartX(clientX)
    setBeingTouched(true)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleMove = (clientX: number) => {
    if (beingTouched) {
      const touchX = clientX
      let deltaX = touchX - touchStartX
      if (deltaX < -150) {
        handleClose(closeModal)
      } else if (deltaX > 0) {
        deltaX = 0
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function handleEnd () {
    setTouchStartX(0)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleTouchStart = (touchStartEvent: React.TouchEvent) => {
    handleStart(touchStartEvent.targetTouches[0].clientX)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleTouchMove = (touchMoveEvent: React.TouchEvent) => {
    handleMove(touchMoveEvent.targetTouches[0].clientX)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleTouchEnd = () => {
    handleEnd()
  }

  const dividedGeneralSum = generalSum
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

  return (
    <div
      className={style.popup}
      style={styleAnimate}
      onTouchStart={(touchStartEvent) => handleTouchStart(touchStartEvent)}
      onTouchMove={(touchMoveEvent) => handleTouchMove(touchMoveEvent)}
      onTouchEnd={() => handleTouchEnd()}
    >
      <div
        className={style['order-detail__body']}
        style={styleBackgroundColor}
        ref={node}
      >
        {windowWidth > 767
          ? (
          <div
            className={style.popup__close}
            style={styleBackgroundColorButton}
            onClick={handleClick}
          >
            <ButtonPopupClose fill={fillButtonClose} />
          </div>
            )
          : (
          <div className={style['popup__img-line']} onClick={handleClick}>
            <ButtonPopupLine fill={fill} stroke={stroke} />
          </div>
            )}
        <div className={style['order-detail__title']}>{translation('order-details')}</div>
        <div className={style['order-detail__products']}>
          {currentOrder?.orderList.dishes.map((dish) => (
            <OrderProduct
              rest={rest}
              prodCount={dish.quantity}
              dish={dish.dish}
              key={dish.id}
            />
          ))}
        </div>
        <div className={style['order-detail__params']}>
          <div className={style['params-user']}>
            <div className={style['params-user__title']}>{translation('telephone')}</div>
            <div
              className={style['params-user__value']}
              style={styleMainColorText}
            >
              {currentOrder?.userPhone}
            </div>
          </div>
          {isDelivery
            ? (
            <div className={style['params-adress']}>
              <div className={style['params-adress__title']}>
                {translation('delivery-address')}
              </div>
              <div
                className={style['params-adress__value']}
                style={styleMainColorText}
              >
                {addressText}
              </div>
            </div>
              )
            : (
                ''
              )}
          {currentOrder.customInputs.length > 0
            ? currentOrder.customInputs.map((customInput) => (
                <div className={style['params-adress']} key={makeUUID()}>
                  <div className={style['params-adress__title']}>
                    {customInput.name}
                  </div>
                  <div
                    className={style['params-adress__value']}
                    style={styleMainColorText}
                  >
                    {customInput.value}
                  </div>
                </div>
            ))
            : ''}
        </div>
        <div className={style['order-detail__general']} style={styleLoght}>
          <div className={style['order-detail__general-summ']}>
            <div
              className={style['order-detail__general-summ-title']}
              style={styleTextLight}
            >
              {translation('amount-goods')}
            </div>
            <div
              className={style['order-detail__general-summ-value']}
              style={{ paddingRight: '5px' }}
            >
              {currentOrder?.orderPrice}&nbsp;{rest.currentCurrency}
            </div>
          </div>
          {isDelivery
            ? (
            <div className={style['order-detail__general-delivery']}>
              <div
                className={style['order-detail__general-delivery-title']}
                style={styleTextLight}
              >
                {translation('delivery')}
              </div>
              <div
                className={style['order-detail__general-delivery-value']}
                style={{ paddingRight: '5px' }}
              >
                {currentOrder?.deliveryPrice}&nbsp;{rest.currentCurrency}
              </div>
            </div>
              )
            : (
                ''
              )}

          <div className={style['order-detail__general-final']}>
            <div
              className={style['order-detail__general-final-title']}
              style={styleTextLight}
            >
              {currentOrder != null
                ? currentOrder?.isPaid
                  ? translation('is-paid')
                  : translation('to-be-paid')
                : ''}
            </div>
            <div
              className={style['order-detail__general-final-value']}
              style={{ paddingRight: '5px' }}
            >
              {dividedGeneralSum}&nbsp;{rest.currentCurrency}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ModalCurrentOrder
