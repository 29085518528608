import {
  SHOW_CATEGORIES_CARD_VIEW,
  ShowCategoriesCardView
} from '../actions/categories-card-view'

export interface IShowCategoriesCardViewState {
  show: boolean
}

const initialState: IShowCategoriesCardViewState = {
  show: false
}

export const showCategoriesCardViewReducer = (
  state = initialState,
  action: ShowCategoriesCardView
): IShowCategoriesCardViewState => {
  switch (action.type) {
    case SHOW_CATEGORIES_CARD_VIEW: {
      return {
        ...state,
        show: action.payload
      }
    }
    default:
      return state
  }
}
