import style from './spinner.module.css'
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const DefaultSpinner = () => {
  return <div className={style.container}>
    <div className={style['loadingio-spinner-eclipse-2m92p0ci1nj']}>
      <div className={style['ldio-d78geh853at']}>
        <div> </div>
      </div>
    </div>
  </div>
}
