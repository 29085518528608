import React, { FC } from 'react'
import { useTextTranslation } from '../../../contexts/TextTranslation'

import style from './input-email.module.css'
import { IBorder } from '../../cart-detali/cart-detali'
import { TRest } from '../../../utils/typesFromBackend'

interface IInputEmail {
  email: string
  setEmail: (arg: string) => void
  border: IBorder
  rest: TRest
}

const InputEmail: FC<IInputEmail> = ({ email, setEmail, border, rest }) => {
  const [validInput, setValidInput] = React.useState(true)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const translation = useTextTranslation()
  function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>): void {
    setEmail(e.target.value)
    setValidInput(e.target.checkValidity())
  }

  const textColor = {
    color: rest.colorsSchema_id.mainTextColor,
    backgroundColor: rest.colorsSchema_id.backgroundColor
  }

  const inputStyle = {
    color: rest.colorsSchema_id.popupInputColor,
    backgroundColor: rest.colorsSchema_id.backgroundColor
  }

  return (
    <div className={style['input-wrapper']}>
      <label style={textColor} className={style['cart-detail__subtitle']}>
        {translation('enter-your-email')}
      </label>
      <input
        ref={inputRef}
        className={style['cart-input']}
        style={{ ...border, ...inputStyle }}
        type='email'
        name='email'
        minLength={2}
        required
        placeholder={translation('your-email')}
        value={email}
        onChange={handleChangeInput}
        autoComplete='off'
      />
      <span className={style['cart-input__error']}>
        {validInput ? '' : translation('you-need-to-enter-your-email')}
      </span>
    </div>
  )
}
export default InputEmail
