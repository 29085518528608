import { FC, useEffect, useState, useRef } from 'react'
import style from './dish-modal.module.css'
import imageNoPhoto from '../../assets/images/no_photo.png'
import {
  TCategoryModifier,
  TDish,
  TModifier,
  TRest
} from '../../utils/typesFromBackend'

import ButtonPlus from '../ui/button-plus/button-plus'
import ButtonMinus from '../ui/button-minus/button-minus'
import ButtonPopupClose from '../ui/button-popup-close/button-popup-close'
import ModifierInModal from '../modifier-in-modal/modifier-in-modal'
import GroupModifierInModal from '../group-modifier-in-modal/group-modifier-in-modal'
import { IModifierInModal } from '../../utils/types'
import { useDispatch } from '../../services/hooks/redux-hooks'
import { ADDED_ITEM } from '../../services/actions'
import { useOnClickOutside } from '../../services/hooks/on-click-outside'
import { useAnimate } from '../../services/hooks/animate'
import { BASE_URL_CDN } from '../../utils/const'
import { setNotification } from '../../services/actions/notification'
import { useTextTranslation } from '../../contexts/TextTranslation'
import { useCurrentLanguage } from '../../contexts/CurrentLanguageContext'
import { useSwipe } from '../../services/hooks'
import ButtonPopupLine from '../ui/button-popup-line/button-popup-line'

interface IDishModal {
  dish: TDish
  rest: TRest
  isNoImage: boolean
  activeModifiers: boolean
  closeModal: () => void
}

interface IGroupModifierInModal {
  groupModifier: TCategoryModifier
  modifiers: IModifierInModal[]
}

const ESC_CODE = 27

const DishModal: FC<IDishModal> = ({
  dish,
  rest,
  isNoImage,
  activeModifiers,
  closeModal
}) => {
  const [quantity, setQuantity] = useState(1)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showWeight, setShowWeight] = useState(true)
  const [showProductsControl, setShowProductsControl] = useState(false)
  const [wantOrder, setWantOrder] = useState(false)
  const [sumInModal, setSumInModal] = useState(0)
  const [modifiersInModal, setModifiersInModal] = useState<IModifierInModal[]>(
    []
  )
  const [groupModifiersInModal, setGroupModifiersInModal] = useState<
    IGroupModifierInModal[]
  >([])
  /* const [top, setTop] = useState<number>(0) */
  const [imageLoaded, setImageLoaded] = useState(false)

  const translation = useTextTranslation()
  const currentLanguage = useCurrentLanguage()
  const dispatch = useDispatch()

  const { handleTouchStart, handleTouchMove, handleTouchEnd } = useSwipe({
    slideDown: () => handleClose(closeModal),
    slideLeft: () => handleClose(closeModal)
  })

  const handleImageLoad = (): void => {
    setImageLoaded(true)
  }

  const showAlert = (
    message: string,
    type: 'success' | 'danger' | 'warning'
  ): void => {
    dispatch(setNotification({ message, type }))
  }

  function closeESC(evt: KeyboardEvent): void {
    if (evt.keyCode === ESC_CODE) {
      handleClose(closeModal)
    }
  }

  function handleClick(): void {
    handleClose(closeModal)
  }

  function handleDecrease(): void {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  function handleIncrease(): void {
    setQuantity(quantity + 1)
  }

  function toggleCheckModifier(id: string): void {
    const resultArray: IModifierInModal[] = []
    modifiersInModal.forEach((item) => {
      if (item.modifier._id === id) {
        resultArray.push({ modifier: item.modifier, check: !item.check })
      } else {
        resultArray.push(item)
      }
    })
    setModifiersInModal(resultArray)
  }

  function toggleCheckGroupModifier(
    groupModifierId: string,
    modifierId: string
  ): void {
    const resultArray: IGroupModifierInModal[] = []
    groupModifiersInModal.forEach((item) => {
      if (item.groupModifier._id === groupModifierId) {
        if (item.groupModifier.isGroupModifierCategoryRadio) {
          item.modifiers.forEach((modifier) => {
            if (modifier.modifier._id === modifierId) {
              modifier.check = !modifier.check
            } else {
              modifier.check = false
            }
          })
          resultArray.push(item)
        } else {
          item.modifiers.forEach((modifier) => {
            if (modifier.modifier._id === modifierId) {
              modifier.check = !modifier.check
            }
          })
          resultArray.push(item)
        }
      } else {
        resultArray.push(item)
      }
    })
    setGroupModifiersInModal(resultArray)
  }

  function handleAddInCart(): void {
    if (groupModifiersInModal.length > 0) {
      let flagCheck = true
      groupModifiersInModal.forEach((item) => {
        if (item.groupModifier.isGroupModifierRequired) {
          if (item.modifiers.some((modifier) => modifier.check)) {
            /* empty */
          } else {
            flagCheck = false
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            showAlert(
              translation('you-need-choose-something') +
                ' ' +
                item.groupModifier.title[currentLanguage],
              'danger'
            )
          }
        }
      })
      if (flagCheck) {
        const modifiersCheckedArray = createDishForCart()
        dispatch({
          type: ADDED_ITEM,
          item: {
            ...dish,
            modifiers_ids: modifiersCheckedArray,
            groupModifiers_ids: []
          },
          quantity
        })
        handleClose(closeModal)
      }
    } else {
      const modifiersCheckedArray = createDishForCart()
      dispatch({
        type: ADDED_ITEM,
        item: {
          ...dish,
          modifiers_ids: modifiersCheckedArray,
          groupModifiers_ids: []
        },
        quantity
      })
      handleClose(closeModal)
    }
  }

  function createDishForCart(): TModifier[] {
    const modifiersArray: TModifier[] = []
    if (modifiersInModal.length > 0) {
      modifiersInModal.forEach((modifier) => {
        if (modifier.check) {
          modifiersArray.push(modifier.modifier)
        }
      })
    }
    if (groupModifiersInModal.length > 0) {
      groupModifiersInModal.forEach((groupModifier) => {
        groupModifier.modifiers.forEach((modifier) => {
          if (modifier.check) {
            modifiersArray.push(modifier.modifier)
          }
        })
      })
    }
    return modifiersArray
  }

  useEffect(() => {
    if (rest.tariff === 'Basic') {
      setShowProductsControl(false)
    } else {
      setShowProductsControl(true)
    }
    if (rest.tariff === 'Extend') {
      setWantOrder(true)
    } else {
      setWantOrder(false)
    }
  }, [rest.tariff])

  useEffect(() => {
    const resultArray: IModifierInModal[] = []
    const resultArrayGroupModifiers: IGroupModifierInModal[] = []
    if (activeModifiers) {
      dish.modifiers_ids.forEach((modifier) => {
        if (modifier.active) {
          resultArray.push({ modifier, check: false })
        }
      })
      dish.groupModifiers_ids.forEach((groupModifier) => {
        if (groupModifier.active) {
          if (groupModifier.isGroupModifierRequired) {
            // здесь добавляем модификаторы в массив, если isRequired группа - то один элемент checked true
            const arrayModifiers = []
            for (let i = 0; i < groupModifier.modifiers_ids.length; i++) {
              if (groupModifier.modifiers_ids[i].active) {
                arrayModifiers.push({
                  modifier: groupModifier.modifiers_ids[i],
                  check: false
                })
              }
            }
            if (arrayModifiers.length > 0) {
              arrayModifiers[0].check = true
              resultArrayGroupModifiers.push({
                groupModifier,
                modifiers: arrayModifiers
              })
            }
          } else {
            const arrayModifiers = []
            for (let i = 0; i < groupModifier.modifiers_ids.length; i++) {
              if (groupModifier.modifiers_ids[i].active) {
                arrayModifiers.push({
                  modifier: groupModifier.modifiers_ids[i],
                  check: false
                })
              }
            }
            if (arrayModifiers.length > 0) {
              resultArrayGroupModifiers.push({
                groupModifier,
                modifiers: arrayModifiers
              })
            }
          }
        }
      })
      setModifiersInModal(resultArray)
      setGroupModifiersInModal(resultArrayGroupModifiers)
    }
  }, [activeModifiers, dish.groupModifiers_ids, dish.modifiers_ids])

  useEffect(() => {
    let sum = dish.price
    if (modifiersInModal.length > 0) {
      modifiersInModal.forEach((itemInArrayModifiers) => {
        if (itemInArrayModifiers.check) {
          if (itemInArrayModifiers.modifier.price !== 0) {
            sum += itemInArrayModifiers.modifier.price
          }
        }
      })
    }
    if (groupModifiersInModal.length > 0) {
      groupModifiersInModal.forEach((groupModifier) => {
        groupModifier.modifiers.forEach((modifier) => {
          if (modifier.check) {
            if (modifier.modifier.price !== 0) {
              sum += modifier.modifier.price
            }
          }
        })
      })
    }
    setSumInModal(Math.round(sum * quantity * 100) / 100)
  }, [modifiersInModal, dish.price, groupModifiersInModal, quantity])

  useEffect(() => {
    document.addEventListener('keydown', closeESC)
    return () => document.removeEventListener('keydown', closeESC)
  })

  const stroke = rest.colorsSchema_id.mainColor
  const windowWidth = window.innerWidth
  const fillButtonClose = rest.colorsSchema_id.buttonTextColor
  const fillButtonPopupLine = rest.colorsSchema_id.mainColor

  const styleBackgroundColorButton = {
    background: rest.colorsSchema_id.buttonColor
  }
  // eslint-disable-next-line
  const element = document.getElementById('product-popup__body') as HTMLElement
  const node = useRef<HTMLDivElement>(null)

  /* const updateWindowDimensions = (): void => {
    // eslint-disable-next-line
    if (element && window.innerWidth < 767) {
      const topValue = window.innerHeight - element.offsetHeight - 17
      setTop(topValue)
      console.log(window.innerHeight)
      console.log(element.offsetHeight)
    }
  } */

  /* const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const updateTopValue = (): void => {
    // eslint-disable-next-line
    if (element && window.innerWidth < 767) {
      // eslint-disable-next-line
      const elementHeight = node.current ? node.current.offsetHeight : 0
      const topValue = window.innerHeight - elementHeight - 17
      setTop(topValue)
    }
  } */

  /* useEffect(() => {
    const handleResize = (): void => {
      const newWidth = window.innerWidth
      const newHeight = window.innerHeight

      // Check if the screen was rotated
      if ((newWidth < newHeight && windowSize.width >= windowSize.height) ||
          (newWidth >= newHeight && windowSize.width < windowSize.height)) {
        updateTopValue()
      }

      setWindowSize({ width: newWidth, height: newHeight })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [element, imageLoaded, windowSize])
  useEffect(() => {
    updateTopValue()

    const resizeObserver = new ResizeObserver(() => {
      updateTopValue()
    })
    if (node.current) {
      resizeObserver.observe(node.current)
    }

    return () => {
      if (node.current) {
        resizeObserver.unobserve(node.current)
      }
    }
  }, [element, imageLoaded]) */

  const styleBackgroundAndTop = {
    background: rest.colorsSchema_id.buttonColor
  }
  const styleBackgroundColor = {
    background: rest.colorsSchema_id.popupBackup,
    color: rest.colorsSchema_id.mainTextColor
  }

  const dishSale = {
    color: rest.colorsSchema_id.buttonTextColor,
    backgroundColor: rest.colorsSchema_id.popupBackup
  }

  const styleMainColorButton = {
    backgroundColor: rest.colorsSchema_id.buttonColor,
    color: rest.colorsSchema_id.buttonTextColor
  }
  const styleButtons = rest.colorsSchema_id.buttonTextColor

  useOnClickOutside(node, handleClick)

  function addMargin(): string {
    if (groupModifiersInModal.length > 0) {
      return '0'
    } else if (groupModifiersInModal.length <= 0) {
      if (windowWidth > 768) {
        return '0'
      } else {
        return '179'
      }
    } else {
      return '0'
    }
  }

  function addMarginNoImage(): string {
    if (groupModifiersInModal.length > 0) {
      return '0'
    } else if (groupModifiersInModal.length <= 0) {
      if (windowWidth > 768) {
        return '0'
      } else {
        return '179'
      }
    } else {
      return '0'
    }
  }

  const params = {
    mount: {
      style: {
        opacity: 1,
        background: 'rgb(5 32 46 / 90%)'
      },
      delay: 500
    },
    unMount: {
      style: {
        opacity: 0,
        background: 'rgb(5 32 46 / 90%)'
      },
      delay: 500
    }
  }
  const { styleAnimate, handleClose } = useAnimate(params)

  const dividedSumInModal = sumInModal
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

  const dishWeightColor = {
    color: rest.colorsSchema_id.dishWeightColor
  }

  useEffect(() => {
    const handleResize = (): void => {
      setIsMobile(window.innerWidth <= 768)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div
      className={style.popup}
      style={styleAnimate}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {isNoImage ? (
        <div
          className={style['product-popup__body_no_image']}
          style={styleBackgroundColor}
          ref={node}
        >
          <div
            className={style.popup__close}
            style={styleBackgroundColorButton}
            onClick={handleClick}
          >
            <ButtonPopupClose fill={fillButtonClose} />
          </div>
          {windowWidth < 768 ? (
            <>
              <div className={style['popup__img-line']} onClick={handleClick}>
                <ButtonPopupLine fill={fillButtonPopupLine} stroke={stroke} />
              </div>
            </>
          ) : (
            ''
          )}

          <div style={{ overflowY: 'auto', minHeight: '35vh' }}>
            <div
              className={
                showProductsControl
                  ? style['product-popup__info']
                  : `${style['product-popup__info']} ${style['pad-b-30']}`
              }
            >
              <div className={style['product-popup__title']}>
                <span className={style['product-popup__title-value']}>
                  {dish.title[currentLanguage]}
                </span>
              </div>
              <div
                style={{
                  color: `${rest.colorsSchema_id.colorDescriptionInModal}`
                }}
                className={style['product-popup__description']}
              >
                {dish.description[currentLanguage]}
              </div>
            </div>
            {showWeight ? (
              <div className={style['product-popup__price-container']}>
                <div className={style['product-popup__price']}>
                  <span className={style['product-popup__price-value']}>
                    {dish.price}&nbsp;{rest.currentCurrency}
                  </span>
                </div>
                <span className={style['product-popup__unit-value']}>
                  {' '}
                  {/* eslint-disable-next-line */}
                  {dish.unit === 'шт' ? '1' : dish.weight ? dish.weight : ''}
                  &nbsp;{dish.unit}
                </span>
              </div>
            ) : (
              ''
            )}
            {activeModifiers ? (
              <div
                className={`${style['product-popup__content']} ${style['product-popup__content_no_image']}`}
              >
                <h2 className={style['title-popup-with-modifier_no_image']}>
                  {translation('additionally')}
                </h2>
                <div
                  style={{ marginBottom: addMarginNoImage() + 'px' }}
                  className={style['modifier-in-popup__body_no_image']}
                >
                  {modifiersInModal.map((modifier) => (
                    <ModifierInModal
                      key={modifier.modifier._id}
                      modifier={modifier.modifier}
                      rest={rest}
                      check={modifier.check}
                      toggleCheckModifier={toggleCheckModifier}
                      noImage={isNoImage}
                    />
                  ))}
                </div>
                <div
                  style={{
                    marginBottom:
                      groupModifiersInModal.length > 0
                        ? windowWidth < 768
                          ? '179px'
                          : '0px'
                        : '0px'
                  }}
                  className={style['group-modifier-in-popup__body_no_image']}
                >
                  {groupModifiersInModal.map((item) => (
                    <GroupModifierInModal
                      key={item.groupModifier._id}
                      rest={rest}
                      groupModifier={item.groupModifier}
                      modifiers={item.modifiers}
                      toggleCheckGroupModifier={toggleCheckGroupModifier}
                      noImage={isNoImage}
                    />
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          {showProductsControl ? (
            activeModifiers ? (
              <div
                style={{
                  backgroundColor: `${rest.colorsSchema_id.popupBackup}`
                }}
                className={
                  windowWidth > 767
                    ? `${style['product-popup__controls']} ${style['product-popup__controls_no_image_desktop']}`
                    : `${style['product-popup__controls']} ${style['product-popup__controls_no_image_mobile']}`
                }
              >
                <div className={style.controls}>
                  <div className={style['product-popup__count']}>
                    <div
                      className={style['product-popup__count-minus_no_image']}
                      onClick={handleDecrease}
                      style={styleMainColorButton}
                    >
                      <ButtonMinus fill={styleButtons} stroke={stroke} />
                    </div>
                    <div
                      className={style['product-popup__count-value_no_image']}
                    >
                      {quantity}
                    </div>
                    <div
                      className={style['product-popup__count-plus_no_image']}
                      onClick={handleIncrease}
                      style={styleMainColorButton}
                    >
                      <ButtonPlus fill={styleButtons} stroke={stroke} />
                    </div>
                  </div>
                  <div className={style['popup-sum']}>
                    <p className={style['product-popup-modifier__price']}>
                      {translation('amount')}
                    </p>
                    <p
                      className={`${style['group-modifier-sum']} ${style['product-popup-modifier__price']}`}
                    >
                      {dividedSumInModal}&nbsp;{rest.currentCurrency}
                    </p>
                  </div>
                </div>
                <div className={style['product-popup__add']}>
                  <span
                    className={style.addButton}
                    style={styleMainColorButton}
                    onClick={handleAddInCart}
                  >
                    {wantOrder ? translation('want-order') : translation('add')}
                  </span>
                </div>
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: `${rest.colorsSchema_id.popupBackup}`
                }}
                className={
                  windowWidth > 767
                    ? `${style['product-popup__controls']} ${style['product-popup__controls_no_image']}`
                    : style['product-popup__controls']
                }
              >
                <div className={style.controls}>
                  <div className={style['product-popup__count']}>
                    <div
                      className={style['product-popup__count-minus']}
                      onClick={handleDecrease}
                      style={styleMainColorButton}
                    >
                      <ButtonMinus fill={styleButtons} stroke={stroke} />
                    </div>
                    <div className={style['product-popup__count-value']}>
                      {quantity}
                    </div>
                    <div
                      className={style['product-popup__count-plus']}
                      onClick={handleIncrease}
                      style={styleMainColorButton}
                    >
                      <ButtonPlus fill={styleButtons} stroke={stroke} />
                    </div>
                  </div>
                  <div className={style['popup-sum']}>
                    <p className={style['product-popup-modifier__price']}>
                      {translation('amount')}
                    </p>
                    <p
                      className={`${style['group-modifier-sum']} ${style['product-popup-modifier__price']}`}
                    >
                      {dividedSumInModal}&nbsp;{rest.currentCurrency}
                    </p>
                  </div>
                </div>
                <div className={style['product-popup__add']}>
                  <span
                    className={style.addButton}
                    style={styleMainColorButton}
                    onClick={handleAddInCart}
                  >
                    {wantOrder ? translation('want-order') : translation('add')}
                  </span>
                </div>
              </div>
            )
          ) : (
            ''
          )}
        </div>
      ) : (
        <div style={{ maxHeight: '90vh', position: 'relative', width: '100%' }}>
          {windowWidth < 768 ? (
            <>
              <div
                className={style.popup__close}
                style={styleBackgroundColorButton}
                onClick={handleClick}
              >
                <ButtonPopupClose fill={fillButtonClose} />
              </div>
              <div className={style['popup__img-line']} onClick={handleClick}>
                <ButtonPopupLine fill={fillButtonPopupLine} stroke={stroke} />
              </div>
            </>
          ) : (
            ''
          )}
          <div
            id='product-popup__body'
            className={style['product-popup__body']}
            style={styleBackgroundColor}
            ref={node}
          >
            {/* eslint-disable-next-line */}
            {/* <div
                className={style.popup__close}
                style={styleBackgroundColorButton}
                onClick={handleClick}
              >
                <ButtonPopupClose fill={fillButtonClose} />
              </div> */}
            <div className={style['product-popup__inf-container']}>
              {dish.discount > 0 ? (
                <div className={style.discount} style={dishSale}>
                  {dish.discount}% Off
                </div>
              ) : (
                ''
              )}
              {/* eslint-disable-next-line */}
              {windowWidth > 768 ? (
                <div
                  className={style.popup__close}
                  style={styleBackgroundAndTop}
                  onClick={handleClick}
                >
                  <ButtonPopupClose fill={fillButtonClose} />
                </div>
              ) : (
                ''
              )}
              <img
                /* eslint-disable */
                src={
                  dish.image
                    ? `${BASE_URL_CDN}/${dish.image}`
                    : rest.logoPath
                    ? `${BASE_URL_CDN}/${rest.logoPath}`
                    : imageNoPhoto
                }
                /* eslint-enable */
                alt={dish.title[currentLanguage]}
                className={`${style['product-popup__img']} ${
                  imageLoaded ? '' : style.hidden
                }`}
                id='product-popup__img'
                onLoad={handleImageLoad}
              />
              <div
                className={
                  showProductsControl
                    ? style['product-popup__info']
                    : `${style['product-popup__info']} ${style['pad-b-30']}`
                }
              >
                <div className={style['product-popup__title']}>
                  <span className={style['product-popup__title-value']}>
                    {dish.title[currentLanguage]}
                  </span>
                </div>
                <div
                  style={{
                    color: `${rest.colorsSchema_id.colorDescriptionInModal}`
                  }}
                  className={style['product-popup__description']}
                >
                  {dish.description[currentLanguage]}
                </div>
              </div>
            </div>
            <div className={style.popup__product_info}>
              {showWeight ? (
                <div className={style['product-popup__priceweight']}>
                  <div className={style['product-popup__price']}>
                    <span className={style['product-popup__price-value']}>
                      {dish.price}&nbsp;{rest.currentCurrency}
                    </span>
                  </div>
                  <span
                    style={dishWeightColor}
                    className={style['product-popup__unit-value']}
                  >
                    {' '}
                    {/* eslint-disable-next-line */}
                    {dish.unit === 'шт' ? '1' : dish.weight ? dish.weight : ''}
                    &nbsp;{dish.unit}
                  </span>
                </div>
              ) : (
                ''
              )}
              {activeModifiers ? (
                <div className={style['product-popup__content']}>
                  <h2
                    style={{
                      color: `${rest.colorsSchema_id.colorModificator}`
                    }}
                    className={style['title-popup-with-modifier']}
                  >
                    {translation('additionally')}
                  </h2>
                  <div
                    style={{ marginBottom: addMargin() + 'px' }}
                    className={style['modifier-in-popup__body']}
                  >
                    {modifiersInModal.map((modifier) => (
                      <ModifierInModal
                        key={modifier.modifier._id}
                        modifier={modifier.modifier}
                        rest={rest}
                        check={modifier.check}
                        toggleCheckModifier={toggleCheckModifier}
                        noImage={isNoImage}
                      />
                    ))}
                  </div>
                  <div
                    style={{
                      marginBottom:
                        groupModifiersInModal.length > 0
                          ? windowWidth < 768
                            ? '179px'
                            : '0px'
                          : '0px'
                    }}
                    className={style['group-modifier-in-popup__body']}
                  >
                    {groupModifiersInModal.map((item) => (
                      <GroupModifierInModal
                        key={item.groupModifier._id}
                        rest={rest}
                        groupModifier={item.groupModifier}
                        modifiers={item.modifiers}
                        toggleCheckGroupModifier={toggleCheckGroupModifier}
                        noImage={isNoImage}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                ''
              )}
              {showProductsControl && innerWidth > 768 ? (
                activeModifiers ? (
                  <div
                    style={{
                      backgroundColor: `${rest.colorsSchema_id.popupBackup}`
                    }}
                    className={
                      style['product-popup__controls_active_modifiers']
                    }
                  >
                    <div className={style.controls}>
                      <div className={style['product-popup__count']}>
                        <div
                          className={style['product-popup__count-minus']}
                          onClick={handleDecrease}
                          style={styleMainColorButton}
                        >
                          <ButtonMinus fill={styleButtons} stroke={stroke} />
                        </div>
                        <div className={style['product-popup__count-value']}>
                          {quantity}
                        </div>
                        <div
                          className={style['product-popup__count-plus']}
                          onClick={handleIncrease}
                          style={styleMainColorButton}
                        >
                          <ButtonPlus fill={styleButtons} stroke={stroke} />
                        </div>
                      </div>
                      <div className={style['popup-sum']}>
                        <p className={style['product-popup-modifier__price']}>
                          {translation('amount')}
                        </p>
                        <p
                          className={`${style['group-modifier-sum']} ${style['product-popup-modifier__price']}`}
                        >
                          {dividedSumInModal}&nbsp;{rest.currentCurrency}
                        </p>
                      </div>
                    </div>
                    <div className={style['product-popup__add']}>
                      <span
                        className={style.addButton}
                        style={styleMainColorButton}
                        onClick={handleAddInCart}
                      >
                        {wantOrder
                          ? translation('want-order')
                          : translation('add')}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundColor: `${rest.colorsSchema_id.popupBackup}`
                    }}
                    className={
                      style['product-popup__controls_active_modifiers']
                    }
                  >
                    <div className={style.controls}>
                      <div className={style['product-popup__count']}>
                        <div
                          className={style['product-popup__count-minus']}
                          onClick={handleDecrease}
                          style={styleMainColorButton}
                        >
                          <ButtonMinus fill={styleButtons} stroke={stroke} />
                        </div>
                        <div className={style['product-popup__count-value']}>
                          {quantity}
                        </div>
                        <div
                          className={style['product-popup__count-plus']}
                          onClick={handleIncrease}
                          style={styleMainColorButton}
                        >
                          <ButtonPlus fill={styleButtons} stroke={stroke} />
                        </div>
                      </div>
                      <div className={style['popup-sum']}>
                        <p className={style['product-popup-modifier__price']}>
                          {translation('amount')}
                        </p>
                        <p
                          className={`${style['group-modifier-sum']} ${style['product-popup-modifier__price']}`}
                        >
                          {dividedSumInModal}&nbsp;{rest.currentCurrency}
                        </p>
                      </div>
                    </div>
                    <div className={style['product-popup__add']}>
                      <span
                        className={style.addButton}
                        style={styleMainColorButton}
                        onClick={handleAddInCart}
                      >
                        {wantOrder
                          ? translation('want-order')
                          : translation('add')}
                      </span>
                    </div>
                  </div>
                )
              ) : (
                ''
              )}
            </div>
            {showProductsControl && isMobile ? (
              activeModifiers ? (
                <div
                  style={{
                    backgroundColor: `${rest.colorsSchema_id.popupBackup}`
                  }}
                  className={style['product-popup__controls_active_modifiers']}
                >
                  <div className={style.controls}>
                    <div className={style['product-popup__count']}>
                      <div
                        className={style['product-popup__count-minus']}
                        onClick={handleDecrease}
                        style={styleMainColorButton}
                      >
                        <ButtonMinus fill={styleButtons} stroke={stroke} />
                      </div>
                      <div className={style['product-popup__count-value']}>
                        {quantity}
                      </div>
                      <div
                        className={style['product-popup__count-plus']}
                        onClick={handleIncrease}
                        style={styleMainColorButton}
                      >
                        <ButtonPlus fill={styleButtons} stroke={stroke} />
                      </div>
                    </div>
                    <div className={style['popup-sum']}>
                      <p className={style['product-popup-modifier__price']}>
                        {translation('amount')}
                      </p>
                      <p
                        className={`${style['group-modifier-sum']} ${style['product-popup-modifier__price']}`}
                      >
                        {dividedSumInModal}&nbsp;{rest.currentCurrency}
                      </p>
                    </div>
                  </div>
                  <div className={style['product-popup__add']}>
                    <span
                      className={style.addButton}
                      style={styleMainColorButton}
                      onClick={handleAddInCart}
                    >
                      {wantOrder
                        ? translation('want-order')
                        : translation('add')}
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: `${rest.colorsSchema_id.popupBackup}`,
                    position: modifiersInModal.length > 0 ? 'fixed' : 'relative'
                  }}
                  className={style['product-popup__controls_active_modifiers']}
                >
                  <div className={style.controls}>
                    <div className={style['product-popup__count']}>
                      <div
                        className={style['product-popup__count-minus']}
                        onClick={handleDecrease}
                        style={styleMainColorButton}
                      >
                        <ButtonMinus fill={styleButtons} stroke={stroke} />
                      </div>
                      <div className={style['product-popup__count-value']}>
                        {quantity}
                      </div>
                      <div
                        className={style['product-popup__count-plus']}
                        onClick={handleIncrease}
                        style={styleMainColorButton}
                      >
                        <ButtonPlus fill={styleButtons} stroke={stroke} />
                      </div>
                    </div>
                    <div className={style['popup-sum']}>
                      <p className={style['product-popup-modifier__price']}>
                        {translation('amount')}
                      </p>
                      <p
                        className={`${style['group-modifier-sum']} ${style['product-popup-modifier__price']}`}
                      >
                        {dividedSumInModal}&nbsp;{rest.currentCurrency}
                      </p>
                    </div>
                  </div>
                  <div className={style['product-popup__add']}>
                    <span
                      className={style.addButton}
                      style={styleMainColorButton}
                      onClick={handleAddInCart}
                    >
                      {wantOrder
                        ? translation('want-order')
                        : translation('add')}
                    </span>
                  </div>
                </div>
              )
            ) : (
              ''
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default DishModal
