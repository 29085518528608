import { TDish, TOrder } from '../../utils/typesFromBackend'
import {
  ADDED_ITEM,
  DELETE_ITEM,
  CLEAR_CART,
  TCartActions,
  DECREASE_ITEM,
  ADDED_CURRENT_ORDER
} from '../actions/index'
import { v4 as makeUUID } from 'uuid'

export interface IDishInCart {
  dish: TDish
  quantity: number
  id: string
}
export interface TCartInitialState {
  dishes: IDishInCart[] | []
  currentOrder: TOrder | null
}
// доставть из LS текущий заказ
const cartStateString = localStorage.getItem('cartState')
const cartStateInitial: TCartInitialState | null =
  cartStateString !== null ? JSON.parse(cartStateString) : null
const cartInitialState: TCartInitialState = {
  dishes: cartStateInitial?.dishes ?? [],
  currentOrder: cartStateInitial?.currentOrder ?? null
}
export const cartReducer = (
  state = cartInitialState,
  action: TCartActions
): TCartInitialState => {
  switch (action.type) {
    case ADDED_CURRENT_ORDER: {
      return {
        ...state,
        currentOrder: { ...action.order } // добавляем в стор текущий заказ
      }
    }
    case ADDED_ITEM: {
      const resultArray = []
      let flagIsSearch = false
      state.dishes.forEach((item) => {
        if (action.id != null) {
          if (item.id === action.id) {
            flagIsSearch = true
            item.quantity += action.quantity
          }
          resultArray.push(item)
        } else {
          if (item.dish._id === action.item._id) {
            if (
              arraysEqual(item.dish.modifiers_ids, action.item.modifiers_ids) &&
              arraysEqual(
                item.dish.groupModifiers_ids,
                action.item.groupModifiers_ids
              )
            ) {
              flagIsSearch = true
              item.quantity += action.quantity
            }
          }
          resultArray.push(item)
        }
      })
      if (!flagIsSearch) {
        resultArray.push({
          dish: action.item,
          quantity: action.quantity,
          id: makeUUID()
        })
      }
      return {
        ...state,
        dishes: [...resultArray]
      }
    }
    case DECREASE_ITEM: {
      const resultArray: IDishInCart[] = []
      state.dishes.forEach((item) => {
        if (action.id != null) {
          if (item.id === action.id) {
            if (item.quantity - action.quantity > 0) {
              item.quantity -= action.quantity
            } else {
              item.quantity = 0
            }
          }
          if (item.quantity > 0) {
            resultArray.push(item)
          }
        } else {
          if (item.dish._id === action.item._id) {
            if (
              arraysEqual(item.dish.modifiers_ids, action.item.modifiers_ids) &&
              arraysEqual(
                item.dish.groupModifiers_ids,
                action.item.groupModifiers_ids
              )
            ) {
              if (item.quantity - action.quantity > 0) {
                item.quantity -= action.quantity
              } else {
                item.quantity = 0
              }
            } else if (action.alone === true) {
              if (item.quantity - action.quantity > 0) {
                item.quantity -= action.quantity
              } else {
                item.quantity = 0
              }
            }
          }
          if (item.quantity > 0) {
            resultArray.push(item)
          }
        }
      })
      return {
        ...state,
        dishes: [...resultArray]
      }
    }
    case DELETE_ITEM: {
      return {
        ...state,
        dishes: [...state.dishes].filter((item) => item.dish._id !== action.id)
      }
    }
    case CLEAR_CART: {
      return {
        ...state,
        dishes: []
      }
    }
    default: {
      return state
    }
  }
}

const objectsEqual: any = (o1: any, o2: any) => {
  if (o2 === null && o1 !== null) return false
  return o1 !== null && typeof o1 === 'object' && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
        Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
    : o1 !== null &&
      Array.isArray(o1) &&
      Array.isArray(o2) &&
      o1.length === 0 &&
      o2.length === 0
    ? true
    : o1 === o2
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const arraysEqual = (a1: any[], a2: any[]) =>
  a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]))
